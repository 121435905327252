
@import "@/../sass/_variables.scss";

.local-chart-settings {
    padding: 5px 0;

    &__label {
        margin-right: $spacing-small;
    }

    &__advanced {
        .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
            line-height: 24px;
            height: 24px;
            padding-left: 0;
            font-weight: normal;
        }

        .ivu-collapse,
        .ivu-collapse > .ivu-collapse-item {
            border: 0 none;
        }

        .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active .ivu-collapse-header {
            background-color: transparent;
        }

        .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header > i {
            margin-right: 0;
        }
    }
}
