
@import "@/../sass/_variables.scss";

.facebook-creative-manager-settings {
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-smaller;
    }

    &__label {
        width: 90px;
    }
}
