
@import "../../../sass/variables";

.intelligent-delivery__header {
    &-variants-counter {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #c1c7cd;
        display: flex;
        align-items: center;

        .loading-spinner {
            margin: 0 5px;
        }
    }
}
