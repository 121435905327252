@menu-prefix-cls: ~"@{css-prefix}menu";
@menu-dropdown-item-prefix-cls: ~"@{menu-prefix-cls}-horizontal .@{menu-prefix-cls}-submenu .@{select-dropdown-prefix-cls} .@{menu-prefix-cls}-item";

.@{menu-prefix-cls} {
    display: block;
    margin: 0;
    padding: 0;
    outline: none;
    list-style: none;
    color: @text-color;
    font-size: @font-size-base;
    position: relative;
    z-index: @zindex-select;

    &-horizontal{
        height: 60px;
        line-height: 60px;

        &.@{menu-prefix-cls}-light{
            &:after{
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: @border-color-base;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
    &-vertical{
        &.@{menu-prefix-cls}-light{
            &:after{
                content: '';
                display: block;
                width: 1px;
                height: 100%;
                background: @border-color-base;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
            }
        }
    }

    &-light{
        background: #fff;
    }
    &-dark{
        background: @menu-dark-title;
    }
    &-primary{
        background: @primary-color;
    }

    &-item{
        display: block;
        outline: none;
        list-style: none;
        font-size: @font-size-base;
        position: relative;
        z-index: 1;
        cursor: pointer;
        transition: all @transition-time @ease-in-out;
    }
    a&-item{
        color: inherit;
        &:hover, &:active{
            color: inherit;
        }
    }
    &-item > i{
        margin-right: 6px;
    }
    &-submenu-title > i, &-submenu-title span > i{
        margin-right: 8px;
    }

    &-horizontal &-item,
    &-horizontal &-submenu
    {
        float: left;
        padding: 0 20px;
        position: relative;
        cursor: pointer;
        z-index: 3;
        transition: all @transition-time @ease-in-out;
    }

    &-light&-horizontal &-item, &-light&-horizontal &-submenu{
        height: inherit;
        line-height: inherit;
        border-bottom: 2px solid transparent;
        color: @text-color;
        &-active, &:hover{
            color: @primary-color;
            border-bottom: 2px solid @primary-color;
        }
    }

    &-dark&-horizontal &-item, &-dark&-horizontal &-submenu{
        color: @menu-dark-subsidiary-color;
        &-active, &:hover{
            color: #fff;
        }
    }

    &-primary&-horizontal &-item, &-primary&-horizontal &-submenu{
        color: #fff;
        &-active, &:hover{
            //background: @link-active-color;
        }
    }

    &-horizontal &-submenu .@{select-dropdown-prefix-cls} {
        min-width: 100%;
        width: auto;
        max-height: none;
        .@{menu-prefix-cls}-item{
            height: auto;
            line-height: normal;
            border-bottom: 0;
            float: none;
        }
    }

    &-item-group{
        line-height: normal;
        &-title {
            height: 30px;
            line-height: 30px;
            padding-left: 8px;
            font-size: @font-size-small;
            color: @legend-color;
        }

        & > ul{
            padding: 0 !important;
            list-style: none !important;
        }
    }

    // vertical
    &-vertical &-item,
    &-vertical &-submenu-title
    {
        padding: 14px 24px;
        position: relative;
        cursor: pointer;
        z-index: 1;
        transition: all @transition-time @ease-in-out;

        &:hover{
            //background: @background-color-select-hover;
            color: @primary-color;
        }
    }

    &-vertical &-submenu-title-icon{
        // #5592 支持自定义图标 size 后，换一种位置
        //float: right;
        //position: relative;
        //top: 4px;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
    }
    &-submenu-title-icon {
        transition: transform @transition-time @ease-in-out;
    }
    &-horizontal &-opened > * > &-submenu-title-icon{
        transform: rotate(180deg);
    }
    &-vertical &-opened > * > &-submenu-title-icon{
        transform: translateY(-50%) rotate(180deg);
    }

    &-vertical &-submenu{
        &-nested{
            padding-left: 20px;
        }
        .@{menu-prefix-cls}-item{
            padding-left: 43px;
        }
    } 
    &-vertical &-item-group{
        &-title{
            height: 48px;
            line-height: 48px;
            font-size: @font-size-base;
            padding-left: 28px;
        }
    }
    &-dark&-vertical &-item-group{
        &-title{
            color: @menu-dark-group-title-color;
        }
    }

    &-light&-vertical &-item{
        //border-right: 2px solid transparent;
        &-active:not(.@{menu-prefix-cls}-submenu){
            color: @primary-color;
            background: ~`colorPalette("@{primary-color}", 1)`;
            //border-right: 2px solid @primary-color;
            z-index: 2;
            &:after{
                content: '';
                display: block;
                width: 2px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                background: @primary-color;
            }
        }
    }

    &-dark&-vertical &-item, &-dark&-vertical &-submenu-title{
        color: @menu-dark-subsidiary-color;
        &-active:not(.@{menu-prefix-cls}-submenu),
        &-active:not(.@{menu-prefix-cls}-submenu):hover
        {
            background: @menu-dark-active-bg;
        }
        &:hover{
            color: #fff;
            background: @menu-dark-title;
        }
        &-active:not(.@{menu-prefix-cls}-submenu){
            color: @primary-color;
            //border-right: 2px solid @primary-color;
        }
    }
    &-dark&-vertical &-submenu &-item{
        &:hover{
            color: #fff;
            background: transparent !important;
        }
        &-active,&-active:hover{
            border-right: none;
            color: #fff;
            background: @primary-color !important;
        }
    }
    // &-dark&-vertical &-item-active &-submenu-title{
    //     color: #fff;
    // }
    &-dark&-vertical &-child-item-active > &-submenu-title{
        color: #fff;
    }

    &-dark&-vertical &-opened{
        background: @menu-dark-active-bg;
        .@{menu-prefix-cls}-submenu-title{
            background: @menu-dark-title;
        }

        .@{menu-prefix-cls}-submenu-has-parent-submenu{
            .@{menu-prefix-cls}-submenu-title{
                background: transparent;
            }
        }
    }
}
.select-item(@menu-prefix-cls, @menu-dropdown-item-prefix-cls);

.@{menu-dropdown-item-prefix-cls} {
    padding: 7px 16px 8px;
    font-size: @font-size-base !important;
}