
@import "../../../sass/variables";

.font-style {
    &__control {
        &--color-value {
            position: absolute;
            width: calc(100% - 40px);
            z-index: 1;
            top: 0;
            margin-left: 40px;
            padding: 0 10px;
            border-left: 1px solid $cmp-dark-bg-color;
        }

        &--color-row {
            position: relative;
            width: 100%;
        }

        &--color-picker {
            cursor: pointer;
            position: relative;
            width: 100%;
            z-index: 2;

            .ivu-input-icon {
                display: none;
            }

            .ivu-input {
                border: transparent 0;
                background-color: inherit;
                width: 140px;

                &:hover,
                &:active {
                    border: transparent 0;
                    cursor: pointer;
                }
            }

            .ivu-input-icon-normal + .ivu-input {
                padding: 2px 6px;
                width: 40px;
                height: 33px;
            }

            .ivu-color-picker-small .ivu-color-picker-color {
                margin: 4px 6px;
            }
        }
    }

    &__color-value {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: green;
    }
}

.font-family {
    max-width: 184px;
    &__option {
        padding-right: 100px;
        position: relative;
    }

    &__icon-content {
        position: absolute;
        top: 5px;
        right: 15px;
    }

    &__icon-wrapper {
        display: inline-block;
        width: 24px;
        height: 24px;
        filter: grayscale(100%);
    }
}
