
@import "@/../sass/_variables.scss";

.account-signal {
    &__modal {
        &-wrap {
            padding: 20px;
            border-radius: 6px;
            margin-bottom: 10px;
            background: var(--wpp-grey-color-100);
        }
        h3 {
            margin-bottom: 12px;
        }
        .hox-modal {
            width: 820px;
            &__body {
                margin: 0 20px;
                padding: 0 0 20px;
            }
        }
    }
    &__header {
        span {
            display: block;
            font-size: 13px;
            font-weight: normal;
            margin-top: 10px;
        }
        .ivu-date-picker {
            width: 48%;
            margin-right: 4%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__wrap {
        display: flex;
    }

    &__tabs {
        border-bottom: 2px solid var(--wpp-grey-color-400);
        margin-bottom: 20px;
        span {
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: -2px;
            border-bottom: 2px solid var(--wpp-grey-color-400);
            cursor: pointer;
            &.active {
                border-bottom: 2px solid $primary-color;
            }
        }
    }

    &__add-new {
        color: var(--wpp-primary-color-500);
        border: none;
        background: none;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
    }

    &__save-button {
        margin-left: auto;
    }

    &__tabs {
        border-bottom: 2px solid var(--wpp-grey-color-400);
        margin-bottom: 20px;
        span {
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: -2px;
            border-bottom: 2px solid var(--wpp-grey-color-400);
            cursor: pointer;
            &.active {
                border-bottom: 2px solid $primary-color;
            }
        }
    }

    &__add-new {
        color: var(--wpp-primary-color-500);
        border: none;
        background: none;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
    }

    .ivu-form-item {
        display: flex;
    }
}
