
@import "../../../sass/variables";

.variants-library__wrapper {
    height: 100%;
    position: relative;
    width: 100%;
}

.variants-library {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__body {
        flex: 1;
        overflow-y: auto;
        padding: $spacing-large 20px $spacing;
        position: relative;

        &--no-padding {
            padding: 0;
        }

        &--no-scroll {
            overflow-y: hidden;
        }
    }

    &__empty-message-wrapper {
        padding: $spacing-large 0 0;
    }

    &__loading-error-message-wrapper {
        padding: 0 $spacing-large;
    }

    &__footer {
        padding: $spacing 0 0;
        border-top: 1px solid $grey3;
        display: flex;

        &-spacer {
            flex: 1 1 100%;
            width: 100%;
        }

        &-item {
            flex: 0 0 auto;
            max-width: 100%;
            padding: 0 0 0 $spacing-smaller;
            width: auto;
        }

        &-items {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: space-between;

            .ivu-select {
                padding: 0;

                .ivu-select-selection {
                    padding: 6px 10px;
                }
            }
        }
    }

    &__scrubbar {
        flex: 1;
        margin-right: 30px;
    }

    .banner-scrubbar {
        flex: 1 0 100px;
        min-width: 100px;

        .ivu-slider-wrap {
            margin: 0 9px;
        }
    }

    .play-pause-all {
        flex: 0 0;
        margin-left: $spacing-large;
    }

    &__page-select {
        flex: 0 0;
        white-space: nowrap;
    }

    &__page-size-select-wrapper {
        flex: 0 0;
        padding: 0 0 0 $spacing;
    }

    &__scale-options-wrapper {
        align-items: center;
        display: flex;
        margin: 0 $spacing-small 0 0;
        white-space: nowrap;

        p {
            margin: 0 $spacing-smaller 0 0;
        }
    }

    &__select-all-notice-wrapper {
        border-bottom: 1px solid $grey3;
        padding: $spacing 20px 0;
    }

    &__variations {
        color: $cmp-light-active;
        font-weight: bold;
        height: $campaign-nav-height;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $grey3;
    }
}

.campaign-edit__deliverables-size-group {
    width: 100%;

    &--single-child {
        width: auto;
        display: inline-block;
    }
}

.select-variants__filters {
    width: 25%;
    overflow: auto;
}

.variants {
    &-wrap {
        max-height: calc(100vh - 162px);
        display: flex;
    }

    &__select-all-wrap {
        padding: 20px;
    }

    &-list {
        margin-left: 5%;
        width: 70%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        &__header {
            display: flex;
            border-bottom: 1px solid #dcdcdc;
            padding-bottom: 10px;
            align-items: center;
            height: 60px;

            button {
                margin-left: auto;
            }
        }
    }
}
