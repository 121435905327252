
@import "@/../sass/_variables.scss";

.deliverable-preview-drawer {
    .ivu-drawer-body {
        flex: 1;
        height: auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;
    }

    .ivu-drawer-content {
        display: flex;
        flex-direction: column;
    }

    .timeline-slider {
        align-items: center;
        display: flex;
        flex: 1 0;
        width: 100%;
    }

    .ivu-slider-wrap {
        margin: 9px ($spacing + 9) 9px 9px;
    }

    .play-pause-all {
        margin: 0;
        color: $grey5;
        transition: color 0.2s linear;

        &:hover {
            color: $darktheme100;
        }
    }
}

.deliverable-preview-drawer__body {
    background: $cmp-light-bg-color;
    display: flex;
    flex: 1;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.deliverable-preview-drawer__container {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.deliverable-preview-drawer__qr {
    &-wrapper {
        flex: 0 0;
        margin: $spacing-large 0 0 $spacing-large;
        padding: $spacing $spacing $spacing-small $spacing;
        border: 1px solid $grey2;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    &-code {
        margin-bottom: $spacing-small;
    }

    &-copy {
        white-space: nowrap;
    }
}

.deliverable-preview-drawer__header {
    background-color: white;
    border-bottom: 1px solid $grey2;
    padding-left: $spacing-large;

    .navigation-tab {
        padding: 24px $spacing-large 20px;
    }
}

.deliverable-preview-drawer__footer {
    border-top: 1px solid $grey3;
    display: flex;
    padding: $spacing-small $spacing-large;
}

.deliverable-preview-drawer__preview-wrapper {
    flex: 0 0;
    padding: $spacing-large;
    margin: 0 auto;
}

.deliverable-preview-drawer__status-container {
    padding: 0 0 $spacing-large;
}

.deliverable-preview-drawer__feed {
    position: relative;
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;

    &-wrapper {
        flex: 1 0 calc(100% - #{$spacing-large * 2});
        width: calc(100% - #{$spacing-large * 2});
        margin: $spacing $spacing-large 0 $spacing-large;
    }

    &-link {
        color: $darkgrey2;
        padding: 0 20px;
    }

    &-copy {
        margin-left: auto;
        margin-right: 12px;

        &.ivu-btn {
            font-size: 11px;
            background-color: $grey2;
            border: none;

            &:hover {
                background-color: $grey3;
            }
        }
    }
}
