
@import "../../../sass/variables";

.selectall-notice {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: $spacing-small $spacing;
    text-align: center;
    width: 100%;
    background: $white;
    margin-bottom: $spacing;
}

.selectall-notice__button-wrapper {
    display: inline-block;
    padding: 0 0 0 $spacing-small;
}
