
@import "../../../sass/variables";

.campaign-cell {
    width: 100%;

    &-dropdown {
        width: 293px;
        padding-left: 6px;
    }

    &-poptip {
        display: flex;
        justify-content: space-between;
        width: 287px;

        &-arrow.ivu-icon {
            font-size: 14px;
            padding-top: 3px;
            padding-right: 3px;
        }
    }

    &-content {
        max-height: 250px;
        width: 300px;
    }

    &-buttons {
        padding: 8px;
        display: flex;
        justify-content: flex-end;
    }

    &-table {
        max-height: 200px;
        overflow: hidden auto;
        padding: 8px 0;

        th {
            font-weight: normal;
            color: $blue;
        }

        .ivu-table-cell {
            padding: 0;
        }
    }

    &-poptip-text {
        width: 100%;
    }
}
