
@import "../../../sass/variables";
.kpi-stats {
    display: inline-block;

    span {
        width: 6px;
        height: 6px;
        margin-bottom: 2px;
        border-radius: 50%;
        background-color: #00000054;
        display: inline-block;
    }

    &--up span {
        background-color: $ci-quadrant-green;
    }

    &--down span {
        background-color: $ci-quadrant-red;
    }

    &--utilisation {
        color: #00000054;
    }

    &__item {
        display: inline-block;
        padding: 4px;
    }

    &--confidence {
        display: none;
    }
}
