
@import "@/../sass/_variables.scss";

.media-library__asset-tag-list {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 99%;
    background: white;
    overflow: hidden scroll;
    padding-right: $spacing-large;
    box-shadow: 3px 0px 3px -2px rgba(140, 140, 140, 0.4);

    ul {
        list-style: none;
        li {
            margin-top: $spacing-base;

            .media-library__asset-tag-list-text {
                display: flex;
                justify-content: space-between;

                span {
                    max-width: 50%;
                    display: block;

                    & + span {
                        text-align: right;
                    }
                }
            }
        }
    }

    .no-tags {
        margin-top: 70%;
        text-align: center;

        .icon {
            margin-bottom: $spacing-base;
        }
    }
}
