
@import "../../../sass/variables";

.expandable-settings-pane {
    height: 100%;
    overflow: hidden auto;

    svg {
        fill: $cmp-dark-font-color;
    }

    .ivu-btn {
        background-color: $cmp-dark-input-bg-color;
        border-color: #dcdee2;
        margin: 2px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $grey5;
    }
    input::-moz-placeholder {
        /* Firefox 19+ */
        color: $grey5;
    }
    input:-ms-input-placeholder {
        /* IE 10+ */
        color: $grey5;
    }
    input:-moz-placeholder {
        /* Firefox 18- */
        color: $grey5;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}

.editable-settings {
    &-item {
        width: 40px;
        height: 33px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__input {
        flex: 1 1 43px;
        width: 43px;

        &-label {
            padding-right: $spacing-small;
            width: 20px;
            flex: 0 0 20px;
            &--second {
                margin-left: 10px;
            }
        }

        .ivu-input-group-append {
            font-size: 10px;
            background-color: $cmp-dark-input-bg-color;
            border-color: $darktheme20;
            padding: 0;
            line-height: normal;
            height: 24px;
        }

        &--with-suffix {
            .ivu-input {
                padding-right: 2px;
            }

            .ivu-input-group-append {
                padding-left: 2px;
                padding-right: 7px;
            }
        }

        .ivu-input {
            border-right: 0;
            text-align: right;
            height: 24px;
        }

        .ivu-input:focus {
            box-shadow: none;
        }

        &-wrapper {
            flex: 1 1 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
