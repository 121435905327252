@import "variables";

/* ff had problems loading fonts provided by the iview */
@supports (-moz-appearance: none) {
    @font-face {
        font-family: "Ionicons";
        src: url("https://cdnjs.cloudflare.com/ajax/libs/iview/3.5.4/styles/fonts/ionicons.woff2") format("woff2"),
            url("https://cdnjs.cloudflare.com/ajax/libs/iview/3.5.4/styles/fonts/ionicons.woff") format("woff"),
            url("https://cdnjs.cloudflare.com/ajax/libs/iview/3.5.4/styles/fonts/ionicons.ttf") format("truetype"),
            url("https://cdnjs.cloudflare.com/ajax/libs/iview/3.5.4/styles/fonts/ionicons.svg") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

.ivu-page-item a {
    font-family: inherit;
}

.ivu-icon {
    font-size: $default-icon-fs;

    &.ivu-icon-ios-funnel {
        font-size: $ios-funnel-fs;
    }

    .ivu-btn > & {
        line-height: $default-icon-fs;
    }

    .ivu-btn-icon-only.ivu-btn-circle > & {
        vertical-align: text-bottom;
    }

    &.ivu-select-arrow {
        font-size: $select-arrow-icon-fs;
    }
}

.biggerIosIcon {
    &.ivu-btn {
        font-size: 24px;
        line-height: 20px;
    }

    &.ivu-btn-icon-only.ivu-btn-circle {
        > .ivu-icon {
            vertical-align: middle;
            line-height: 1em;
        }
    }
}

.bigIosIcon {
    &.ivu-btn {
        font-size: 20px;
        line-height: 20px;
    }

    &.ivu-btn-icon-only.ivu-btn-circle {
        > .ivu-icon {
            vertical-align: bottom;
            line-height: 1em;
        }
    }
}

.ivu-modal {
    &-wrap {
        z-index: $zindex-default-modal;
    }

    &-confirm-head {
        min-height: 42px;

        &-title {
            margin-left: 0;
        }

        &-icon-confirm {
            display: none;
        }
    }

    &-confirm-body {
        padding-left: 0;
    }

    &-confirm-footer {
        .ivu-btn-large {
            font-size: $font-size-base;
        }
    }

    &-footer {
        .ivu-btn-large {
            font-size: $btn-font-size;
            padding: $btn-padding-base;
        }
    }
}

.ivu-message {
    &-notice {
        max-width: 400px;
        margin: 0 auto;

        &-content {
            box-shadow: 0 1px 16px rgba(0, 0, 0, 0.2);

            &-text {
                display: flex;

                .ivu-message-custom-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1 1 auto;

                    .ivu-icon {
                        flex: 0 0 auto;
                    }
                }
            }
        }
    }

    &-none {
        display: none;
    }

    & .ivu-icon {
        font-size: 30px;
    }
}

.icon-button {
    .ivu-icon-ios-cloud-upload-outline {
        font-size: $ios-cloud-upload-outline-fs;
        font-weight: $ios-cloud-upload-outline-weight;
    }

    .ivu-icon-ios-trash-outline {
        font-size: $ios-trash-outline-fs;
        font-weight: $ios-trash-outline-weight;
    }
}

.ivu-form-item {
    &.no-margin {
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.ivu-layout-sider-children {
    display: flex;
    flex: 1 0 100%;
    width: 100%;
}

.ivu-table-filter-list-item {
    padding-bottom: 5px;
    overflow: hidden auto;
    max-height: 400px;
}

.ivu-card {
    &.no-padding {
        .ivu-card-body {
            padding: 0;
        }
    }
}

.ivu-btn {
    height: $input-height-base;
    line-height: 1;

    &.primary-green {
        background-color: $primary-green;
        border-color: $primary-green-dark;

        &:hover {
            background-color: $primary-green-light;
        }
    }
}

.ivu-btn-large {
    height: $input-height-large;
}

.ivu-btn-small {
    height: $input-height-small;
}

.ivu-poptip {
    &.hide-title {
        .ivu-poptip-title {
            display: none;
        }
    }

    &.no-wrap {
        .ivu-poptip-body {
            padding: 0;
        }
    }
}

.ivu-alert {
    &-info {
        .ivu-alert-icon {
            color: $blue;
        }
    }
}

.ivu-collapse > .ivu-collapse-item {
    border: 1px solid transparent;

    .ivu-collapse-header {
        border: none;
        background: $white;
    }

    &.ivu-collapse-item-active {
        border-bottom: 1px solid $cmp-light-filters-border-color;

        .ivu-collapse-header {
            background: $cmp-light-filters-border-color;
        }
    }
}
