
@import "../../../../sass/_variables.scss";

.insights-chart-preview {
    max-width: 100%;
    background-color: white;
    padding: $spacing-large;
    margin: 0 auto $spacing-larger auto;

    h2 {
        text-transform: capitalize;
        color: $ci-font-color-secondary;
    }
}
