
@import "@/../sass/_variables.scss";

.table-header-column {
    background: $grey1;
    font-weight: bold;
    position: relative;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    width: auto;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid $grey3;
    }

    &--group-header {
        font-weight: normal;

        &:after {
            border-bottom: none;
        }
    }

    &--sticky-top {
        position: sticky;
        top: 0;
        z-index: 3;
    }

    &--sticky-left {
        position: sticky;
        left: 0;
        z-index: 5;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            border-right: 1px solid $grey3;
        }
    }

    &--sticky-right {
        position: sticky;
        right: 0;
        z-index: 5;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            border-left: 1px solid $grey3;
            z-index: -1;
        }
    }

    &--strong-border-left {
        z-index: 4;

        &:after {
            content: "";
            position: absolute;
            left: -1px;
            bottom: 0;
            height: 100%;
            border-left: 3px double $grey3;
        }
    }

    &--sub-header {
        background: $white;
        font-weight: normal;
    }
}

.table-header-column__content {
    align-items: center;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    padding: $spacing-small $spacing-semi-small;
    text-overflow: ellipsis;
}
