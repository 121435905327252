
@import "@/../sass/_variables.scss";

$size: 40px;

.banner-status-indicator {
    align-items: center;
    display: flex;
    height: $size;
    justify-content: center;
    position: relative;
    width: $size;
}
