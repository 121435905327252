.placeholder(@color: @input-placeholder-color) {
    // Firefox
    &::-moz-placeholder {
        color: @color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    // Internet Explorer 10+
    &:-ms-input-placeholder {
        color: @color;
    }
    // Safari and Chrome
    &::-webkit-input-placeholder {
        color: @color;
    }
}

// for select and input like component's arrow
.inner-arrow() {
    position: absolute;
    top: 50%;
    right: 8px;
    line-height: 1;
    transform: translateY(-50%);
    font-size: @font-size-base;
    color: @subsidiary-color;
    transition: all @transition-time @ease-in-out;
}

.reset-component() {
    font-family: @font-family;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @text-color;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
}