
@import "@/../sass/_variables.scss";

$full-screen-outer-spacing: $spacing-large;

.hox-modal {
    background: $white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    color: $cmp-light-secondary-font-color;
    margin: auto;
    max-width: 100%;
    position: relative;
    width: 520px;
}

.hox-modal--tabs-no-header {
    .hox-modal__close-icon-container {
        top: $spacing-large - 21px;
    }
}

.hox-modal__body {
    padding: $spacing $spacing-large;
    position: relative;
}

.hox-modal__close-icon-container {
    color: $grey5;
    cursor: pointer;
    position: absolute;
    right: $spacing-large - 14px;
    top: $spacing-large - 14px;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: $darkgrey2;
    }
}

.hox-modal__footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing $spacing-large $spacing-large $spacing-large;
}

.hox-modal__header-container {
    border-bottom: 1px solid $grey2;
    padding: $spacing $spacing-large 0;
}

.hox-modal__header {
    font-size: $font-size-larger;
    line-height: 1em;
    margin: 0 $spacing-large $spacing 0;
    padding: $spacing 0;
}

.hox-modal--no-scroll {
    overflow: hidden;
}

.hox-modal--fullscreen {
    bottom: $full-screen-outer-spacing;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$full-screen-outer-spacing * 2});
    left: $full-screen-outer-spacing;
    position: absolute;
    right: $full-screen-outer-spacing;
    top: $full-screen-outer-spacing;
    width: calc(100vw - #{$full-screen-outer-spacing * 2});

    .hox-modal__body {
        flex: 1;
        overflow: auto;
    }
}

.hox-modal--resizable {
    width: initial;
}

.hox-modal__wrapper {
    background: rgba(55, 55, 55, 0.6);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $spacing-large $spacing;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
}

.hox-modal__wrapper--on-top-of-everything {
    z-index: 9999;
}

.hox-modal__description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
