
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.asset-preview {
    @include make-checked-background($grey3, $spacing-small);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    flex: 1;
    height: calc(100% - 32px);
    justify-content: center;
    position: relative;
    width: 100%;
}

.asset-preview__alert-container {
    max-width: 578px;
    padding: $spacing;
    width: 100%;
}

.asset-preview__image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.asset-preview__video {
    max-height: 100%;
    object-fit: scale-down;
}
