
@import "../../sass/_variables.scss";

.clients {
    &__action {
        &-items {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0;
        }

        &-item {
            flex: 0 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }
}

.edit-client {
    &-button__delete {
        float: left;
    }
}
