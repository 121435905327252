
@import "../../../sass/variables";

.master-templates {
    height: 100%;
    position: relative;
    width: 100%;

    &__filters {
        padding-top: 60px;
    }

    .campaign-banner {
        border: 1px solid $cmp-light-border-color;
        box-sizing: content-box;
    }

    .master-template-library {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
