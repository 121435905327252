
@import "../../../../sass/_variables.scss";
@import "~prism-editor";
@import "~prism-theme-tomorrow";

/* required class */
.css-editor {
    color: $cmp-dark-font-color;
    background-color: $cmp-dark-input-bg-color;
    border-color: $darktheme20;

    font-family:
        Fira code,
        Fira Mono,
        Consolas,
        Menlo,
        Courier,
        monospace;
    font-size: $font-size-base;
    line-height: $line-height-base;
    padding: $spacing-smaller;
    min-height: 4em;
}

/* optional class for removing the outline */
.prism-editor__textarea {
    outline: none;
}
