
@import "../../../../sass/_variables.scss";

.attribute-values-analysis {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    &__load-more {
        flex: 1 0 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .creative-carousel-dots {
        bottom: 57px;
    }
}
