
@import "../../../sass/_variables.scss";

.insights-display-settings {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__dropdown {
        margin-right: $spacing-semi-large;
    }
}
