
@import "../../sass/variables";

.teamMember {
    margin-bottom: 0.5rem;

    &Email {
        font-size: 1rem;
        font-weight: bold;
        float: left;
    }

    &Access {
        text-transform: capitalize;
    }

    &Scope {
        float: right;

        .ivu-tag-text {
            color: $info-color !important;
        }

        &.ivu-tag-border {
            border-color: $info-color;
        }
    }

    .revoke {
        float: right;
        font-size: 0.8rem;
        padding-top: 5px;
        cursor: pointer;
    }

    .clear {
        clear: both;
    }
}
