
@import "../../../sass/variables";
.element-position {
    &__position {
        flex: 1 1 138px;
        width: 138px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
