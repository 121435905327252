
@import "../../../sass/variables";

.mentionable {
    &-wrap {
        position: relative;
    }

    &-panel {
        position: absolute;
        font-size: $font-size-small;
    }

    &-inner {
        position: relative;
    }
}

.mentionable-list {
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin: 0;

    &-header {
        border-bottom: 1px solid $grey3;
        padding: $spacing-small 20px $spacing-small;
    }

    &-footer {
        border-top: 1px solid $grey3;
        padding: $spacing-small 20px $spacing-small;
    }
}

.mentionable-list-item {
    box-sizing: border-box;
    padding: 8px 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey1;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--current {
        background: $grey1;
    }
}

.mentionable-popup {
    z-index: 11110 !important; //todo: check
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(101, 111, 122, 0.5);
    position: absolute;
    bottom: 0;
    left: -0.8em;
    cursor: default;
    background-color: $white;
    max-height: 300px;
    width: 335px;
    overflow-y: auto;
    white-space: normal;
    margin-right: 20px;

    &--wide {
        width: 450px;
    }

    &::-webkit-scrollbar {
        width: 11px;
        height: 11px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        min-height: 36px;
        border: 2px solid transparent;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 7px;
        background-color: #c4c4c4;
    }
}

.mentionable-usage-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px $spacing-large;
    text-align: center;
}

.mentionable-popup-wrapper {
    .ivu-poptip-body {
        padding: 0;
    }

    &--anchor {
        height: 0;
        padding: 0;
        margin: 0;
    }
}
