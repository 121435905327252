
@import "@/../sass/_variables.scss";

.edit-campaign-modal {
    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;
    }
}
