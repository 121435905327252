
@import "@/../sass/_variables.scss";
@import "@/../sass/creative-insights-charts.scss";

.insights-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.insights {
    flex: 1 1 100%;
    height: 100%;
    width: calc(100% - 84px);
    margin: 0 42px;
    image-rendering: -webkit-optimize-contrast;

    &--drawer-is-open {
        overflow: hidden;
    }

    &-no-data {
        margin: $spacing-largest auto auto;
    }

    &-no-kpis {
        margin: 20vh auto auto;
    }

    &-section {
        margin: $spacing $spacing-small;
    }

    &-settings {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 45px 0 $spacing 0;
    }

    .ivu-collapse > .ivu-collapse-item {
        border: 1px solid transparent;

        .ivu-collapse-header {
            border: none;
            background: $white;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        }

        &.ivu-collapse-item-active {
            border-bottom: 1px solid $cmp-light-filters-border-color;

            .ivu-collapse-header {
                background: $white;
            }
        }
    }

    .ivu-collapse-content {
        background: rgba(255, 255, 255, 0.4);
    }

    &__back-link {
        color: $ci-font-color;
        cursor: pointer;
        font-size: 20px;
        margin-right: auto;
    }
}

.insights--no-scroll {
    overflow: hidden;
    height: 100vh;
}

.diversity-warning .alert__icon-container {
    padding-top: $spacing-small;
}
