
@import "../../../sass/variables";

.alert-modal {
    color: $cmp-light-secondary-font-color;

    .ivu-modal-content {
        border-radius: 0;
        padding: 10px 5px;
    }

    .ivu-modal-header,
    .ivu-modal-footer {
        border: none;
    }

    .ivu-modal-header h2 {
        padding-left: 5px;
    }

    .ivu-modal-body {
        display: flex;
    }

    .ivu-modal-footer {
        .ivu-btn {
            padding: 5px 10px;
            margin-left: 5px;
        }
    }

    &__alert-icon {
        color: $error-color;
        font-size: 64px;
    }

    &__content-wrapper {
        padding: 3px 0 0 20px;
    }

    &.warning {
        .alert-modal__alert-icon {
            color: $warning-color;
        }
    }
}
