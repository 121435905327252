// from the colours xslx
$green: #41b883;
$red: #f9444e;
$yellow: #ff9900;
$purple: #351ea2;
$purple-light: #5033d9;
$purple-lighter: #755ee1;
$blue: #0092ff;
$electric-blue: #2b39ff;

$darktheme100: #020202;
$darktheme80: #1c1c1c;
$darktheme60: #2a2e32;
$darktheme40: #323a4a;
$darktheme20: #515a6e;
$darktheme0: #808695;

$white: #ffffff;
$grey0: #fafafa;
$grey1: #f0f2f5;
$grey2: #e9e9e9;
$grey3: #dcdcdc;
$grey4: #ccc;
$grey5: #9b9b9b;

$fluogreen: #27f5c7;
$accentpink: #c334bf;

// based on the values above
$darkgrey1: $darktheme0;
$darkgrey2: $darktheme20;
$darkgrey3: $darktheme40;
$darkgrey4: $darktheme60;
$darkgrey5: $darktheme80;
$darkgrey6: $darktheme100;

$reporting-color-blue: #477ef6;
$reporting-color-cyan: #5ad8a6;
$reporting-color-gray: #5d7092;
$reporting-color-gray-minus: #cdcdcd;
$reporting-color-yellow: #f6bd16;
$reporting-color-red: #e86452;
$reporting-color-pink: #dc5a83;
$reporting-color-purple: #945fb9;
$reporting-color-orange: #ee825e;
$reporting-color-green: #1e9493;

$ctrUp: $red;
$ctrDown: $green;
$cvrUp: $reporting-color-blue;
$cvrDown: $reporting-color-purple;

$hogarth-H-dark-fill: #121422;
$hogarth-H-light-fill: #fff;

$hogarth-blue: #191528;
$hogarth-indigo: #4f51c9;
$hogarth-coral: #ff527e;
$hogarth-yellow: #def034;
$hogarth-aqua: #27c79a;

$layout-body-background: #f5f7f9;
