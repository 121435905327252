@import "_variables";

.campaign {
    &-layout {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &-header {
        display: flex;
        flex: 0 0 $campaign-nav-height;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: $campaign-nav-height;
        color: $campaign-nav-color;
        position: sticky;
        top: 0;
        z-index: 5;

        &__breadcrumb {
            text-transform: none;
            &--disabled {
                pointer-events: none;
            }
            
            span.inactive-link {
                color: $campaign-nav-color;
            }

            a {
                color: $campaign-nav-color;

                &:hover {
                    color: $campaign-nav-color;
                    border-bottom: 3px solid $blue;
                    padding-bottom: 20px;
                }
            }

            .router-link-exact-active {
                border-bottom: 3px solid $cmp-dark-active;
                padding-bottom: 20px;
            }

            .ivu-breadcrumb-item-separator {
                color: $cmp-dark-active;
            }

            &s {
                margin-left: 22px;
                flex: 1 1;
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 20px;
            height: 20px;
            margin: 14px;

            &s {
                flex: 0 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    &-content {
        overflow: initial !important;
        margin-left: $campaign-vertical-nav-width;
        z-index: 5;

        .main-content {
            display: flex;
            height: 100%;
            padding-top: $campaign-main-content-padding-top;
        }
    }

    &-banner {
        &__checkbox {
            position: relative;
            margin-right: 0;

            .ivu-checkbox {
                position: absolute;
                top: 10px;
                left: 10px;
                font-size: 20px;

                &-inner {
                    width: 20px;
                    height: 20px;

                    &:after {
                        height: 14px;
                        left: 8px;
                    }
                }
            }
        }

        &__preview .ivu-checkbox-wrapper {
            display: block;
        }
    }
}

.campaign-edit-split {
    .groups-pane__inner,
    .editable-widgets__inner {
        padding-right: 0 !important;
        height: calc(100vh - #{$campaign-nav-height}) !important;
    }
}

.filter-badge-count {
    padding-right: 15px;
}

.ivu-layout-sider {
    left: $campaign-vertical-nav-width;
}

.ivu-collapse-content {
    padding: 0;
}

.other-editables-btn,
.ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
    font-weight: bold;
    height: $campaign-sidebar-item-height;
    line-height: $campaign-sidebar-item-height;

    .collapsible-widget__panel-controls {
        font-weight: normal;
    }
}

.other-editables-btn {
    border-bottom: 1px solid;
}

.ivu-input {
    font-size: 12px;
}

.deliverable {
    &-value-item {
        background-color: $cmp-dark-secondary-sidebar-color;
        border-bottom: 1px solid $cmp-dark-bg-color;
        line-height: 34px;
        font-size: 12px;
        &:last-child {
            border-bottom: none;
        }
    }
}

.editable-widgets {
    .ivu-collapse-content > .ivu-collapse-content-box {
        padding: 0;
    }
}

.filter-badge-count .ivu-badge-count {
    background: $filter-badge-bg-color;
    color: $filter-badge-bg-font-color;
    font-family: inherit;
}

.editable-select-widget {
    .labelled-select {
        height: 34px;
        padding-left: 10px;
    }

    .ivu-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex: 1 0;

        > div {
            width: calc(100% - 15px);

            &.ivu-select-dropdown {
                min-width: auto !important;
                width: calc(100% - 24px);
                li {
                    white-space: normal;
                }
            }
        }
    }

    .lockable-row {
        .ivu-select > div.ivu-select-dropdown {
            width: calc(100% - 127px);
        }
    }
}

.templates-modal__body .campaign {
    &-banner {
        border: 1px solid $cmp-light-border-color;
        box-sizing: content-box;

        &-footer {
            color: $cmp-light-font-color;
        }
    }
}

@media all and (-ms-high-contrast: none) {
    /*fixes Library height in IE*/

    #app {
        .collapsible-widget__panel-controls {
            flex: 0 0 auto;
        }

        .campaign-library {
            &__footer-items {
                justify-content: flex-start;
                max-width: 100%;
            }

            &__footer-item,
            &__page-select,
            &__page-size-select-wrapper,
            & .play-pause-all {
                flex: 0 0 auto;
            }

            .timeline-slider {
                flex: 1 1 auto;
            }

            &__sidebar-nav .navigation-tab {
                flex: 1 1 50%;
            }
        }

        .library-deliverable-table-checkbox-cell,
        .library-deliverable-table-action-items-cell {
            position: relative;
        }

        .library-deliverable-table-checkbox-cell .ivu-checkbox-input {
            opacity: 1;
        }

        .campaign-content {
            z-index: auto;
        }
    }

    .clients__action-item {
        flex: 0 0 auto;
    }
}
