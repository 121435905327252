
@import "../../../sass/variables";

.lockable-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1 0;
    border-bottom: none;

    &--locked {
        color: $cmp-dark-disabled-color;
    }

    &__padlock {
        text-align: center;
        color: $cmp-dark-font-color;
        border-right: 1px solid $cmp-dark-bg-color;
        padding: 0 10px;

        &--click-tracked {
            cursor: pointer;
        }
    }
}
