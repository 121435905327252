
@import "../../../sass/variables";
.marketplace__navigation {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-select {
        margin-right: $spacing;
        width: 170px;

        .ivu-select-item,
        .ivu-select-selected-value {
            text-transform: capitalize;
        }
    }
}

.marketplace-modal__body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.marketplace-new-badge {
    position: absolute;
    right: -10px;
    height: 32px;
    top: -10px;

    img {
        width: 100%;
        height: 100%;
        image-rendering: -webkit-optimize-contrast;
    }
}

.marketplace-item {
    padding: $spacing-semi-large;
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
    height: 160px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    position: relative;

    &.disabled {
        pointer-events: none;
        .marketplace-item__header,
        .marketplace-item__footer,
        p {
            opacity: 0.3;
        }
    }

    &:last-child(3n) {
        border-right: 0 none;
    }

    &__header {
        display: flex;
        height: 24px;
        line-height: 24px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    h4 {
        color: $ci-font-color;
        flex: 1 1;
        font-size: 14px;
        line-height: 24px;
        //white-space: nowrap;
    }

    p {
        flex: 1 1;
        padding: $spacing-small 0;
    }

    &__footer-action {
        &--added {
            color: #2ed23e;
        }

        &--add {
            color: $ci-blue;
            cursor: pointer;
        }
    }

    &__provider {
        position: absolute;
        bottom: $spacing-small;
        right: $spacing-small;
        max-width: 100px;

        img {
            width: 100%;
            height: 100%;
            image-rendering: -webkit-optimize-contrast;
        }
    }
}
.marketplace-category {
    width: 100%;
    flex: auto;
    text-transform: uppercase;
    font-size: 12px;
    padding: $spacing-small;
    background: $grey1;
    &--wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }
}
.insights-marketplace .hox-modal {
    width: 1000px;
    height: 90vh;

    .hox-modal__header {
        margin-bottom: 0;
    }

    .hox-modal__body {
        padding: 0;
        height: calc(90vh - 67px);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .hox-modal__header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: $spacing-larger;
        padding-left: $spacing-semi-large;
    }
}
