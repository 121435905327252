
@import "@/../sass/_variables.scss";

.dropdown-button {
    position: relative;

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    &.ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}

.dropdown-button--active {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2) !important;

    .ivu-select-arrow {
        transform: rotate(180deg);
        top: calc(50% - 7px);
    }
}

.dropdown-button__text {
    /*
    Because we're using .ivu-select-arrow to style our dropdown arrow we
    need to provide some extra padding for the content in the right of the
    button.
  */
    padding: 0 9px 0 0;
}
