
@import "../../../sass/variables";
.missing-font-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__message {
        font-size: 12px;
        vertical-align: center;
    }

    &__close {
        cursor: pointer;
    }
}
