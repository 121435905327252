
@import "@/../sass/_variables.scss";

.multi-input {
    margin: 0 0 $spacing;
    width: 100%;
}

.multi-input--has-errors {
    margin: 0;
}

.multi-input__error-message {
    color: $error-color;
    font-size: $font-size-small;
    margin: 0 0 $spacing-small;
    &:last-child {
        margin: 0 0 $spacing;
    }
}

.multi-input__input-container {
    width: 100%;
}

.multi-input__value-wrapper {
    align-items: center;
    display: flex;
    margin: 0 0 $spacing-small;
}

.multi-input__value-wrapper--has-errors {
    margin: 0 0 $spacing-smallest;
}

.multi-input__remove-button-wrapper {
    margin: 0 0 0 $spacing-small;
}

.multi-input__remove-button-wrapper--invisible {
    opacity: 0;
    pointer-events: none;
}
