
.meta-isf {
    width: 100%;
    &__wrap {
        width: 100%;
        background-color: #8b8d91;
        padding-bottom: 10px;
        position: relative;
    }

    &__banner {
        &-video {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            &-wrap {
                display: flex;
                flex-direction: column;
            }
            &-play {
                height: 100%;
                padding: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                span {
                    cursor: pointer;
                    height: 75px;
                    width: 75px;
                    display: flex;
                    border-radius: 50%;
                    border: 3px solid #fff;
                    background: #494a4c;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-size: 42px;
                        color: #fff;
                    }
                }
            }
            &-wrap {
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                background: #000;
                display: none;
                &.visible {
                    display: flex;
                    flex-direction: column;
                }
            }
            &-media {
                margin-top: auto;
            }
            &-info {
                margin: 20px 10px 100px auto;
                color: #fff;
                font-weight: 600;
                background: #191919;
                padding: 5px 10px;
                border-radius: 5px;
            }
            &-actions {
                display: flex;
                align-items: center;
                margin-top: 10px;
                width: 100%;
                color: #fff;
                padding: 0 10px;
                button {
                    margin-left: auto;
                    color: #fff;
                    background: #191919;
                    font-weight: 600;
                    padding: 5px 10px;
                    border: 0;
                    border-radius: 5px;
                }
            }

            &-progress-bar {
                height: 4px;
                width: 100%;
                background: #000;
                &__fill {
                    height: 100%;
                    background: #d76a1c;
                    width: 0;
                    &.animated {
                        animation: progressAnimation 8s linear infinite;
                    }
                }
            }
        }
    }

    &__ph {
        display: flex;
        flex-direction: column;
        min-height: 500px;
        &-top {
            padding: 10px;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            width: 100%;
            span {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #babbbd;
            }
        }

        &-bottom {
            margin-top: auto;
            display: flex;
            padding: 10px;
            width: 100%;
        }

        &-info {
            padding-right: 20px;
            margin-top: auto;
            width: 100%;
            &-head {
                display: flex;
                gap: 10px;
                width: 100%;
                &__avatar {
                    display: inline-block;
                    flex: 0 0 auto;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #babbbd;
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 5px;
                    align-items: base-start;
                    justify-content: center;
                    span {
                        height: 8px;
                        background: #babbbd;
                        display: inline-block;
                        border-radius: 5px;
                        width: 45%;
                        &:last-child {
                            width: 30%;
                        }
                    }
                }
            }

            &-text {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin: 10px 0;
                span {
                    height: 8px;
                    background: #babbbd;
                    display: inline-block;
                    border-radius: 5px;
                    width: 100%;
                }
            }

            &-actions {
                width: 100%;
                display: flex;
                gap: 15px;
                margin: 10px 0 0;
                span {
                    height: 50px;
                    width: 50%;
                    background: #babbbd;
                    display: inline-block;
                    border-radius: 35px;
                }
            }
        }

        &-controls {
            display: flex;
            gap: 10px;
            flex-direction: column;
            margin-left: auto;

            span {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #babbbd;
            }
        }
    }
    &__data {
        display: flex;
        background: #444648;
        border-radius: 10px;
        margin: 10px;
        overflow: hidden;
        gap: 10px;
        &-video {
            width: 75px;
            .bannerWrapper,
            .banner-component-wrapper.banner-preview__banner {
                width: 75px !important;
                height: 75px !important;
                justify-content: flex-start;
                iframe {
                    width: 75px !important;
                    height: 75px !important;
                }
            }
        }
        &-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: #fff;
            margin: auto;
            span {
                display: inline-block;
            }
        }
        &-actions {
            display: flex;
            flex-direction: column;
            margin-block: auto;
            height: 60px;
            padding-right: 10px;
            i {
                color: #fff;
                font-weight: 600;
                &:first-child {
                    margin-bottom: auto;
                }
                &:last-child {
                    margin-top: auto;
                }
            }
        }
    }
    &__description {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        background: #282a2b;
        color: #fff;
        margin: 10px;
        &-info {
            display: flex;
            gap: 10px;
        }
        &-avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        a {
            &.meta-isf__description-link {
                color: #fff;
            }
        }
        &-text {
            padding-right: 0;
            font-weight: 600;
            a {
                &.meta-isv__description-link {
                    color: #606770;
                    display: inline-block;
                }
            }
        }
        .meta-isv__text {
            padding: 0;
            font-size: 14px;
            margin-bottom: 0;
        }
        > i {
            margin-left: auto;
            font-size: 24px;
            font-weight: 600;
        }
    }
}
@keyframes progressAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
