@import "hox-colours";
@import "ci-colours";

/*
  Semantic colors
*/
$primary-color: $electric-blue;
$info-color: $blue;
$success-color: $green;
$warning-color: $yellow;
$error-color: $red;

$link-color: $blue;
$link-hover-color: darken($link-color, 20%);

/*
  Font Sizes
*/
$font-size-smaller: 11px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-large: 16px;
$font-size-larger: 18px;
$font-size-largest: 22px;

$line-height-base: 1.5em;

/*
  Spacing
*/
$spacing-smallest: 2px;
$spacing-smaller: 4px;
$spacing-small: 8px;
$spacing-semi-small: 12px;
$spacing-base: 16px;
/*
  Kept `$spacing` because it was used before `$spacing-base` was created.

  We should eventually consolidate the two, perhaps when/if we move to maps.
*/
$spacing: $spacing-base;
$spacing-semi-large: 20px;
$spacing-large: 32px;
$spacing-larger: 48px;
$spacing-largest: 64px;

/*
  Borders
*/
$border-width-base: 1px;
$border-width-thick: 2px;
$border-width-thicker: 4px;

$border-radius-largest: 12px;
$border-radius-larger: 6px;
$border-radius-large: 3px;
$border-radius-base: 2px;

/*
  Shadows
*/
$box-shadow-light: 0 1px 6px rgba(0, 0, 0, 0.1);
$box-shadow-base: 0 1px 6px rgba(0, 0, 0, 0.2);

/*
  Transitions
*/
$transition-function-base: ease-in-out;
$transition-time-base: 0.2s;

/*
  Inputs
*/
$input-height-small: 24px;
$input-height-base: 34px;
$input-height-large: 44px;

/*
  Buttons
*/
$btn-font-size: $font-size-base;
$btn-font-size-large: $font-size-large;
$btn-border-radius: $border-radius-base;
$btn-padding-base: 5px 15px 6px;
$btn-padding-large: 9px 15px 9px 15px;
$btn-padding-small: 1px 7px 2px;
$btn-padding-base-icon: 5px 15px 6px;
$btn-padding-large-icon: 6px 15px 6px 15px;
$btn-padding-small-icon: 1px 7px 2px;

/*
  z-index
*/
$zindex-loading-layer: 1;
$zindex-qa-cursor-info: 8;
$zindex-default-modal: 1000;
$zindex-campaign-nav: 10;
$zindex-creative-insights-nav: 10;
$zindex-notifications-drawer: 10;
$zindex-footer: 11;

/*
  Coppied from iview.theme
*/
$table-td-hover-bg: #ebf7ff;

/*
  Our Variables
*/
$primary: $primary-color;
$primary-green: $hogarth-aqua;
$primary-green-dark: darken($primary-green, 5%);
$primary-green-light: lighten($primary-green, 7%);

$banner-preview-bg-color: $grey4;
$campaign-nav-bg: $darktheme100;
$campaign-nav-color: rgba($white, 0.85);
$campaign-nav-height: 60px;
$campaign-vertical-nav-width: 60px;
$campaign-main-content-padding-top: 30px;
$campaign-sidebar-item-height: 48px;
$campaign-editor-split-pane-width: 500px;
$campaign-library-pane-width: 300px;
$portal-pane-width: 300px;

$creative-insights-nav-bg: $darktheme100;
$creative-insights-vertical-nav-width: 60px;

$internal-annotation-color: $info-color;
$external-annotation-color: $primary-color;

/*
  Campaign styles dark
*/
$cmp-dark-bg-color: #1b1f28;
$cmp-dark-secondary-bg-color: #171a22;
$cmp-dark-tertiary-bg-color: #252d37;

$cmp-dark-font-color: $grey1;
$cmp-dark-disabled-color: darken($cmp-dark-font-color, 30%);

$cmp-dark-primary-sidebar-color: $cmp-dark-bg-color;
$cmp-dark-secondary-sidebar-color: $cmp-dark-tertiary-bg-color;

$cmp-dark-border-color: $darktheme100;
$cmp-dark-border-width: 3px;

$cmp-dark-input-bg-color: $darktheme80;

$cmp-dark-tag-default-bg: $darktheme20;
$cmp-dark-tag-primary-bg: $darktheme40;
$cmp-dark-tag-primary-border: $blue;

$cmp-dark-active: $blue;
$cmp-dark-btn-bg-color: $darktheme40;

/*
  Campaign styles light
*/
$cmp-light-bg-color: #fafafa;

/*
  $cmp-light-secondary-font-color and $cmp-light-font-color seem to be incorrectly
  named: the names should be swapped.
*/
$cmp-light-secondary-font-color: $darktheme20;
$cmp-light-font-color: $grey5;
$cmp-light-tertiary-font-color: $grey4;

$cmp-light-border-color: $grey3;
$cmp-light-filters-border-color: $grey1;
$cmp-light-filters-bg-color: $white;
$cmp-light-filters-hover-color: $cmp-light-bg-color;

$cmp-light-active: $blue;

$cmp-light-hoxton-h: #001029;

$filter-badge-bg-color: #dcdcdc;
$filter-badge-bg-font-color: #666666;

$cmp-light-default-font-color: $darktheme20;

/*
  Vue bar scrubbar styles
*/
$vb-width: 6px;
$vb-dragger-color: $grey2;
$vb-dragger-hover-color: darken($grey2, 7.5%);

@import "./iview-icons";
