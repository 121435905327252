
@import "@/../sass/_variables.scss";

.bar-chart {
    width: 100%;
    height: 100%;
}

.creative-insights-chart__chart-wrapper {
    &__chart-wrapper {
        height: 380px;
    }
}

.horizontal-bar-chart__chart {
    height: 380px;
}
