
@import "@/../sass/_variables.scss";

.banner-info-poptip {
    &.ivu-poptip {
        max-width: 100%;
    }

    .ivu-poptip-rel {
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        white-space: nowrap;
        width: 100%;
    }
}

.banner-info-poptip__content {
    overflow: hidden;
    padding: 0 $spacing-smaller;
}

.banner-info-poptip__info {
    max-height: 300px;
    max-width: 200px;
    overflow-y: auto;
    padding: $spacing;
}

.banner-info-poptip__info-list {
    > li {
        border-bottom: 1px solid $grey3;
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-small;
        font-weight: bold;
        justify-content: space-between;
        padding: 0 0 $spacing-smaller;
        margin: 0 0 $spacing-smaller;

        p {
            width: 100%;
        }

        &:last-child {
            border-bottom: none;
            margin: 0;
            padding: 0;
        }
    }
}

.banner-info-poptip__info-list-title {
    font-weight: normal;
}

.banner-info-poptip__info-title {
    font-size: $font-size-base;
    font-weight: bold;
    margin: 0 0 $spacing;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
