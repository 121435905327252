
@import "../sass/variables";

.theme-campaign-light {
    .campaign {
        &-header {
            background-color: $cmp-light-bg-color;
            border-bottom: 1px solid $cmp-light-border-color;

            &__breadcrumb {
                span.inactive-link {
                    color: $cmp-light-secondary-font-color;
                }

                a {
                    color: $cmp-light-secondary-font-color;

                    &:hover {
                        color: $cmp-light-secondary-font-color;
                    }
                }
            }
        }

        &-banner {
            border: 1px solid $cmp-light-border-color;
            box-sizing: content-box;

            &-footer {
                color: $cmp-light-font-color;
            }
        }
    }

    .ivu-layout-sider {
        background-color: $white;

        .sidebar-body {
            width: $campaign-library-pane-width;
            top: 0;
            position: fixed !important;
            overflow: auto !important;
            height: 100vh;
        }
    }

    .main-content {
        background-color: $cmp-light-bg-color;
    }
}
