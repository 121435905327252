
@import "@/../sass/_variables.scss";

.share-preview-modal__deliverable-label {
    font-size: $font-size-base;
    margin: 0 0 $spacing-small;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.share-preview-modal__deliverables-container {
    margin: 0 0 $spacing;
    max-height: 300px;
    overflow: hidden;
    position: relative;
}

.share-preview-modal__deliverables-container--scrollable {
    overflow-y: scroll;
}

.share-preview-modal__footer-button-wrapper {
    display: inline-block;
    margin: 0 0 0 $spacing-small;
}

.share-preview-modal__preview-url-input-wrapper {
    flex: 1;
    margin: 0 $spacing-small 0 0;
}

.share-preview-modal__preview-url-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: (0 - $spacing-smaller) 0 $spacing;
}

.share-preview-modal__show-all-deliverables-container {
    background: $white;
    border-top: 1px solid $grey3;
    bottom: 0;
    box-shadow: 0 -10px 20px $white;
    left: -20px;
    padding: $spacing-small 0 0;
    position: absolute;
    right: -20px;
    text-align: center;
}

.share-preview-modal__title {
    font-size: $font-size-large;
    margin: 0 0 $spacing;
    padding: $spacing 0 0;
}

.share-preview-modal__title-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
