
@import "../../sass/_variables.scss";

$thumbnail-height: 200px;

.font-card {
    &__thumbnail {
        align-items: center;
        background: no-repeat center;
        background-size: cover;
        color: $grey5;
        display: flex;
        height: $thumbnail-height;
        justify-content: center;
        width: 100%;
    }

    &__postscript-warning {
        color: $warning-color;
        margin-right: $spacing-small;
    }

    .card__footer-title {
        line-height: 24px;
    }
}
