
@import "@/../sass/_variables.scss";
.template-upload-modal {
    p.small-margin {
        margin-bottom: $spacing-small;
    }
    &__button {
        margin-left: $spacing-small;
    }
    p.template-upload-modal__alert-text {
        font-size: $font-size-small;
    }

    &__label {
        font-size: $font-size-small;
    }

    ul {
        margin-bottom: $spacing-small;
    }

    li {
        font-size: $font-size-small;
        margin-left: $spacing-large;
    }

    &__removed {
        margin-top: $spacing-small;
    }
}
