
@import "../../../sass/variables";

.platform-select {
    &__option {
        border-top: 1px solid transparent;

        &--divider {
            border-top: 1px solid $grey3;
            margin-top: 5px;
            padding-top: 7px;

            &:before {
                content: "";
                height: 5px;
                display: block;
                background-color: $white;
                width: calc(100% + 2 * 16px);
                margin-left: -$spacing;
                position: relative;
                top: -7px;
            }
        }
    }
}
