
@import "@/../sass/_variables.scss";

.library-deliverable-table-published-status-cell {
    overflow: hidden;
}

.library-deliverable-table-published-status-cell .ivu-tag:last-child {
    margin-right: 0;
}

.library-deliverable-table-published-status-cell--full-width-tags {
    .ivu-tag {
        text-align: center;
        width: 100%;
    }
}

.library-deliverable-table-published-status-cell__tag--disabled {
    color: $grey5;
    opacity: 0.9;
    pointer-events: none;

    .ivu-tag-text {
        color: $grey5;
    }
}
