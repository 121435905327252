
@import "../../sass/variables";

.sidebar-body {
    height: calc(100vh);
    width: 100%;

    &--scroll {
        position: sticky !important;
        top: 0;
    }
}
