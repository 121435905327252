
@import "@/../sass/_variables.scss";

.set-deliverables-visible-to-dropdown {
    .ivu-checkbox-wrapper {
        display: block;
        margin: 0;
        padding: $spacing-small $spacing $spacing-small $spacing-small;
    }

    .ivu-dropdown-item {
        padding: 0;
    }
}

.set-deliverables-visible-to-dropdown__dropdown-button {
    margin: 0 0 0 $spacing-small;
}

.set-deliverables-visible-to-dropdown__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing $spacing-small ($spacing-small - 5px) $spacing-large;
}
