
@import "@/../sass/_variables.scss";

.delete-font-modal {
    &__alert-wrapper {
        padding: $spacing 0 0;
    }

    &__button {
        margin: 0 0 0 $spacing-small;
    }
}
