
@import "@/../sass/_variables.scss";

.download-modal__button {
    margin: 0 0 0 $spacing-small;
}

.download-modal__select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 $spacing;
}

.download-modal__select-platform {
    flex: 1 0;
    width: 100%;
    margin-left: 20px;
}

.download-modal__dc-profile-input {
    margin-bottom: $spacing-small;

    &--error {
        border: 1px solid $error-color;

        .ivu-input:focus,
        .ivu-input:hover {
            border-color: $error-color;
        }
    }

    &-error-message {
        color: $error-color;
    }
}

.download-modal__options-wrapper {
    h4 {
        display: inline-block;
    }

    ul {
        list-style: none;

        li {
            margin-bottom: $spacing-semi-small;
        }
    }

    .ivu-checkbox {
        margin-right: $spacing-small;
    }

    p {
        font-size: $font-size-smaller;
        margin-left: 26px;
    }

    .download-modal__short-input {
        &-value {
            width: 78px;
            float: right;
            margin-top: -35px;
            height: 35px;
        }

        .ivu-input-group-append {
            background-color: transparent;
            border: 0 none;
        }
    }

    .download-modal__long-input {
        &-value {
            width: 220px;
            float: right;
            margin-top: -35px;
            height: 35px;
        }

        .ivu-input-group-append {
            background-color: transparent;
            border: 0 none;
        }
    }
}
