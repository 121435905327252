
@import "@/../sass/_variables.scss";

.qa-confirmation-modal__alert-wrapper {
    padding: $spacing 0 0;
    margin: 0 0 (0 - $spacing);
}

.qa-confirmation-modal__button {
    margin: 0 0 0 $spacing-small;

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    &.ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}
