
@import "@/../sass/_variables.scss";
.resizable-campaign-banner {
    .campaign-banner__social {
        z-index: 22;
        top: 0;
        margin: 10px;
        width: 100%;
        .campaign-edit__header-action-select {
            max-width: auto;
            margin-left: 10px;
        }
    }

    .campaign-banner__social--no-resize {
        background: #fff;
        padding: 10px;
        position: relative;
        width: 100%;
    }

    &.campaign-banner--meta-preview {
        .banner-preview {
            display: flex;
        }
    }
}
.campaign-banner.hide-footer-options .campaign-banner-footer__options {
    display: none;
}

.campaign-banner__banner-container {
    flex: 1 1;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    &-checkbox {
        width: 100%;
        height: 100%;
    }

    .ivu-checkbox {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .ivu-checkbox-inner {
        height: 20px;
        margin: $spacing-small 0 0 $spacing-small;
        width: 20px;

        &:after {
            height: 14px;
            left: 8px;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
    }
}
.campaign-edit__header-action-select {
    span {
        display: block;
        font-size: 12px;
    }
}
