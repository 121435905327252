// Ionicons Font Path
// --------------------------

@font-face {
    font-family: @ionicons-font-family;
    src: url(/fonts/ionicons.woff2) format("woff2"),
         url(/fonts/ionicons.woff) format("woff"),
         url(/fonts/ionicons.ttf) format("truetype"),
         url(/fonts/ionicons.svg) format("svg");
    // src: url("@{ionicons-font-path}/ionicons.woff2?v=@{ionicons-version}") format("woff2"),
    //      url("@{ionicons-font-path}/ionicons.woff?v=@{ionicons-version}") format("woff"),
    //      url("@{ionicons-font-path}/ionicons.ttf?v=@{ionicons-version}") format("truetype"),
    //      url("@{ionicons-font-path}/ionicons.svg?v=@{ionicons-version}#Ionicons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.ivu-icon() {
    display: inline-block;
    font-family: @ionicons-font-family;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
}

.ivu-icon {
    .ivu-icon();
}
