// from the colours xslx
$ci-blue: rgba(24, 144, 255, 1);
$ci-blue-bg: #e6f7ff;

$ci-font-color: rgba(0, 0, 0, 0.85);
$ci-font-color-secondary: rgba(0, 0, 0, 0.45);

$ci-quadrant-red: #f9444e;
$ci-quadrant-green: #41b883;
$ci-quadrant-orange: #ee825e;

$ci-light-gray: #a5a5a5;
