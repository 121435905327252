
@import "../../../../sass/variables";

.editable-image-item .widget-mode {
    color: $cmp-dark-disabled-color;

    &--active {
        color: $cmp-dark-font-color;
    }
}
