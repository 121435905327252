
@import "@/../sass/_variables.scss";

$stripe-opacity: 0.4;
$stripe-size: 10px;
$thickness: $border-width-thicker;

@keyframes progress-bar-stripes {
    from {
        background-position: 20px 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress-bar {
    background: $grey2;
    height: $thickness;
    width: 100%;
    border-radius: $thickness;
    position: relative;

    &--margin-bottom-base {
        margin-bottom: $spacing-base;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--margin-bottom-none {
        margin-bottom: 0;
    }
}

.progress-bar__inner {
    border-radius: $thickness;
    height: 100%;
    position: absolute;
    left: 0;
    transition: width $transition-time-base $transition-function-base;

    &--type-in-progress {
        background: $primary-color;
    }

    &--type-success {
        background: $success-color;
    }

    &--type-error {
        background: $error-color;
    }
}

.progress-bar__inner--animated {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, $stripe-opacity) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, $stripe-opacity) 50%,
        rgba(255, 255, 255, $stripe-opacity) 75%,
        transparent 75%,
        transparent
    );
    background-size: $stripe-size $stripe-size;
    animation: progress-bar-stripes 1s linear infinite;
}
