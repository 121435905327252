
@import "@/../sass/_variables.scss";

.resize-settings__algorithm-description {
    color: $grey5;
    font-size: $font-size-small;
    padding: $spacing-smaller 0 0 0;
}

.resize-settings__radio-wrapper {
    margin: 0 0 $spacing-small;

    &--manual {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ivu-radio {
        display: none;
    }
}

.resize-settings__select-label {
    display: block;
    font-size: $font-size-base;
    font-weight: bold;
    margin: 0 0 $spacing-smaller;
}
