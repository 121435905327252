
@import "../../../sass/variables";

.user-permission-modal {
    &-alert {
        margin-top: 20px;

        .alert__icon-container {
            padding-bottom: 0;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;

        &-subtitle {
            padding-top: 8px;
            font-weight: normal;
            font-size: 12px;
        }
    }

    &-newuser {
        padding-bottom: 24px;

        .ivu-form-item-content {
            line-height: 21px;
        }
    }

    &-add-client {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 8;
    }

    &-table {
        th {
            .ivu-table-cell {
                padding-left: 0;
                padding-right: 0;
            }
        }

        td {
            .ivu-table-cell {
                padding: 0 8px;
                margin-bottom: 6px;
            }

            &.bordered-column > .ivu-table-cell {
                border: 1px solid $grey3;
                height: 34px;
            }

            &.bordered-column.client-name > .ivu-table-cell {
                padding: 6px 8px;
            }
        }

        .new-entry-row {
            td.bordered-column > .ivu-table-cell {
                background-color: $grey1;
            }

            td.bordered-column.client-name > .ivu-table-cell {
                padding: 5px 0 4px 0;
            }
        }

        .client-campaigns > .ivu-table-cell {
            padding: 6px 6px 6px 0;
            margin-bottom: 6px;
        }

        &--ghost {
            border: 0 none transparent;

            .ivu-table {
                &::before,
                &::after {
                    background-color: inherit;
                }

                th,
                td {
                    background-color: inherit;
                    border-color: transparent;
                    height: 25px;
                }

                table {
                    border-color: transparent;
                    background-color: inherit;
                }
            }
        }

        &-wrapper {
            position: relative;
        }

        .select-client-notice {
            padding-left: 6px;
        }

        .ivu-icon-md-close {
            cursor: pointer;
        }
    }

    &-button__delete {
        margin-right: auto;
    }
}
