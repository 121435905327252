
@import "@/../sass/_variables.scss";
.text-stats-chart {
    position: relative;
    height: 48px;
    padding: 0;
    margin: 0;

    h4 {
        padding-left: 18px;
        font-size: 14px;
        top: 4px;
    }

    &__chart {
        width: 100%;
    }

    &__wrapper {
        height: 28px;
        padding: 0;
        margin: 0;
    }
}
