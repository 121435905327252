@import "variables";

@mixin box-shadow-solid($color: $darktheme20, $opacity: 0.2) {
    box-shadow: 0 0 0 2px rgba($color, $opacity);
}

@mixin clearfix() {
    &:after {
        display: block;
        content: " ";
        clear: both;
    }
}

@mixin make-checked-background($check-color, $check-size, $background-color: transparent) {
    background: $background-color;
    background-image: linear-gradient(45deg, $check-color 25%, transparent 25%),
        linear-gradient(-45deg, $check-color 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, $check-color 75%),
        linear-gradient(-45deg, transparent 75%, $check-color 75%);
    background-size: ($check-size * 2) ($check-size * 2);
    background-position: 0 0, 0 $check-size, $check-size (0 - $check-size), (0 - $check-size) 0px;
}
