
@import "@/../sass/_variables.scss";

.quadrant-text {
    font-size: 10px;
    color: $ci-font-color-secondary;
    text-transform: uppercase;
}

.quadrant-text--style {
    &-00 {
        color: $ci-font-color-secondary;
    }

    &-01 {
        color: $ci-quadrant-red;
    }

    &-10 {
        color: $ci-quadrant-orange;
    }

    &-11 {
        color: $ci-quadrant-green;
    }
}
