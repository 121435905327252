
@import "../../../../sass/variables";

.editable-url-widget__link-icon {
    color: $cmp-dark-font-color;

    &:hover {
        color: $cmp-dark-font-color;
    }
}
