
@import "@/../sass/_variables.scss";
@import "@/../sass/creative-insights-charts.scss";
.insights-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.insights-dashboard {
    flex: 1 1 100%;
    height: 100%;
    width: calc(100% - 84px);
    margin: 0 42px;
    image-rendering: -webkit-optimize-contrast;

    &--drawer-is-open {
        overflow: hidden;
    }

    &-no-data {
        margin: $spacing-largest auto auto;
    }

    &-no-kpis {
        margin: 20vh auto auto;
    }

    &__marketplace {
        margin: $spacing-smaller $spacing-smaller $spacing;
        height: 175px;

        .ivu-card-body {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }

        h3 {
            margin: $spacing-small 0 $spacing;
            font-size: 14px;

            span {
                color: $ci-blue;
            }
        }

        .ivu-btn {
            background-color: $ci-blue;
            color: white;
            border: 0 transparent;
        }
    }
}

.insights--no-scroll {
    overflow: hidden;
    height: 100vh;
}

.diversity-warning .alert__icon-container {
    padding-top: $spacing-small;
}

.insights-dashboard-tag-component {
    width: 50%;
    flex: 0 1 50%;
    padding: $spacing-smaller;

    &--dragged {
        opacity: 0.5;
    }
}

.insights-dashboard-tag-components {
    display: flex; // swap to grid
    flex-wrap: wrap;
    justify-content: space-between;

    &__draggable-wrapper {
        width: 100%;
        display: flex; // swap to grid
        flex-wrap: wrap;
        justify-content: space-between;

        .insights-dashboard-tag-component__drag-handle {
            cursor: move;
        }
    }
}

.dashboard-filters {
    margin: 0 $spacing-smaller;
}
