
@import "@/../sass/_variables.scss";

.library-banner {
    border: 1px solid $grey3;
    box-sizing: content-box;
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    position: relative;

    &--resizable {
        resize: both;
        overflow: auto;
    }
}

.library-banner__banner-container {
    flex: 1 1;
    align-items: center;
    display: flex;
    font-size: 0;
    justify-content: center;
    position: relative;

    &-checkbox--resizable {
        width: 100%;
        height: 100%;
    }

    .ivu-checkbox {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .ivu-checkbox-inner {
        height: 20px;
        margin: $spacing-small 0 0 $spacing-small;
        width: 20px;

        &:after {
            height: 14px;
            left: 8px;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
    }
}

.library-banner__footer {
    flex: 0 0 40px;
    align-items: center;
    background: $white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.library-banner__title {
    color: $darktheme20;
    flex: 1 0;
    font-size: $font-size-small;
    min-width: 0;
}
