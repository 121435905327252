
@import "@/../sass/_variables.scss";

.library-deliverable-table {
    height: 100%;

    &--locked-campaign {
        height: calc(100% - 64px);
    }
}
