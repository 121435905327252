
@import "../sass/variables";

.user-feedback-menu {
    .user-menu__actions {
        display: flex;
    }
    .user-menu-option {
        display: flex;
        &--knowledge {
            display: flex;
            flex-direction: column;
            span {
                font-size: 9px;
                margin-top: -3px;
            }
        }
    }
}
