// Ionicons Icons
// --------------------------

.ivu-icon-ios-add-circle-outline:before {
    content: "\f100";
}
.ivu-icon-ios-add-circle:before {
    content: "\f101";
}
.ivu-icon-ios-add:before {
    content: "\f102";
}
.ivu-icon-ios-alarm-outline:before {
    content: "\f103";
}
.ivu-icon-ios-alarm:before {
    content: "\f104";
}
.ivu-icon-ios-albums-outline:before {
    content: "\f105";
}
.ivu-icon-ios-albums:before {
    content: "\f106";
}
.ivu-icon-ios-alert-outline:before {
    content: "\f107";
}
.ivu-icon-ios-alert:before {
    content: "\f108";
}
.ivu-icon-ios-american-football-outline:before {
    content: "\f109";
}
.ivu-icon-ios-american-football:before {
    content: "\f10a";
}
.ivu-icon-ios-analytics-outline:before {
    content: "\f10b";
}
.ivu-icon-ios-analytics:before {
    content: "\f10c";
}
.ivu-icon-ios-aperture-outline:before {
    content: "\f10d";
}
.ivu-icon-ios-aperture:before {
    content: "\f10e";
}
.ivu-icon-ios-apps-outline:before {
    content: "\f10f";
}
.ivu-icon-ios-apps:before {
    content: "\f110";
}
.ivu-icon-ios-appstore-outline:before {
    content: "\f111";
}
.ivu-icon-ios-appstore:before {
    content: "\f112";
}
.ivu-icon-ios-archive-outline:before {
    content: "\f113";
}
.ivu-icon-ios-archive:before {
    content: "\f114";
}
.ivu-icon-ios-arrow-back:before {
    content: "\f115";
}
.ivu-icon-ios-arrow-down:before {
    content: "\f116";
}
.ivu-icon-ios-arrow-dropdown-circle:before {
    content: "\f117";
}
.ivu-icon-ios-arrow-dropdown:before {
    content: "\f118";
}
.ivu-icon-ios-arrow-dropleft-circle:before {
    content: "\f119";
}
.ivu-icon-ios-arrow-dropleft:before {
    content: "\f11a";
}
.ivu-icon-ios-arrow-dropright-circle:before {
    content: "\f11b";
}
.ivu-icon-ios-arrow-dropright:before {
    content: "\f11c";
}
.ivu-icon-ios-arrow-dropup-circle:before {
    content: "\f11d";
}
.ivu-icon-ios-arrow-dropup:before {
    content: "\f11e";
}
.ivu-icon-ios-arrow-forward:before {
    content: "\f11f";
}
.ivu-icon-ios-arrow-round-back:before {
    content: "\f120";
}
.ivu-icon-ios-arrow-round-down:before {
    content: "\f121";
}
.ivu-icon-ios-arrow-round-forward:before {
    content: "\f122";
}
.ivu-icon-ios-arrow-round-up:before {
    content: "\f123";
}
.ivu-icon-ios-arrow-up:before {
    content: "\f124";
}
.ivu-icon-ios-at-outline:before {
    content: "\f125";
}
.ivu-icon-ios-at:before {
    content: "\f126";
}
.ivu-icon-ios-attach:before {
    content: "\f127";
}
.ivu-icon-ios-backspace-outline:before {
    content: "\f128";
}
.ivu-icon-ios-backspace:before {
    content: "\f129";
}
.ivu-icon-ios-barcode-outline:before {
    content: "\f12a";
}
.ivu-icon-ios-barcode:before {
    content: "\f12b";
}
.ivu-icon-ios-baseball-outline:before {
    content: "\f12c";
}
.ivu-icon-ios-baseball:before {
    content: "\f12d";
}
.ivu-icon-ios-basket-outline:before {
    content: "\f12e";
}
.ivu-icon-ios-basket:before {
    content: "\f12f";
}
.ivu-icon-ios-basketball-outline:before {
    content: "\f130";
}
.ivu-icon-ios-basketball:before {
    content: "\f131";
}
.ivu-icon-ios-battery-charging:before {
    content: "\f132";
}
.ivu-icon-ios-battery-dead:before {
    content: "\f133";
}
.ivu-icon-ios-battery-full:before {
    content: "\f134";
}
.ivu-icon-ios-beaker-outline:before {
    content: "\f135";
}
.ivu-icon-ios-beaker:before {
    content: "\f136";
}
.ivu-icon-ios-beer-outline:before {
    content: "\f137";
}
.ivu-icon-ios-beer:before {
    content: "\f138";
}
.ivu-icon-ios-bicycle:before {
    content: "\f139";
}
.ivu-icon-ios-bluetooth:before {
    content: "\f13a";
}
.ivu-icon-ios-boat-outline:before {
    content: "\f13b";
}
.ivu-icon-ios-boat:before {
    content: "\f13c";
}
.ivu-icon-ios-body-outline:before {
    content: "\f13d";
}
.ivu-icon-ios-body:before {
    content: "\f13e";
}
.ivu-icon-ios-bonfire-outline:before {
    content: "\f13f";
}
.ivu-icon-ios-bonfire:before {
    content: "\f140";
}
.ivu-icon-ios-book-outline:before {
    content: "\f141";
}
.ivu-icon-ios-book:before {
    content: "\f142";
}
.ivu-icon-ios-bookmark-outline:before {
    content: "\f143";
}
.ivu-icon-ios-bookmark:before {
    content: "\f144";
}
.ivu-icon-ios-bookmarks-outline:before {
    content: "\f145";
}
.ivu-icon-ios-bookmarks:before {
    content: "\f146";
}
.ivu-icon-ios-bowtie-outline:before {
    content: "\f147";
}
.ivu-icon-ios-bowtie:before {
    content: "\f148";
}
.ivu-icon-ios-briefcase-outline:before {
    content: "\f149";
}
.ivu-icon-ios-briefcase:before {
    content: "\f14a";
}
.ivu-icon-ios-browsers-outline:before {
    content: "\f14b";
}
.ivu-icon-ios-browsers:before {
    content: "\f14c";
}
.ivu-icon-ios-brush-outline:before {
    content: "\f14d";
}
.ivu-icon-ios-brush:before {
    content: "\f14e";
}
.ivu-icon-ios-bug-outline:before {
    content: "\f14f";
}
.ivu-icon-ios-bug:before {
    content: "\f150";
}
.ivu-icon-ios-build-outline:before {
    content: "\f151";
}
.ivu-icon-ios-build:before {
    content: "\f152";
}
.ivu-icon-ios-bulb-outline:before {
    content: "\f153";
}
.ivu-icon-ios-bulb:before {
    content: "\f154";
}
.ivu-icon-ios-bus-outline:before {
    content: "\f155";
}
.ivu-icon-ios-bus:before {
    content: "\f156";
}
.ivu-icon-ios-cafe-outline:before {
    content: "\f157";
}
.ivu-icon-ios-cafe:before {
    content: "\f158";
}
.ivu-icon-ios-calculator-outline:before {
    content: "\f159";
}
.ivu-icon-ios-calculator:before {
    content: "\f15a";
}
.ivu-icon-ios-calendar-outline:before {
    content: "\f15b";
}
.ivu-icon-ios-calendar:before {
    content: "\f15c";
}
.ivu-icon-ios-call-outline:before {
    content: "\f15d";
}
.ivu-icon-ios-call:before {
    content: "\f15e";
}
.ivu-icon-ios-camera-outline:before {
    content: "\f15f";
}
.ivu-icon-ios-camera:before {
    content: "\f160";
}
.ivu-icon-ios-car-outline:before {
    content: "\f161";
}
.ivu-icon-ios-car:before {
    content: "\f162";
}
.ivu-icon-ios-card-outline:before {
    content: "\f163";
}
.ivu-icon-ios-card:before {
    content: "\f164";
}
.ivu-icon-ios-cart-outline:before {
    content: "\f165";
}
.ivu-icon-ios-cart:before {
    content: "\f166";
}
.ivu-icon-ios-cash-outline:before {
    content: "\f167";
}
.ivu-icon-ios-cash:before {
    content: "\f168";
}
.ivu-icon-ios-chatboxes-outline:before {
    content: "\f169";
}
.ivu-icon-ios-chatboxes:before {
    content: "\f16a";
}
.ivu-icon-ios-chatbubbles-outline:before {
    content: "\f16b";
}
.ivu-icon-ios-chatbubbles:before {
    content: "\f16c";
}
.ivu-icon-ios-checkbox-outline:before {
    content: "\f16d";
}
.ivu-icon-ios-checkbox:before {
    content: "\f16e";
}
.ivu-icon-ios-checkmark-circle-outline:before {
    content: "\f16f";
}
.ivu-icon-ios-checkmark-circle:before {
    content: "\f170";
}
.ivu-icon-ios-checkmark:before {
    content: "\f171";
}
.ivu-icon-ios-clipboard-outline:before {
    content: "\f172";
}
.ivu-icon-ios-clipboard:before {
    content: "\f173";
}
.ivu-icon-ios-clock-outline:before {
    content: "\f174";
}
.ivu-icon-ios-clock:before {
    content: "\f175";
}
.ivu-icon-ios-close-circle-outline:before {
    content: "\f176";
}
.ivu-icon-ios-close-circle:before {
    content: "\f177";
}
.ivu-icon-ios-close:before {
    content: "\f178";
}
.ivu-icon-ios-closed-captioning-outline:before {
    content: "\f179";
}
.ivu-icon-ios-closed-captioning:before {
    content: "\f17a";
}
.ivu-icon-ios-cloud-circle-outline:before {
    content: "\f17b";
}
.ivu-icon-ios-cloud-circle:before {
    content: "\f17c";
}
.ivu-icon-ios-cloud-done-outline:before {
    content: "\f17d";
}
.ivu-icon-ios-cloud-done:before {
    content: "\f17e";
}
.ivu-icon-ios-cloud-download-outline:before {
    content: "\f17f";
}
.ivu-icon-ios-cloud-download:before {
    content: "\f180";
}
.ivu-icon-ios-cloud-outline:before {
    content: "\f181";
}
.ivu-icon-ios-cloud-upload-outline:before {
    content: "\f182";
}
.ivu-icon-ios-cloud-upload:before {
    content: "\f183";
}
.ivu-icon-ios-cloud:before {
    content: "\f184";
}
.ivu-icon-ios-cloudy-night-outline:before {
    content: "\f185";
}
.ivu-icon-ios-cloudy-night:before {
    content: "\f186";
}
.ivu-icon-ios-cloudy-outline:before {
    content: "\f187";
}
.ivu-icon-ios-cloudy:before {
    content: "\f188";
}
.ivu-icon-ios-code-download:before {
    content: "\f189";
}
.ivu-icon-ios-code-working:before {
    content: "\f18a";
}
.ivu-icon-ios-code:before {
    content: "\f18b";
}
.ivu-icon-ios-cog-outline:before {
    content: "\f18c";
}
.ivu-icon-ios-cog:before {
    content: "\f18d";
}
.ivu-icon-ios-color-fill-outline:before {
    content: "\f18e";
}
.ivu-icon-ios-color-fill:before {
    content: "\f18f";
}
.ivu-icon-ios-color-filter-outline:before {
    content: "\f190";
}
.ivu-icon-ios-color-filter:before {
    content: "\f191";
}
.ivu-icon-ios-color-palette-outline:before {
    content: "\f192";
}
.ivu-icon-ios-color-palette:before {
    content: "\f193";
}
.ivu-icon-ios-color-wand-outline:before {
    content: "\f194";
}
.ivu-icon-ios-color-wand:before {
    content: "\f195";
}
.ivu-icon-ios-compass-outline:before {
    content: "\f196";
}
.ivu-icon-ios-compass:before {
    content: "\f197";
}
.ivu-icon-ios-construct-outline:before {
    content: "\f198";
}
.ivu-icon-ios-construct:before {
    content: "\f199";
}
.ivu-icon-ios-contact-outline:before {
    content: "\f19a";
}
.ivu-icon-ios-contact:before {
    content: "\f19b";
}
.ivu-icon-ios-contacts-outline:before {
    content: "\f19c";
}
.ivu-icon-ios-contacts:before {
    content: "\f19d";
}
.ivu-icon-ios-contract:before {
    content: "\f19e";
}
.ivu-icon-ios-contrast:before {
    content: "\f19f";
}
.ivu-icon-ios-copy-outline:before {
    content: "\f1a0";
}
.ivu-icon-ios-copy:before {
    content: "\f1a1";
}
.ivu-icon-ios-create-outline:before {
    content: "\f1a2";
}
.ivu-icon-ios-create:before {
    content: "\f1a3";
}
.ivu-icon-ios-crop-outline:before {
    content: "\f1a4";
}
.ivu-icon-ios-crop:before {
    content: "\f1a5";
}
.ivu-icon-ios-cube-outline:before {
    content: "\f1a6";
}
.ivu-icon-ios-cube:before {
    content: "\f1a7";
}
.ivu-icon-ios-cut-outline:before {
    content: "\f1a8";
}
.ivu-icon-ios-cut:before {
    content: "\f1a9";
}
.ivu-icon-ios-desktop-outline:before {
    content: "\f1aa";
}
.ivu-icon-ios-desktop:before {
    content: "\f1ab";
}
.ivu-icon-ios-disc-outline:before {
    content: "\f1ac";
}
.ivu-icon-ios-disc:before {
    content: "\f1ad";
}
.ivu-icon-ios-document-outline:before {
    content: "\f1ae";
}
.ivu-icon-ios-document:before {
    content: "\f1af";
}
.ivu-icon-ios-done-all:before {
    content: "\f1b0";
}
.ivu-icon-ios-download-outline:before {
    content: "\f1b1";
}
.ivu-icon-ios-download:before {
    content: "\f1b2";
}
.ivu-icon-ios-easel-outline:before {
    content: "\f1b3";
}
.ivu-icon-ios-easel:before {
    content: "\f1b4";
}
.ivu-icon-ios-egg-outline:before {
    content: "\f1b5";
}
.ivu-icon-ios-egg:before {
    content: "\f1b6";
}
.ivu-icon-ios-exit-outline:before {
    content: "\f1b7";
}
.ivu-icon-ios-exit:before {
    content: "\f1b8";
}
.ivu-icon-ios-expand:before {
    content: "\f1b9";
}
.ivu-icon-ios-eye-off-outline:before {
    content: "\f1ba";
}
.ivu-icon-ios-eye-off:before {
    content: "\f1bb";
}
.ivu-icon-ios-eye-outline:before {
    content: "\f1bc";
}
.ivu-icon-ios-eye:before {
    content: "\f1bd";
}
.ivu-icon-ios-fastforward-outline:before {
    content: "\f1be";
}
.ivu-icon-ios-fastforward:before {
    content: "\f1bf";
}
.ivu-icon-ios-female:before {
    content: "\f1c0";
}
.ivu-icon-ios-filing-outline:before {
    content: "\f1c1";
}
.ivu-icon-ios-filing:before {
    content: "\f1c2";
}
.ivu-icon-ios-film-outline:before {
    content: "\f1c3";
}
.ivu-icon-ios-film:before {
    content: "\f1c4";
}
.ivu-icon-ios-finger-print:before {
    content: "\f1c5";
}
.ivu-icon-ios-flag-outline:before {
    content: "\f1c6";
}
.ivu-icon-ios-flag:before {
    content: "\f1c7";
}
.ivu-icon-ios-flame-outline:before {
    content: "\f1c8";
}
.ivu-icon-ios-flame:before {
    content: "\f1c9";
}
.ivu-icon-ios-flash-outline:before {
    content: "\f1ca";
}
.ivu-icon-ios-flash:before {
    content: "\f1cb";
}
.ivu-icon-ios-flask-outline:before {
    content: "\f1cc";
}
.ivu-icon-ios-flask:before {
    content: "\f1cd";
}
.ivu-icon-ios-flower-outline:before {
    content: "\f1ce";
}
.ivu-icon-ios-flower:before {
    content: "\f1cf";
}
.ivu-icon-ios-folder-open-outline:before {
    content: "\f1d0";
}
.ivu-icon-ios-folder-open:before {
    content: "\f1d1";
}
.ivu-icon-ios-folder-outline:before {
    content: "\f1d2";
}
.ivu-icon-ios-folder:before {
    content: "\f1d3";
}
.ivu-icon-ios-football-outline:before {
    content: "\f1d4";
}
.ivu-icon-ios-football:before {
    content: "\f1d5";
}
.ivu-icon-ios-funnel-outline:before {
    content: "\f1d6";
}
.ivu-icon-ios-funnel:before {
    content: "\f1d7";
}
.ivu-icon-ios-game-controller-a-outline:before {
    content: "\f1d8";
}
.ivu-icon-ios-game-controller-a:before {
    content: "\f1d9";
}
.ivu-icon-ios-game-controller-b-outline:before {
    content: "\f1da";
}
.ivu-icon-ios-game-controller-b:before {
    content: "\f1db";
}
.ivu-icon-ios-git-branch:before {
    content: "\f1dc";
}
.ivu-icon-ios-git-commit:before {
    content: "\f1dd";
}
.ivu-icon-ios-git-compare:before {
    content: "\f1de";
}
.ivu-icon-ios-git-merge:before {
    content: "\f1df";
}
.ivu-icon-ios-git-network:before {
    content: "\f1e0";
}
.ivu-icon-ios-git-pull-request:before {
    content: "\f1e1";
}
.ivu-icon-ios-glasses-outline:before {
    content: "\f1e2";
}
.ivu-icon-ios-glasses:before {
    content: "\f1e3";
}
.ivu-icon-ios-globe-outline:before {
    content: "\f1e4";
}
.ivu-icon-ios-globe:before {
    content: "\f1e5";
}
.ivu-icon-ios-grid-outline:before {
    content: "\f1e6";
}
.ivu-icon-ios-grid:before {
    content: "\f1e7";
}
.ivu-icon-ios-hammer-outline:before {
    content: "\f1e8";
}
.ivu-icon-ios-hammer:before {
    content: "\f1e9";
}
.ivu-icon-ios-hand-outline:before {
    content: "\f1ea";
}
.ivu-icon-ios-hand:before {
    content: "\f1eb";
}
.ivu-icon-ios-happy-outline:before {
    content: "\f1ec";
}
.ivu-icon-ios-happy:before {
    content: "\f1ed";
}
.ivu-icon-ios-headset-outline:before {
    content: "\f1ee";
}
.ivu-icon-ios-headset:before {
    content: "\f1ef";
}
.ivu-icon-ios-heart-outline:before {
    content: "\f1f0";
}
.ivu-icon-ios-heart:before {
    content: "\f1f1";
}
.ivu-icon-ios-help-buoy-outline:before {
    content: "\f1f2";
}
.ivu-icon-ios-help-buoy:before {
    content: "\f1f3";
}
.ivu-icon-ios-help-circle-outline:before {
    content: "\f1f4";
}
.ivu-icon-ios-help-circle:before {
    content: "\f1f5";
}
.ivu-icon-ios-help:before {
    content: "\f1f6";
}
.ivu-icon-ios-home-outline:before {
    content: "\f1f7";
}
.ivu-icon-ios-home:before {
    content: "\f1f8";
}
.ivu-icon-ios-ice-cream-outline:before {
    content: "\f1f9";
}
.ivu-icon-ios-ice-cream:before {
    content: "\f1fa";
}
.ivu-icon-ios-image-outline:before {
    content: "\f1fb";
}
.ivu-icon-ios-image:before {
    content: "\f1fc";
}
.ivu-icon-ios-images-outline:before {
    content: "\f1fd";
}
.ivu-icon-ios-images:before {
    content: "\f1fe";
}
.ivu-icon-ios-infinite-outline:before {
    content: "\f1ff";
}
.ivu-icon-ios-infinite:before {
    content: "\f200";
}
.ivu-icon-ios-information-circle-outline:before {
    content: "\f201";
}
.ivu-icon-ios-information-circle:before {
    content: "\f202";
}
.ivu-icon-ios-information:before {
    content: "\f203";
}
.ivu-icon-ios-ionic-outline:before {
    content: "\f204";
}
.ivu-icon-ios-ionic:before {
    content: "\f205";
}
.ivu-icon-ios-ionitron-outline:before {
    content: "\f206";
}
.ivu-icon-ios-ionitron:before {
    content: "\f207";
}
.ivu-icon-ios-jet-outline:before {
    content: "\f208";
}
.ivu-icon-ios-jet:before {
    content: "\f209";
}
.ivu-icon-ios-key-outline:before {
    content: "\f20a";
}
.ivu-icon-ios-key:before {
    content: "\f20b";
}
.ivu-icon-ios-keypad-outline:before {
    content: "\f20c";
}
.ivu-icon-ios-keypad:before {
    content: "\f20d";
}
.ivu-icon-ios-laptop:before {
    content: "\f20e";
}
.ivu-icon-ios-leaf-outline:before {
    content: "\f20f";
}
.ivu-icon-ios-leaf:before {
    content: "\f210";
}
.ivu-icon-ios-link-outline:before {
    content: "\f211";
}
.ivu-icon-ios-link:before {
    content: "\f212";
}
.ivu-icon-ios-list-box-outline:before {
    content: "\f213";
}
.ivu-icon-ios-list-box:before {
    content: "\f214";
}
.ivu-icon-ios-list:before {
    content: "\f215";
}
.ivu-icon-ios-locate-outline:before {
    content: "\f216";
}
.ivu-icon-ios-locate:before {
    content: "\f217";
}
.ivu-icon-ios-lock-outline:before {
    content: "\f218";
}
.ivu-icon-ios-lock:before {
    content: "\f219";
}
.ivu-icon-ios-log-in:before {
    content: "\f21a";
}
.ivu-icon-ios-log-out:before {
    content: "\f21b";
}
.ivu-icon-ios-magnet-outline:before {
    content: "\f21c";
}
.ivu-icon-ios-magnet:before {
    content: "\f21d";
}
.ivu-icon-ios-mail-open-outline:before {
    content: "\f21e";
}
.ivu-icon-ios-mail-open:before {
    content: "\f21f";
}
.ivu-icon-ios-mail-outline:before {
    content: "\f220";
}
.ivu-icon-ios-mail:before {
    content: "\f221";
}
.ivu-icon-ios-male:before {
    content: "\f222";
}
.ivu-icon-ios-man-outline:before {
    content: "\f223";
}
.ivu-icon-ios-man:before {
    content: "\f224";
}
.ivu-icon-ios-map-outline:before {
    content: "\f225";
}
.ivu-icon-ios-map:before {
    content: "\f226";
}
.ivu-icon-ios-medal-outline:before {
    content: "\f227";
}
.ivu-icon-ios-medal:before {
    content: "\f228";
}
.ivu-icon-ios-medical-outline:before {
    content: "\f229";
}
.ivu-icon-ios-medical:before {
    content: "\f22a";
}
.ivu-icon-ios-medkit-outline:before {
    content: "\f22b";
}
.ivu-icon-ios-medkit:before {
    content: "\f22c";
}
.ivu-icon-ios-megaphone-outline:before {
    content: "\f22d";
}
.ivu-icon-ios-megaphone:before {
    content: "\f22e";
}
.ivu-icon-ios-menu-outline:before {
    content: "\f22f";
}
.ivu-icon-ios-menu:before {
    content: "\f230";
}
.ivu-icon-ios-mic-off-outline:before {
    content: "\f231";
}
.ivu-icon-ios-mic-off:before {
    content: "\f232";
}
.ivu-icon-ios-mic-outline:before {
    content: "\f233";
}
.ivu-icon-ios-mic:before {
    content: "\f234";
}
.ivu-icon-ios-microphone-outline:before {
    content: "\f235";
}
.ivu-icon-ios-microphone:before {
    content: "\f236";
}
.ivu-icon-ios-moon-outline:before {
    content: "\f237";
}
.ivu-icon-ios-moon:before {
    content: "\f238";
}
.ivu-icon-ios-more-outline:before {
    content: "\f239";
}
.ivu-icon-ios-more:before {
    content: "\f23a";
}
.ivu-icon-ios-move:before {
    content: "\f23b";
}
.ivu-icon-ios-musical-note-outline:before {
    content: "\f23c";
}
.ivu-icon-ios-musical-note:before {
    content: "\f23d";
}
.ivu-icon-ios-musical-notes-outline:before {
    content: "\f23e";
}
.ivu-icon-ios-musical-notes:before {
    content: "\f23f";
}
.ivu-icon-ios-navigate-outline:before {
    content: "\f240";
}
.ivu-icon-ios-navigate:before {
    content: "\f241";
}
.ivu-icon-ios-no-smoking-outline:before {
    content: "\f242";
}
.ivu-icon-ios-no-smoking:before {
    content: "\f243";
}
.ivu-icon-ios-notifications-off-outline:before {
    content: "\f244";
}
.ivu-icon-ios-notifications-off:before {
    content: "\f245";
}
.ivu-icon-ios-notifications-outline:before {
    content: "\f246";
}
.ivu-icon-ios-notifications:before {
    content: "\f247";
}
.ivu-icon-ios-nuclear-outline:before {
    content: "\f248";
}
.ivu-icon-ios-nuclear:before {
    content: "\f249";
}
.ivu-icon-ios-nutrition-outline:before {
    content: "\f24a";
}
.ivu-icon-ios-nutrition:before {
    content: "\f24b";
}
.ivu-icon-ios-open-outline:before {
    content: "\f24c";
}
.ivu-icon-ios-open:before {
    content: "\f24d";
}
.ivu-icon-ios-options-outline:before {
    content: "\f24e";
}
.ivu-icon-ios-options:before {
    content: "\f24f";
}
.ivu-icon-ios-outlet-outline:before {
    content: "\f250";
}
.ivu-icon-ios-outlet:before {
    content: "\f251";
}
.ivu-icon-ios-paper-outline:before {
    content: "\f252";
}
.ivu-icon-ios-paper-plane-outline:before {
    content: "\f253";
}
.ivu-icon-ios-paper-plane:before {
    content: "\f254";
}
.ivu-icon-ios-paper:before {
    content: "\f255";
}
.ivu-icon-ios-partly-sunny-outline:before {
    content: "\f256";
}
.ivu-icon-ios-partly-sunny:before {
    content: "\f257";
}
.ivu-icon-ios-pause-outline:before {
    content: "\f258";
}
.ivu-icon-ios-pause:before {
    content: "\f259";
}
.ivu-icon-ios-paw-outline:before {
    content: "\f25a";
}
.ivu-icon-ios-paw:before {
    content: "\f25b";
}
.ivu-icon-ios-people-outline:before {
    content: "\f25c";
}
.ivu-icon-ios-people:before {
    content: "\f25d";
}
.ivu-icon-ios-person-add-outline:before {
    content: "\f25e";
}
.ivu-icon-ios-person-add:before {
    content: "\f25f";
}
.ivu-icon-ios-person-outline:before {
    content: "\f260";
}
.ivu-icon-ios-person:before {
    content: "\f261";
}
.ivu-icon-ios-phone-landscape:before {
    content: "\f262";
}
.ivu-icon-ios-phone-portrait:before {
    content: "\f263";
}
.ivu-icon-ios-photos-outline:before {
    content: "\f264";
}
.ivu-icon-ios-photos:before {
    content: "\f265";
}
.ivu-icon-ios-pie-outline:before {
    content: "\f266";
}
.ivu-icon-ios-pie:before {
    content: "\f267";
}
.ivu-icon-ios-pin-outline:before {
    content: "\f268";
}
.ivu-icon-ios-pin:before {
    content: "\f269";
}
.ivu-icon-ios-pint-outline:before {
    content: "\f26a";
}
.ivu-icon-ios-pint:before {
    content: "\f26b";
}
.ivu-icon-ios-pizza-outline:before {
    content: "\f26c";
}
.ivu-icon-ios-pizza:before {
    content: "\f26d";
}
.ivu-icon-ios-plane-outline:before {
    content: "\f26e";
}
.ivu-icon-ios-plane:before {
    content: "\f26f";
}
.ivu-icon-ios-planet-outline:before {
    content: "\f270";
}
.ivu-icon-ios-planet:before {
    content: "\f271";
}
.ivu-icon-ios-play-outline:before {
    content: "\f272";
}
.ivu-icon-ios-play:before {
    content: "\f273";
}
.ivu-icon-ios-podium-outline:before {
    content: "\f274";
}
.ivu-icon-ios-podium:before {
    content: "\f275";
}
.ivu-icon-ios-power-outline:before {
    content: "\f276";
}
.ivu-icon-ios-power:before {
    content: "\f277";
}
.ivu-icon-ios-pricetag-outline:before {
    content: "\f278";
}
.ivu-icon-ios-pricetag:before {
    content: "\f279";
}
.ivu-icon-ios-pricetags-outline:before {
    content: "\f27a";
}
.ivu-icon-ios-pricetags:before {
    content: "\f27b";
}
.ivu-icon-ios-print-outline:before {
    content: "\f27c";
}
.ivu-icon-ios-print:before {
    content: "\f27d";
}
.ivu-icon-ios-pulse-outline:before {
    content: "\f27e";
}
.ivu-icon-ios-pulse:before {
    content: "\f27f";
}
.ivu-icon-ios-qr-scanner:before {
    content: "\f280";
}
.ivu-icon-ios-quote-outline:before {
    content: "\f281";
}
.ivu-icon-ios-quote:before {
    content: "\f282";
}
.ivu-icon-ios-radio-button-off:before {
    content: "\f283";
}
.ivu-icon-ios-radio-button-on:before {
    content: "\f284";
}
.ivu-icon-ios-radio-outline:before {
    content: "\f285";
}
.ivu-icon-ios-radio:before {
    content: "\f286";
}
.ivu-icon-ios-rainy-outline:before {
    content: "\f287";
}
.ivu-icon-ios-rainy:before {
    content: "\f288";
}
.ivu-icon-ios-recording-outline:before {
    content: "\f289";
}
.ivu-icon-ios-recording:before {
    content: "\f28a";
}
.ivu-icon-ios-redo-outline:before {
    content: "\f28b";
}
.ivu-icon-ios-redo:before {
    content: "\f28c";
}
.ivu-icon-ios-refresh-circle-outline:before {
    content: "\f28d";
}
.ivu-icon-ios-refresh-circle:before {
    content: "\f28e";
}
.ivu-icon-ios-refresh:before {
    content: "\f28f";
}
.ivu-icon-ios-remove-circle-outline:before {
    content: "\f290";
}
.ivu-icon-ios-remove-circle:before {
    content: "\f291";
}
.ivu-icon-ios-remove:before {
    content: "\f292";
}
.ivu-icon-ios-reorder:before {
    content: "\f293";
}
.ivu-icon-ios-repeat:before {
    content: "\f294";
}
.ivu-icon-ios-resize:before {
    content: "\f295";
}
.ivu-icon-ios-restaurant-outline:before {
    content: "\f296";
}
.ivu-icon-ios-restaurant:before {
    content: "\f297";
}
.ivu-icon-ios-return-left:before {
    content: "\f298";
}
.ivu-icon-ios-return-right:before {
    content: "\f299";
}
.ivu-icon-ios-reverse-camera-outline:before {
    content: "\f29a";
}
.ivu-icon-ios-reverse-camera:before {
    content: "\f29b";
}
.ivu-icon-ios-rewind-outline:before {
    content: "\f29c";
}
.ivu-icon-ios-rewind:before {
    content: "\f29d";
}
.ivu-icon-ios-ribbon-outline:before {
    content: "\f29e";
}
.ivu-icon-ios-ribbon:before {
    content: "\f29f";
}
.ivu-icon-ios-rose-outline:before {
    content: "\f2a0";
}
.ivu-icon-ios-rose:before {
    content: "\f2a1";
}
.ivu-icon-ios-sad-outline:before {
    content: "\f2a2";
}
.ivu-icon-ios-sad:before {
    content: "\f2a3";
}
.ivu-icon-ios-school-outline:before {
    content: "\f2a4";
}
.ivu-icon-ios-school:before {
    content: "\f2a5";
}
.ivu-icon-ios-search-outline:before {
    content: "\f2a6";
}
.ivu-icon-ios-search:before {
    content: "\f2a7";
}
.ivu-icon-ios-send-outline:before {
    content: "\f2a8";
}
.ivu-icon-ios-send:before {
    content: "\f2a9";
}
.ivu-icon-ios-settings-outline:before {
    content: "\f2aa";
}
.ivu-icon-ios-settings:before {
    content: "\f2ab";
}
.ivu-icon-ios-share-alt-outline:before {
    content: "\f2ac";
}
.ivu-icon-ios-share-alt:before {
    content: "\f2ad";
}
.ivu-icon-ios-share-outline:before {
    content: "\f2ae";
}
.ivu-icon-ios-share:before {
    content: "\f2af";
}
.ivu-icon-ios-shirt-outline:before {
    content: "\f2b0";
}
.ivu-icon-ios-shirt:before {
    content: "\f2b1";
}
.ivu-icon-ios-shuffle:before {
    content: "\f2b2";
}
.ivu-icon-ios-skip-backward-outline:before {
    content: "\f2b3";
}
.ivu-icon-ios-skip-backward:before {
    content: "\f2b4";
}
.ivu-icon-ios-skip-forward-outline:before {
    content: "\f2b5";
}
.ivu-icon-ios-skip-forward:before {
    content: "\f2b6";
}
.ivu-icon-ios-snow-outline:before {
    content: "\f2b7";
}
.ivu-icon-ios-snow:before {
    content: "\f2b8";
}
.ivu-icon-ios-speedometer-outline:before {
    content: "\f2b9";
}
.ivu-icon-ios-speedometer:before {
    content: "\f2ba";
}
.ivu-icon-ios-square-outline:before {
    content: "\f2bb";
}
.ivu-icon-ios-square:before {
    content: "\f2bc";
}
.ivu-icon-ios-star-half:before {
    content: "\f2bd";
}
.ivu-icon-ios-star-outline:before {
    content: "\f2be";
}
.ivu-icon-ios-star:before {
    content: "\f2bf";
}
.ivu-icon-ios-stats-outline:before {
    content: "\f2c0";
}
.ivu-icon-ios-stats:before {
    content: "\f2c1";
}
.ivu-icon-ios-stopwatch-outline:before {
    content: "\f2c2";
}
.ivu-icon-ios-stopwatch:before {
    content: "\f2c3";
}
.ivu-icon-ios-subway-outline:before {
    content: "\f2c4";
}
.ivu-icon-ios-subway:before {
    content: "\f2c5";
}
.ivu-icon-ios-sunny-outline:before {
    content: "\f2c6";
}
.ivu-icon-ios-sunny:before {
    content: "\f2c7";
}
.ivu-icon-ios-swap:before {
    content: "\f2c8";
}
.ivu-icon-ios-switch-outline:before {
    content: "\f2c9";
}
.ivu-icon-ios-switch:before {
    content: "\f2ca";
}
.ivu-icon-ios-sync:before {
    content: "\f2cb";
}
.ivu-icon-ios-tablet-landscape:before {
    content: "\f2cc";
}
.ivu-icon-ios-tablet-portrait:before {
    content: "\f2cd";
}
.ivu-icon-ios-tennisball-outline:before {
    content: "\f2ce";
}
.ivu-icon-ios-tennisball:before {
    content: "\f2cf";
}
.ivu-icon-ios-text-outline:before {
    content: "\f2d0";
}
.ivu-icon-ios-text:before {
    content: "\f2d1";
}
.ivu-icon-ios-thermometer-outline:before {
    content: "\f2d2";
}
.ivu-icon-ios-thermometer:before {
    content: "\f2d3";
}
.ivu-icon-ios-thumbs-down-outline:before {
    content: "\f2d4";
}
.ivu-icon-ios-thumbs-down:before {
    content: "\f2d5";
}
.ivu-icon-ios-thumbs-up-outline:before {
    content: "\f2d6";
}
.ivu-icon-ios-thumbs-up:before {
    content: "\f2d7";
}
.ivu-icon-ios-thunderstorm-outline:before {
    content: "\f2d8";
}
.ivu-icon-ios-thunderstorm:before {
    content: "\f2d9";
}
.ivu-icon-ios-time-outline:before {
    content: "\f2da";
}
.ivu-icon-ios-time:before {
    content: "\f2db";
}
.ivu-icon-ios-timer-outline:before {
    content: "\f2dc";
}
.ivu-icon-ios-timer:before {
    content: "\f2dd";
}
.ivu-icon-ios-train-outline:before {
    content: "\f2de";
}
.ivu-icon-ios-train:before {
    content: "\f2df";
}
.ivu-icon-ios-transgender:before {
    content: "\f2e0";
}
.ivu-icon-ios-trash-outline:before {
    content: "\f2e1";
}
.ivu-icon-ios-trash:before {
    content: "\f2e2";
}
.ivu-icon-ios-trending-down:before {
    content: "\f2e3";
}
.ivu-icon-ios-trending-up:before {
    content: "\f2e4";
}
.ivu-icon-ios-trophy-outline:before {
    content: "\f2e5";
}
.ivu-icon-ios-trophy:before {
    content: "\f2e6";
}
.ivu-icon-ios-umbrella-outline:before {
    content: "\f2e7";
}
.ivu-icon-ios-umbrella:before {
    content: "\f2e8";
}
.ivu-icon-ios-undo-outline:before {
    content: "\f2e9";
}
.ivu-icon-ios-undo:before {
    content: "\f2ea";
}
.ivu-icon-ios-unlock-outline:before {
    content: "\f2eb";
}
.ivu-icon-ios-unlock:before {
    content: "\f2ec";
}
.ivu-icon-ios-videocam-outline:before {
    content: "\f2ed";
}
.ivu-icon-ios-videocam:before {
    content: "\f2ee";
}
.ivu-icon-ios-volume-down:before {
    content: "\f2ef";
}
.ivu-icon-ios-volume-mute:before {
    content: "\f2f0";
}
.ivu-icon-ios-volume-off:before {
    content: "\f2f1";
}
.ivu-icon-ios-volume-up:before {
    content: "\f2f2";
}
.ivu-icon-ios-walk:before {
    content: "\f2f3";
}
.ivu-icon-ios-warning-outline:before {
    content: "\f2f4";
}
.ivu-icon-ios-warning:before {
    content: "\f2f5";
}
.ivu-icon-ios-watch:before {
    content: "\f2f6";
}
.ivu-icon-ios-water-outline:before {
    content: "\f2f7";
}
.ivu-icon-ios-water:before {
    content: "\f2f8";
}
.ivu-icon-ios-wifi-outline:before {
    content: "\f2f9";
}
.ivu-icon-ios-wifi:before {
    content: "\f2fa";
}
.ivu-icon-ios-wine-outline:before {
    content: "\f2fb";
}
.ivu-icon-ios-wine:before {
    content: "\f2fc";
}
.ivu-icon-ios-woman-outline:before {
    content: "\f2fd";
}
.ivu-icon-ios-woman:before {
    content: "\f2fe";
}
.ivu-icon-logo-android:before {
    content: "\f2ff";
}
.ivu-icon-logo-angular:before {
    content: "\f300";
}
.ivu-icon-logo-apple:before {
    content: "\f301";
}
.ivu-icon-logo-bitcoin:before {
    content: "\f302";
}
.ivu-icon-logo-buffer:before {
    content: "\f303";
}
.ivu-icon-logo-chrome:before {
    content: "\f304";
}
.ivu-icon-logo-codepen:before {
    content: "\f305";
}
.ivu-icon-logo-css3:before {
    content: "\f306";
}
.ivu-icon-logo-designernews:before {
    content: "\f307";
}
.ivu-icon-logo-dribbble:before {
    content: "\f308";
}
.ivu-icon-logo-dropbox:before {
    content: "\f309";
}
.ivu-icon-logo-euro:before {
    content: "\f30a";
}
.ivu-icon-logo-facebook:before {
    content: "\f30b";
}
.ivu-icon-logo-foursquare:before {
    content: "\f30c";
}
.ivu-icon-logo-freebsd-devil:before {
    content: "\f30d";
}
.ivu-icon-logo-github:before {
    content: "\f30e";
}
.ivu-icon-logo-google:before {
    content: "\f30f";
}
.ivu-icon-logo-googleplus:before {
    content: "\f310";
}
.ivu-icon-logo-hackernews:before {
    content: "\f311";
}
.ivu-icon-logo-html5:before {
    content: "\f312";
}
.ivu-icon-logo-instagram:before {
    content: "\f313";
}
.ivu-icon-logo-javascript:before {
    content: "\f314";
}
.ivu-icon-logo-linkedin:before {
    content: "\f315";
}
.ivu-icon-logo-markdown:before {
    content: "\f316";
}
.ivu-icon-logo-nodejs:before {
    content: "\f317";
}
.ivu-icon-logo-octocat:before {
    content: "\f318";
}
.ivu-icon-logo-pinterest:before {
    content: "\f319";
}
.ivu-icon-logo-playstation:before {
    content: "\f31a";
}
.ivu-icon-logo-python:before {
    content: "\f31b";
}
.ivu-icon-logo-reddit:before {
    content: "\f31c";
}
.ivu-icon-logo-rss:before {
    content: "\f31d";
}
.ivu-icon-logo-sass:before {
    content: "\f31e";
}
.ivu-icon-logo-skype:before {
    content: "\f31f";
}
.ivu-icon-logo-snapchat:before {
    content: "\f320";
}
.ivu-icon-logo-steam:before {
    content: "\f321";
}
.ivu-icon-logo-tumblr:before {
    content: "\f322";
}
.ivu-icon-logo-tux:before {
    content: "\f323";
}
.ivu-icon-logo-twitch:before {
    content: "\f324";
}
.ivu-icon-logo-twitter:before {
    content: "\f325";
}
.ivu-icon-logo-usd:before {
    content: "\f326";
}
.ivu-icon-logo-vimeo:before {
    content: "\f327";
}
.ivu-icon-logo-whatsapp:before {
    content: "\f328";
}
.ivu-icon-logo-windows:before {
    content: "\f329";
}
.ivu-icon-logo-wordpress:before {
    content: "\f32a";
}
.ivu-icon-logo-xbox:before {
    content: "\f32b";
}
.ivu-icon-logo-yahoo:before {
    content: "\f32c";
}
.ivu-icon-logo-yen:before {
    content: "\f32d";
}
.ivu-icon-logo-youtube:before {
    content: "\f32e";
}
.ivu-icon-md-add-circle:before {
    content: "\f32f";
}
.ivu-icon-md-add:before {
    content: "\f330";
}
.ivu-icon-md-alarm:before {
    content: "\f331";
}
.ivu-icon-md-albums:before {
    content: "\f332";
}
.ivu-icon-md-alert:before {
    content: "\f333";
}
.ivu-icon-md-american-football:before {
    content: "\f334";
}
.ivu-icon-md-analytics:before {
    content: "\f335";
}
.ivu-icon-md-aperture:before {
    content: "\f336";
}
.ivu-icon-md-apps:before {
    content: "\f337";
}
.ivu-icon-md-appstore:before {
    content: "\f338";
}
.ivu-icon-md-archive:before {
    content: "\f339";
}
.ivu-icon-md-arrow-back:before {
    content: "\f33a";
}
.ivu-icon-md-arrow-down:before {
    content: "\f33b";
}
.ivu-icon-md-arrow-dropdown-circle:before {
    content: "\f33c";
}
.ivu-icon-md-arrow-dropdown:before {
    content: "\f33d";
}
.ivu-icon-md-arrow-dropleft-circle:before {
    content: "\f33e";
}
.ivu-icon-md-arrow-dropleft:before {
    content: "\f33f";
}
.ivu-icon-md-arrow-dropright-circle:before {
    content: "\f340";
}
.ivu-icon-md-arrow-dropright:before {
    content: "\f341";
}
.ivu-icon-md-arrow-dropup-circle:before {
    content: "\f342";
}
.ivu-icon-md-arrow-dropup:before {
    content: "\f343";
}
.ivu-icon-md-arrow-forward:before {
    content: "\f344";
}
.ivu-icon-md-arrow-round-back:before {
    content: "\f345";
}
.ivu-icon-md-arrow-round-down:before {
    content: "\f346";
}
.ivu-icon-md-arrow-round-forward:before {
    content: "\f347";
}
.ivu-icon-md-arrow-round-up:before {
    content: "\f348";
}
.ivu-icon-md-arrow-up:before {
    content: "\f349";
}
.ivu-icon-md-at:before {
    content: "\f34a";
}
.ivu-icon-md-attach:before {
    content: "\f34b";
}
.ivu-icon-md-backspace:before {
    content: "\f34c";
}
.ivu-icon-md-barcode:before {
    content: "\f34d";
}
.ivu-icon-md-baseball:before {
    content: "\f34e";
}
.ivu-icon-md-basket:before {
    content: "\f34f";
}
.ivu-icon-md-basketball:before {
    content: "\f350";
}
.ivu-icon-md-battery-charging:before {
    content: "\f351";
}
.ivu-icon-md-battery-dead:before {
    content: "\f352";
}
.ivu-icon-md-battery-full:before {
    content: "\f353";
}
.ivu-icon-md-beaker:before {
    content: "\f354";
}
.ivu-icon-md-beer:before {
    content: "\f355";
}
.ivu-icon-md-bicycle:before {
    content: "\f356";
}
.ivu-icon-md-bluetooth:before {
    content: "\f357";
}
.ivu-icon-md-boat:before {
    content: "\f358";
}
.ivu-icon-md-body:before {
    content: "\f359";
}
.ivu-icon-md-bonfire:before {
    content: "\f35a";
}
.ivu-icon-md-book:before {
    content: "\f35b";
}
.ivu-icon-md-bookmark:before {
    content: "\f35c";
}
.ivu-icon-md-bookmarks:before {
    content: "\f35d";
}
.ivu-icon-md-bowtie:before {
    content: "\f35e";
}
.ivu-icon-md-briefcase:before {
    content: "\f35f";
}
.ivu-icon-md-browsers:before {
    content: "\f360";
}
.ivu-icon-md-brush:before {
    content: "\f361";
}
.ivu-icon-md-bug:before {
    content: "\f362";
}
.ivu-icon-md-build:before {
    content: "\f363";
}
.ivu-icon-md-bulb:before {
    content: "\f364";
}
.ivu-icon-md-bus:before {
    content: "\f365";
}
.ivu-icon-md-cafe:before {
    content: "\f366";
}
.ivu-icon-md-calculator:before {
    content: "\f367";
}
.ivu-icon-md-calendar:before {
    content: "\f368";
}
.ivu-icon-md-call:before {
    content: "\f369";
}
.ivu-icon-md-camera:before {
    content: "\f36a";
}
.ivu-icon-md-car:before {
    content: "\f36b";
}
.ivu-icon-md-card:before {
    content: "\f36c";
}
.ivu-icon-md-cart:before {
    content: "\f36d";
}
.ivu-icon-md-cash:before {
    content: "\f36e";
}
.ivu-icon-md-chatboxes:before {
    content: "\f36f";
}
.ivu-icon-md-chatbubbles:before {
    content: "\f370";
}
.ivu-icon-md-checkbox-outline:before {
    content: "\f371";
}
.ivu-icon-md-checkbox:before {
    content: "\f372";
}
.ivu-icon-md-checkmark-circle-outline:before {
    content: "\f373";
}
.ivu-icon-md-checkmark-circle:before {
    content: "\f374";
}
.ivu-icon-md-checkmark:before {
    content: "\f375";
}
.ivu-icon-md-clipboard:before {
    content: "\f376";
}
.ivu-icon-md-clock:before {
    content: "\f377";
}
.ivu-icon-md-close-circle:before {
    content: "\f378";
}
.ivu-icon-md-close:before {
    content: "\f379";
}
.ivu-icon-md-closed-captioning:before {
    content: "\f37a";
}
.ivu-icon-md-cloud-circle:before {
    content: "\f37b";
}
.ivu-icon-md-cloud-done:before {
    content: "\f37c";
}
.ivu-icon-md-cloud-download:before {
    content: "\f37d";
}
.ivu-icon-md-cloud-outline:before {
    content: "\f37e";
}
.ivu-icon-md-cloud-upload:before {
    content: "\f37f";
}
.ivu-icon-md-cloud:before {
    content: "\f380";
}
.ivu-icon-md-cloudy-night:before {
    content: "\f381";
}
.ivu-icon-md-cloudy:before {
    content: "\f382";
}
.ivu-icon-md-code-download:before {
    content: "\f383";
}
.ivu-icon-md-code-working:before {
    content: "\f384";
}
.ivu-icon-md-code:before {
    content: "\f385";
}
.ivu-icon-md-cog:before {
    content: "\f386";
}
.ivu-icon-md-color-fill:before {
    content: "\f387";
}
.ivu-icon-md-color-filter:before {
    content: "\f388";
}
.ivu-icon-md-color-palette:before {
    content: "\f389";
}
.ivu-icon-md-color-wand:before {
    content: "\f38a";
}
.ivu-icon-md-compass:before {
    content: "\f38b";
}
.ivu-icon-md-construct:before {
    content: "\f38c";
}
.ivu-icon-md-contact:before {
    content: "\f38d";
}
.ivu-icon-md-contacts:before {
    content: "\f38e";
}
.ivu-icon-md-contract:before {
    content: "\f38f";
}
.ivu-icon-md-contrast:before {
    content: "\f390";
}
.ivu-icon-md-copy:before {
    content: "\f391";
}
.ivu-icon-md-create:before {
    content: "\f392";
}
.ivu-icon-md-crop:before {
    content: "\f393";
}
.ivu-icon-md-cube:before {
    content: "\f394";
}
.ivu-icon-md-cut:before {
    content: "\f395";
}
.ivu-icon-md-desktop:before {
    content: "\f396";
}
.ivu-icon-md-disc:before {
    content: "\f397";
}
.ivu-icon-md-document:before {
    content: "\f398";
}
.ivu-icon-md-done-all:before {
    content: "\f399";
}
.ivu-icon-md-download:before {
    content: "\f39a";
}
.ivu-icon-md-easel:before {
    content: "\f39b";
}
.ivu-icon-md-egg:before {
    content: "\f39c";
}
.ivu-icon-md-exit:before {
    content: "\f39d";
}
.ivu-icon-md-expand:before {
    content: "\f39e";
}
.ivu-icon-md-eye-off:before {
    content: "\f39f";
}
.ivu-icon-md-eye:before {
    content: "\f3a0";
}
.ivu-icon-md-fastforward:before {
    content: "\f3a1";
}
.ivu-icon-md-female:before {
    content: "\f3a2";
}
.ivu-icon-md-filing:before {
    content: "\f3a3";
}
.ivu-icon-md-film:before {
    content: "\f3a4";
}
.ivu-icon-md-finger-print:before {
    content: "\f3a5";
}
.ivu-icon-md-flag:before {
    content: "\f3a6";
}
.ivu-icon-md-flame:before {
    content: "\f3a7";
}
.ivu-icon-md-flash:before {
    content: "\f3a8";
}
.ivu-icon-md-flask:before {
    content: "\f3a9";
}
.ivu-icon-md-flower:before {
    content: "\f3aa";
}
.ivu-icon-md-folder-open:before {
    content: "\f3ab";
}
.ivu-icon-md-folder:before {
    content: "\f3ac";
}
.ivu-icon-md-football:before {
    content: "\f3ad";
}
.ivu-icon-md-funnel:before {
    content: "\f3ae";
}
.ivu-icon-md-game-controller-a:before {
    content: "\f3af";
}
.ivu-icon-md-game-controller-b:before {
    content: "\f3b0";
}
.ivu-icon-md-git-branch:before {
    content: "\f3b1";
}
.ivu-icon-md-git-commit:before {
    content: "\f3b2";
}
.ivu-icon-md-git-compare:before {
    content: "\f3b3";
}
.ivu-icon-md-git-merge:before {
    content: "\f3b4";
}
.ivu-icon-md-git-network:before {
    content: "\f3b5";
}
.ivu-icon-md-git-pull-request:before {
    content: "\f3b6";
}
.ivu-icon-md-glasses:before {
    content: "\f3b7";
}
.ivu-icon-md-globe:before {
    content: "\f3b8";
}
.ivu-icon-md-grid:before {
    content: "\f3b9";
}
.ivu-icon-md-hammer:before {
    content: "\f3ba";
}
.ivu-icon-md-hand:before {
    content: "\f3bb";
}
.ivu-icon-md-happy:before {
    content: "\f3bc";
}
.ivu-icon-md-headset:before {
    content: "\f3bd";
}
.ivu-icon-md-heart-outline:before {
    content: "\f3be";
}
.ivu-icon-md-heart:before {
    content: "\f3bf";
}
.ivu-icon-md-help-buoy:before {
    content: "\f3c0";
}
.ivu-icon-md-help-circle:before {
    content: "\f3c1";
}
.ivu-icon-md-help:before {
    content: "\f3c2";
}
.ivu-icon-md-home:before {
    content: "\f3c3";
}
.ivu-icon-md-ice-cream:before {
    content: "\f3c4";
}
.ivu-icon-md-image:before {
    content: "\f3c5";
}
.ivu-icon-md-images:before {
    content: "\f3c6";
}
.ivu-icon-md-infinite:before {
    content: "\f3c7";
}
.ivu-icon-md-information-circle:before {
    content: "\f3c8";
}
.ivu-icon-md-information:before {
    content: "\f3c9";
}
.ivu-icon-md-ionic:before {
    content: "\f3ca";
}
.ivu-icon-md-ionitron:before {
    content: "\f3cb";
}
.ivu-icon-md-jet:before {
    content: "\f3cc";
}
.ivu-icon-md-key:before {
    content: "\f3cd";
}
.ivu-icon-md-keypad:before {
    content: "\f3ce";
}
.ivu-icon-md-laptop:before {
    content: "\f3cf";
}
.ivu-icon-md-leaf:before {
    content: "\f3d0";
}
.ivu-icon-md-link:before {
    content: "\f3d1";
}
.ivu-icon-md-list-box:before {
    content: "\f3d2";
}
.ivu-icon-md-list:before {
    content: "\f3d3";
}
.ivu-icon-md-locate:before {
    content: "\f3d4";
}
.ivu-icon-md-lock:before {
    content: "\f3d5";
}
.ivu-icon-md-log-in:before {
    content: "\f3d6";
}
.ivu-icon-md-log-out:before {
    content: "\f3d7";
}
.ivu-icon-md-magnet:before {
    content: "\f3d8";
}
.ivu-icon-md-mail-open:before {
    content: "\f3d9";
}
.ivu-icon-md-mail:before {
    content: "\f3da";
}
.ivu-icon-md-male:before {
    content: "\f3db";
}
.ivu-icon-md-man:before {
    content: "\f3dc";
}
.ivu-icon-md-map:before {
    content: "\f3dd";
}
.ivu-icon-md-medal:before {
    content: "\f3de";
}
.ivu-icon-md-medical:before {
    content: "\f3df";
}
.ivu-icon-md-medkit:before {
    content: "\f3e0";
}
.ivu-icon-md-megaphone:before {
    content: "\f3e1";
}
.ivu-icon-md-menu:before {
    content: "\f3e2";
}
.ivu-icon-md-mic-off:before {
    content: "\f3e3";
}
.ivu-icon-md-mic:before {
    content: "\f3e4";
}
.ivu-icon-md-microphone:before {
    content: "\f3e5";
}
.ivu-icon-md-moon:before {
    content: "\f3e6";
}
.ivu-icon-md-more:before {
    content: "\f3e7";
}
.ivu-icon-md-move:before {
    content: "\f3e8";
}
.ivu-icon-md-musical-note:before {
    content: "\f3e9";
}
.ivu-icon-md-musical-notes:before {
    content: "\f3ea";
}
.ivu-icon-md-navigate:before {
    content: "\f3eb";
}
.ivu-icon-md-no-smoking:before {
    content: "\f3ec";
}
.ivu-icon-md-notifications-off:before {
    content: "\f3ed";
}
.ivu-icon-md-notifications-outline:before {
    content: "\f3ee";
}
.ivu-icon-md-notifications:before {
    content: "\f3ef";
}
.ivu-icon-md-nuclear:before {
    content: "\f3f0";
}
.ivu-icon-md-nutrition:before {
    content: "\f3f1";
}
.ivu-icon-md-open:before {
    content: "\f3f2";
}
.ivu-icon-md-options:before {
    content: "\f3f3";
}
.ivu-icon-md-outlet:before {
    content: "\f3f4";
}
.ivu-icon-md-paper-plane:before {
    content: "\f3f5";
}
.ivu-icon-md-paper:before {
    content: "\f3f6";
}
.ivu-icon-md-partly-sunny:before {
    content: "\f3f7";
}
.ivu-icon-md-pause:before {
    content: "\f3f8";
}
.ivu-icon-md-paw:before {
    content: "\f3f9";
}
.ivu-icon-md-people:before {
    content: "\f3fa";
}
.ivu-icon-md-person-add:before {
    content: "\f3fb";
}
.ivu-icon-md-person:before {
    content: "\f3fc";
}
.ivu-icon-md-phone-landscape:before {
    content: "\f3fd";
}
.ivu-icon-md-phone-portrait:before {
    content: "\f3fe";
}
.ivu-icon-md-photos:before {
    content: "\f3ff";
}
.ivu-icon-md-pie:before {
    content: "\f400";
}
.ivu-icon-md-pin:before {
    content: "\f401";
}
.ivu-icon-md-pint:before {
    content: "\f402";
}
.ivu-icon-md-pizza:before {
    content: "\f403";
}
.ivu-icon-md-plane:before {
    content: "\f404";
}
.ivu-icon-md-planet:before {
    content: "\f405";
}
.ivu-icon-md-play:before {
    content: "\f406";
}
.ivu-icon-md-podium:before {
    content: "\f407";
}
.ivu-icon-md-power:before {
    content: "\f408";
}
.ivu-icon-md-pricetag:before {
    content: "\f409";
}
.ivu-icon-md-pricetags:before {
    content: "\f40a";
}
.ivu-icon-md-print:before {
    content: "\f40b";
}
.ivu-icon-md-pulse:before {
    content: "\f40c";
}
.ivu-icon-md-qr-scanner:before {
    content: "\f40d";
}
.ivu-icon-md-quote:before {
    content: "\f40e";
}
.ivu-icon-md-radio-button-off:before {
    content: "\f40f";
}
.ivu-icon-md-radio-button-on:before {
    content: "\f410";
}
.ivu-icon-md-radio:before {
    content: "\f411";
}
.ivu-icon-md-rainy:before {
    content: "\f412";
}
.ivu-icon-md-recording:before {
    content: "\f413";
}
.ivu-icon-md-redo:before {
    content: "\f414";
}
.ivu-icon-md-refresh-circle:before {
    content: "\f415";
}
.ivu-icon-md-refresh:before {
    content: "\f416";
}
.ivu-icon-md-remove-circle:before {
    content: "\f417";
}
.ivu-icon-md-remove:before {
    content: "\f418";
}
.ivu-icon-md-reorder:before {
    content: "\f419";
}
.ivu-icon-md-repeat:before {
    content: "\f41a";
}
.ivu-icon-md-resize:before {
    content: "\f41b";
}
.ivu-icon-md-restaurant:before {
    content: "\f41c";
}
.ivu-icon-md-return-left:before {
    content: "\f41d";
}
.ivu-icon-md-return-right:before {
    content: "\f41e";
}
.ivu-icon-md-reverse-camera:before {
    content: "\f41f";
}
.ivu-icon-md-rewind:before {
    content: "\f420";
}
.ivu-icon-md-ribbon:before {
    content: "\f421";
}
.ivu-icon-md-rose:before {
    content: "\f422";
}
.ivu-icon-md-sad:before {
    content: "\f423";
}
.ivu-icon-md-school:before {
    content: "\f424";
}
.ivu-icon-md-search:before {
    content: "\f425";
}
.ivu-icon-md-send:before {
    content: "\f426";
}
.ivu-icon-md-settings:before {
    content: "\f427";
}
.ivu-icon-md-share-alt:before {
    content: "\f428";
}
.ivu-icon-md-share:before {
    content: "\f429";
}
.ivu-icon-md-shirt:before {
    content: "\f42a";
}
.ivu-icon-md-shuffle:before {
    content: "\f42b";
}
.ivu-icon-md-skip-backward:before {
    content: "\f42c";
}
.ivu-icon-md-skip-forward:before {
    content: "\f42d";
}
.ivu-icon-md-snow:before {
    content: "\f42e";
}
.ivu-icon-md-speedometer:before {
    content: "\f42f";
}
.ivu-icon-md-square-outline:before {
    content: "\f430";
}
.ivu-icon-md-square:before {
    content: "\f431";
}
.ivu-icon-md-star-half:before {
    content: "\f432";
}
.ivu-icon-md-star-outline:before {
    content: "\f433";
}
.ivu-icon-md-star:before {
    content: "\f434";
}
.ivu-icon-md-stats:before {
    content: "\f435";
}
.ivu-icon-md-stopwatch:before {
    content: "\f436";
}
.ivu-icon-md-subway:before {
    content: "\f437";
}
.ivu-icon-md-sunny:before {
    content: "\f438";
}
.ivu-icon-md-swap:before {
    content: "\f439";
}
.ivu-icon-md-switch:before {
    content: "\f43a";
}
.ivu-icon-md-sync:before {
    content: "\f43b";
}
.ivu-icon-md-tablet-landscape:before {
    content: "\f43c";
}
.ivu-icon-md-tablet-portrait:before {
    content: "\f43d";
}
.ivu-icon-md-tennisball:before {
    content: "\f43e";
}
.ivu-icon-md-text:before {
    content: "\f43f";
}
.ivu-icon-md-thermometer:before {
    content: "\f440";
}
.ivu-icon-md-thumbs-down:before {
    content: "\f441";
}
.ivu-icon-md-thumbs-up:before {
    content: "\f442";
}
.ivu-icon-md-thunderstorm:before {
    content: "\f443";
}
.ivu-icon-md-time:before {
    content: "\f444";
}
.ivu-icon-md-timer:before {
    content: "\f445";
}
.ivu-icon-md-train:before {
    content: "\f446";
}
.ivu-icon-md-transgender:before {
    content: "\f447";
}
.ivu-icon-md-trash:before {
    content: "\f448";
}
.ivu-icon-md-trending-down:before {
    content: "\f449";
}
.ivu-icon-md-trending-up:before {
    content: "\f44a";
}
.ivu-icon-md-trophy:before {
    content: "\f44b";
}
.ivu-icon-md-umbrella:before {
    content: "\f44c";
}
.ivu-icon-md-undo:before {
    content: "\f44d";
}
.ivu-icon-md-unlock:before {
    content: "\f44e";
}
.ivu-icon-md-videocam:before {
    content: "\f44f";
}
.ivu-icon-md-volume-down:before {
    content: "\f450";
}
.ivu-icon-md-volume-mute:before {
    content: "\f451";
}
.ivu-icon-md-volume-off:before {
    content: "\f452";
}
.ivu-icon-md-volume-up:before {
    content: "\f453";
}
.ivu-icon-md-walk:before {
    content: "\f454";
}
.ivu-icon-md-warning:before {
    content: "\f455";
}
.ivu-icon-md-watch:before {
    content: "\f456";
}
.ivu-icon-md-water:before {
    content: "\f457";
}
.ivu-icon-md-wifi:before {
    content: "\f458";
}
.ivu-icon-md-wine:before {
    content: "\f459";
}
.ivu-icon-md-woman:before {
    content: "\f45a";
}
.ivu-icon-ios-loading:before {
    content: "\f45b";
}