
.meta-fbs-post {
    width: 100%;
    background-color: #6a6a6a;
    border-top: 1px solid #ccd0d5;
    border-bottom: 1px solid #ccd0d5;
    position: relative;

    &__header {
        display: flex;
        align-items: center;
        padding: 20px 10px 10px;
        position: relative;
        z-index: 2;
        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 150px;
            background: rgb(87, 87, 87);
            background: linear-gradient(180deg, rgba(87, 87, 87, 1) 0%, rgba(255, 255, 255, 0) 55%);
        }

        .meta-fbs-post__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            i {
                width: 100%;
                height: 100%;
                font-size: 42px;
                color: #fff;
            }
        }

        .meta-fbs-post__user-info {
            flex-grow: 1;
            position: relative;

            .meta-fbs-post__user-name {
                font-weight: bold;
                font-size: 14px;
                color: #fff;
                line-height: 15px;
            }

            .meta-fbs-post__sponsored {
                font-weight: normal;
                font-size: 12px;
                color: #fff;
            }

            .meta-fbs-post__time {
                color: #fff;
                font-size: 12px;
            }
        }

        .meta-fbs-post__options {
            font-size: 18px;
            color: #fff;
            cursor: pointer;
            position: relative;
            i {
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
    &__progress {
        height: 100%;
        width: 30%;
        background-color: #ffffff;
        animation: progressAnimation 8s linear infinite;

        &-bar {
            height: 2px;
            background-color: rgba(205, 205, 205, 0.5);
            border-radius: 3px;
            position: absolute;
            top: 10px;
            left: 7px;
            width: calc(100% - 14px);
            z-index: 3;
        }
    }

    &__content {
        padding: 16px 0;
        position: relative;
        z-index: 0;
        &-overlay {
            position: absolute;
            bottom: 0;
            z-index: 0;
            width: 100%;
            height: 50%;
            background: rgb(87, 87, 87);
            background: linear-gradient(0deg, rgba(87, 87, 87, 1) 0%, rgba(255, 255, 255, 0) 55%);
        }
        .meta-fbs-post__text {
            font-size: 14px;
            padding: 0 16px;
            text-align: center;
            margin: 25px 0 45px;
            color: #fff;
            font-weight: 600;
            position: relative;
            z-index: 1;
        }

        .meta-fbs-post__image {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        padding: 0 16px;
        position: relative;
        z-index: 1;
        span {
            display: inline-block;
            line-height: 0px;
            font-size: 40px;
            position: relative;
            margin-bottom: -10px;
        }
        &-btn {
            flex-grow: unset;
            border: 0;
            margin: 10px auto auto;
            font-size: 24px;
            color: #2e4386;
            padding: 0 10px;
            background: #fff;
            border-radius: 6px;
            &__mess {
                border: 0;
                margin: 10px auto auto;
                background: #fff;
                font-size: 12px;
                color: #000;
                padding: 2px 12px;
                border-radius: 12px;
                i {
                    display: none;
                }
            }

            &__insta {
                text-transform: uppercase;
                padding: 0 10px;
                i {
                    font-size: 26px;
                }
            }
        }
        &-messenger-btn {
            i {
                display: none;
            }
        }
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 1;
        font-weight: 600;
        opacity: 100;
        transition: all 2s ease;

        span {
            margin-top: -30px;
            transition: all 2s ease;
        }

        &.hidden {
            transition-duration: 0s;
            opacity: 0;
            span {
                margin-top: 0;
            }
        }

        button {
            background: #fff;
            color: #000;
            border: 0;
            padding: 5px 10px;
            border-radius: 25px;
            margin-top: 20px;
            font-size: 12px;
        }
    }

    &__insta {
        background: rgb(149, 149, 149);
        background: linear-gradient(
            0deg,
            rgba(149, 149, 149, 1) 0%,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 0) 75%,
            rgba(149, 149, 149, 1) 100%
        );
        .meta-fbs-post__user-name {
            text-shadow: 0 0 1px #000;
        }
        .meta-fbs-post__content {
            .meta-fbs-post__text {
                text-align: left;
                color: #000;
            }
        }
    }

    &.full-height {
        .meta-fbs-post__header {
            position: absolute;
            width: 100%;
        }
        .meta-fbs-post__content {
            padding: 0;
            .meta-fbs-post__text {
                display: none;
            }
            .meta-fbs-post__description {
                position: absolute;
                width: 100%;
                bottom: 20px;
            }
        }
    }
}
@keyframes progressAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
