
@import "../../sass/variables";

.change-password {
    height: auto;
    margin-top: 25px;
    padding: 10px 50px;

    &-form {
        width: 100%;
    }

    &-wrapper {
        width: 360px;
        text-align: center;
        min-height: 80vh;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h1 {
            color: $white;
            font-size: 32px;
            margin-bottom: $spacing-large;
        }

        .change-password-btn {
            width: 100%;

            &-wrapper {
                margin-top: 24px;
            }
        }

        .logo {
            width: 100px;
            margin-top: 120px;
            margin-bottom: $spacing-largest;
        }
    }
}
