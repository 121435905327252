
@import "../../../sass/variables";

.user-list {
    margin-top: 28px;

    &-table {
        .ivu-table th {
        }

        .ivu-table td {
            vertical-align: top;
            padding-top: 16px;
            padding-bottom: 12px;

            .ivu-table {
                background-color: inherit;

                td.bordered-column > .ivu-table-cell {
                    background-color: $white;
                }

                td {
                    vertical-align: middle;
                    padding-top: 0;
                    padding-bottom: 0;
                    background-color: inherit;
                }

                .ivu-icon.ivu-select-arrow {
                    top: 12px;
                }
            }
        }

        .ivu-table .search-row td {
            padding-top: 6px;
            padding-bottom: 6px;
            height: 36px;
        }

        .ivu-select-single .ivu-select-selection {
            height: 34px;
        }

        .add-user-name {
            width: 300px;
            padding: 17px 0;
        }

        .new-user-row {
            td {
                background-color: $grey1;
            }
        }

        &-more {
            color: $blue;

            &:hover {
                background-color: inherit;
            }
        }

        &-confirm {
            margin-left: 10px;
        }

        &_empty-wrapper {
            .user-permission-modal-table-wrapper {
                pointer-events: none;
            }
        }

        &_action-btn {
            display: none;
        }

        .ivu-table-row:hover &_action-btn {
            display: block;
        }

        &__pages {
            float: right;
            margin-top: 16px;
        }
    }

    &-add {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }
}
