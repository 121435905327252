
@import "@/../sass/_variables.scss";

.set-campaign-section-modal {
    &__label {
        display: block;
        margin: 0 0 $spacing-small;
    }

    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__section-select-wrapper {
        margin: 0 0 $spacing;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;
    }
}
