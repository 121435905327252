
@import "../../sass/variables";

$nav-bg-color: $white;
$nav-border-color: $grey2;
$nav-border-color-selected: #50e3c2; //old
$nav-border-color-selected: $primary-color;
$nav-font-color: #515a6e;

.top-nav {
    border-bottom: 2px solid $nav-border-color;
    background-color: $nav-bg-color;
    display: flex;
    flex: 1 0 100%;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;

    .hox-menu {
        flex: 1 0 360px;
        z-index: 0;

        .ivu-menu-item {
            font-size: 16px;
            text-transform: none;
            color: $nav-font-color;

            &:hover,
            &-selected {
                color: $nav-font-color;
                border-bottom: 4px $nav-border-color-selected solid;
            }
        }
    }

    .client-logo {
        width: 130px;
        flex: 0 0 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        padding-right: 20px;
        position: relative;

        .logo-image {
            max-height: 45px;
            max-width: 70px;
            padding: 0;
            margin: 0;
        }

        .ivu-dropdown {
            width: 110px;

            &-item {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .dropdown-selection {
            flex: 0;
            padding-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;

            &__client-name {
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.top-nav__right {
    display: flex;
}
