
@import "../../../sass/variables";

.editables-controls {
    position: relative;
    top: 0;
    left: 0;
    height: 63px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: $cmp-dark-border-width solid $cmp-dark-border-color;

    &__search {
        background-color: transparent;
        border: none;

        &:hover {
            background-color: transparent;
            border: none;
        }
    }

    .campaign-edit__header-action-btn {
        margin-left: $spacing-small;
        margin-bottom: $spacing-small;
        font-size: 12px;

        > span {
            display: inline;
        }

        &.detach-btn {
            justify-self: flex-end;
        }
    }
}
