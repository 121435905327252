
@import "@/../sass/_variables.scss";

.table {
    border-collapse: collapse;
    position: relative;
    table-layout: fixed;
    width: 100%;

    &--is-scrollable {
        table-layout: auto;
    }
}

.table__container {
    height: 100%;
    position: relative;
    width: 100%;
}

.table__empty-message-wrapper {
    padding: $spacing 0 0 0;
}

.table__loading-layer {
    background: rgba($white, 0.7);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

.table__loading-spinner-wrapper {
    color: $cmp-light-font-color;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.table__wrapper {
    border: 1px solid $grey3;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    /*
        The transform is required to make Safari render the table correctly.

        Without it the sticky columns will be glitchy: they will appear to not
        be sticky until hovered over, at which point they will partially re-render.
    */
    transform: translate3d(0, 0, 0);
    width: 100%;

    &--no-border {
        border: none;
    }
}
