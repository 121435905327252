
@import "@/../sass/_variables.scss";

.qa-annotation-comments__comments {
    padding: 0 $spacing 0 0;
}

.qa-annotation-comments__no-comments-message {
    border: 1px dashed $grey3;
    color: $grey5;
    margin: 0 $spacing $spacing-small 0;
    padding: $spacing;

    > p {
        font-size: $font-size-small;
    }
}

.qa-annotation-comments__no-comments-wrapper {
    padding: $spacing-small 0 0;
}
