
@import "@/../sass/_variables.scss";

.master-template-text-filter {
    display: flex;
    margin-right: $spacing;
    justify-content: space-between;
    align-items: center;
    width: 155px;
    flex: 0 0 155px;
    margin-right: $spacing;

    &__label {
        white-space: nowrap;
        display: flex;
        margin-right: $spacing-smaller;
    }
}
