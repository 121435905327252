
@import "../../../sass/variables";
@import "~resize-css";

.campaign-banner {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    &--resizable {
        resize: both;
        background-color: $white;

        &.campaign-banner-dark-theme {
            background-color: $cmp-dark-secondary-bg-color;
        }

        .campaign-banner-footer {
            background-color: transparent;
        }
    }

    &__preview {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__footer {
        flex: 0 0 40px;
    }
}

.banner-name {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
