
@import "@/../sass/_variables.scss";

.sections-select {
    margin: 0 0 $spacing;
}

.sections-select__label {
    display: block;
    margin: 0 0 $spacing-small;
}
