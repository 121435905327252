
@import "../../../../sass/_variables.scss";

.search-preview {
    flex: 1 1 24%;
    margin: auto 0;
    max-height: 100%;
    &__images,
    &__audio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__audio {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        border: 1px solid #e8eaec;
        border-radius: 4px;
        height: 100%;

        &-icon {
            width: 100%;
            aspect-ratio: 1 / 1;
            height: calc(100% - 54px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-controls {
            width: calc(100% - 20px);
            display: flex;
            justify-content: flex-start;
            position: relative;
            bottom: 5px;
            left: 10px;

            &-el {
                width: 100%;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-small;
    }
    &__add-library {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        width: 100%;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
    }

    &--loading {
        .search-preview__image {
            border: 1px solid #e8eaec;
            border-radius: 4px;
        }
    }

    &:hover {
        .imagine-carousel-arrow {
            visibility: visible;
        }
    }
}
.search-preview__image {
    flex: 1 1 24%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 500px;
    }

    &:hover .search-preview__image-hover {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        a,
        button {
            @media (max-width: 1200px) {
                font-size: 1vw;
            }
            @media (max-width: 800px) {
                font-size: 10px;
            }
        }
    }
}

.search-preview__image-hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff66;
    width: 100%;
    height: 100%;
}
