
@import "@/../sass/_variables.scss";

.notifications-drawer {
    .ivu-drawer-mask,
    .ivu-drawer-wrap {
        z-index: $zindex-notifications-drawer;
    }

    .ivu-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100%;
    }

    .ivu-drawer-content {
        box-sizing: content-box;
        border-left: $campaign-vertical-nav-width solid $cmp-light-bg-color;
    }
}

.notifications-drawer__content {
    flex: 1;
    overflow: auto;
}

.notifications-drawer__empty-message-wrapper {
    padding: $spacing-large;
}

.notifications-drawer__footer {
    background: $white;
    cursor: pointer;
    flex: 0;
    font-size: $font-size-base;
    font-weight: bold;
    transition: background 0.2s ease-in-out;
    text-align: center;
    margin: 10px;
    border: 1px solid var(--wpp-primary-color-500);
    padding: 10px;
    border-radius: 10px;
    text-transform: none;
    color: var(--wpp-primary-color-500);

    &:hover {
        background: $grey2;
    }
}

.notifications-drawer__header {
    border-bottom: $border-width-base solid $cmp-light-border-color;
    padding: $spacing-large 0 0 0;

    &-title {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        padding: 0 15px 5px;
    }
    &-desc {
        color: #4d5358;
        font-size: 14px;
        line-height: 22px;
        padding: 0 15px 20px;
    }
}
