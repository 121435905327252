
@import "@/../sass/_variables.scss";

.labelled-switch {
    align-items: center;
    display: inline-flex;
    flex: 1 0;
}

.labelled-switch--success {
    align-items: center;
    display: inline-flex;
    flex: 1 0;

    .ivu-switch-checked {
        background: $success-color;
        border-color: $success-color;

        &:focus {
            box-shadow: 0 0 0 2px rgba($success-color, 0.2);
        }
    }
}

.labelled-switch--info {
    align-items: center;
    display: inline-flex;
    flex: 1 0;

    .ivu-switch-checked {
        background: $info-color;
        border-color: $info-color;

        &:focus {
            box-shadow: 0 0 0 2px rgba($info-color, 0.2);
        }
    }
}

.labelled-switch__label {
    cursor: pointer;
    padding: 0 $spacing-smaller 0 0;
    transition: color 0.2s linear;
}

.labelled-switch__label--off {
    color: $grey5;
}
