@time-picker-prefix-cls: ~"@{css-prefix}time-picker";
@time-picker-cells-width-base: 56px;
@time-picker-cells-width-with-date-base: 72px;
@time-picker-cells-width: @time-picker-cells-width-base * 2;
@time-picker-cells-width-with-seconds: @time-picker-cells-width-base *3;
@time-picker-cells-width-with-date: @time-picker-cells-width-with-date-base * 2;
@time-picker-cells-width-with-date-with-seconds: @time-picker-cells-width-with-date-base * 3;

.@{time-picker-prefix-cls} {
    &-cells{
        min-width: @time-picker-cells-width;
        &-with-seconds{
            min-width: @time-picker-cells-width-with-seconds;
        }

        &-list{
            width: @time-picker-cells-width-base;
            max-height: 144px;
            float: left;
            overflow: hidden;
            border-left: 1px solid @border-color-split;
            position: relative;
            &:hover{
                overflow-y: auto;
            }

            &:first-child{
                border-left: none;
                border-radius: @btn-border-radius 0 0 @btn-border-radius;
            }
            &:last-child{
                border-radius: 0 @btn-border-radius @btn-border-radius 0;
            }
            ul{
                width: 100%;
                margin: 0;
                padding: 0 0 120px 0;
                list-style: none;
                li{
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    margin: 0;
                    padding: 0 0 0 16px;
                    box-sizing: content-box;
                    text-align: left;
                    user-select: none;
                    cursor: pointer;
                    list-style: none;
                    transition: background @transition-time @ease-in-out;

                }
            }
        }
        &-cell{
            &:hover{
                background: @background-color-select-hover;
            }
            &-disabled {
                color: @btn-disable-color;
                cursor: @cursor-disabled;

                &:hover {
                    color: @btn-disable-color;
                    background-color: #fff;
                    cursor: @cursor-disabled;
                }
            }
            &-selected ,&-selected:hover{
                color: @primary-color;
                background: @background-color-select-hover;
            }
            &-focused{
                background-color: tint(@primary-color, 80%);
            }
        }
    }

    &-header{
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-bottom: 1px solid @border-color-split;
    }

    &-with-range{
        .@{picker-prefix-cls}-panel{
            &-body{
                min-width: @time-picker-cells-width * 2 + 4px;
            }
            &-content{
                float: left;
                position: relative;

                &:after{
                    content: '';
                    display: block;
                    width: 2px;
                    position: absolute;
                    top: 31px;
                    bottom: 0;
                    right: -2px;
                    background: @border-color-split;
                    z-index: 1;
                }

                &-right{
                    float: right;
                    &:after{
                        right: auto;
                        left: -2px;
                    }
                }
            }
        }
        .@{time-picker-prefix-cls}-cells{
            &-list{
                &:first-child{
                    border-radius: 0;
                }
                &:last-child{
                    border-radius: 0;
                }
            }
        }
    }
    &-with-range&-with-seconds{
        .@{picker-prefix-cls}-panel{
            &-body{
                min-width: @time-picker-cells-width-with-seconds * 2 + 4px;
            }
        }
    }
}

.@{picker-prefix-cls}-panel-content{
    .@{picker-prefix-cls}-panel-content{
        .@{time-picker-prefix-cls}{
            &-cells{
                min-width: @time-picker-cells-width-with-date-with-seconds;
                &-with-seconds{
                    min-width: @time-picker-cells-width-with-date-with-seconds;
                    .@{time-picker-prefix-cls}-cells-list{
                        width: @time-picker-cells-width-with-date-with-seconds / 3;
                        ul{
                            li{
                                padding: 0 0 0 28px;
                            }
                        }
                    }
                }
                &-list {
                    width: @time-picker-cells-width-with-date-with-seconds / 2;
                    max-height: 216px;
                    &:first-child{
                        border-radius: 0;
                    }
                    &:last-child{
                        border-radius: 0;
                    }
                    ul{
                        padding: 0 0 192px 0;
                        li{
                            padding: 0 0 0 46px;
                        }
                    }
                }
            }
        }
    }
}
