
@import "@/../sass/_variables.scss";
.resource-group {
    &-input__enriched-resources {
        margin: $spacing-small;
    }

    &-input__enriched-resources-btn {
        margin-right: $spacing-small;
    }

    &-textarea {
        width: 100%;
    }
}
