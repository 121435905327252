
@import "@/../sass/_variables.scss";

.library-deliverable-table-metadata-cell {
    position: relative;
    max-width: 100%;

    &:hover {
        .library-deliverable-table-metadata-cell__edit-button-wrapper {
            opacity: 1;
        }
    }
}

.library-deliverable-table-metadata-cell__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    transition:
        filter 0.2s,
        opacity 0.2s;

    &--faded {
        filter: blur(2px);
        opacity: 0.5;
    }
}

.library-deliverable-table-metadata-cell__form {
    flex: 1;
    margin: 0 $spacing-smaller 0 0;
}

.library-deliverable-table-metadata-cell__edit-button-wrapper {
    opacity: 0;
    transition: opacity 0.2s;
}

.library-deliverable-table-metadata-cell__input-container {
    align-items: center;
    background: $white;
    box-shadow: $box-shadow-base;
    display: flex;
    left: 100%;
    min-width: 240px;
    padding: $spacing-small;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    width: 100%;
    z-index: 4;

    .ivu-input {
        height: 28px;
    }
}

.library-deliverable-table-metadata-cell__value {
    cursor: text;
    flex: 1;
    margin: 0 $spacing-small 0 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &--muted {
        color: $grey5;
    }
}
