
@import "../../../../sass/_variables.scss";

$selected-bg: #f5f7f9;
$border-color: #ced4d9;
$selected-border-color: #0f8ee9;
$border-style: 1px $border-color solid;
$box-shadow:
    inset 1px 1px 0 $border-color,
    inset -1px 0px 0 $border-color;
$box-shadow-td:
    inset 0px 1px 0 $border-color,
    inset 1px 0px 0 $border-color;
$box-shadow-selected:
    inset 0px 0px 0 transparent,
    inset 0px 0px 0 $border-color;

.kpi-matrix {
    margin: $spacing 0;
    max-width: calc(100vw - 396px);
    overflow: auto;
    position: relative;

    &--has-data {
        overflow: scroll hidden;
    }

    &__sticky {
        position: sticky;
        left: 0;
        background-color: #f5f7f9;
        border: 0 none;

        &--first {
            left: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            &:after {
                content: "";
                position: absolute;
                left: -1px;
                bottom: 0;
                height: 100%;
                border-left: 1px solid #f5f7f9;
            }
        }

        &--second,
        &--second.kpi-matrix__sticky--third {
            left: 137px;
        }

        &--third {
            left: 274px;
        }
    }

    &__table {
        width: 100%;
        border-spacing: 0;
        margin: 0 0 $spacing 0;
        border: none;
        border-collapse: collapse;
        table-layout: fixed;

        td,
        th {
            padding: $spacing-smaller $spacing;
            text-align: center;
            height: 64px;
            width: 137px;

            &.selected {
                color: $selected-border-color;
            }
        }
        thead {
            th {
                height: 44px;
                font-weight: normal;
                font-size: 12px;

                .kpi-matrix__header-item-clip {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                &.kpi-matrix__platform-select {
                    text-align: left;
                }

                &.selected {
                    box-shadow:
                        inset 0px -2px 0 #0f8ee9,
                        inset 0px 0px 0 #0f8ee9;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 2px;
                        border-left: 2px solid $selected-border-color;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: -1px;
                        bottom: 0;
                        height: 2px;
                        border-right: 2px solid $selected-border-color;
                    }
                }
            }
        }

        tbody {
            background-color: $white;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

            td {
                box-shadow: $box-shadow-td;
                background-color: $white;

                &.selected {
                    background-color: #fafbfc;
                }

                &.kpi-matrix__sticky {
                    border: 0 none !important;

                    &--first {
                        box-shadow: $box-shadow;
                    }

                    &--second {
                        &:after {
                            content: "";
                            position: absolute;
                            right: -1px;
                            bottom: 0;
                            height: 100%;
                            border-right: $border-style;
                        }
                    }

                    &.selected {
                        box-shadow: none;
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0px;
                            bottom: 0;
                            height: 100%;
                            border-left: $border-style;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            right: -1px;
                            bottom: 0;
                            height: 100%;
                            border-right: $border-style;
                        }
                    }

                    &.selected {
                        box-shadow: $box-shadow-selected;

                        &.kpi-matrix__sticky--bottom {
                            box-shadow:
                                inset 0 0 0 $selected-bg,
                                inset 0 0 0 $border-color;
                        }
                    }
                }
            }
        }

        .metric-name {
            font-weight: bold;
            background-color: #fafbfc;
        }
    }
}
