
@import "@/../sass/_variables.scss";

.account-rule {
    &__form {
        width: 100%;
        padding-right: 0;
        .ivu-form-item {
            margin-bottom: 10px;
            &.ivu-form-item-error {
                margin-bottom: 20px;
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
    }
    &__wrap {
        display: flex;
    }

    &__info {
        padding-left: 15px;
        display: flex;
        align-items: center;
    }
}
