
@import "../../../sass/_variables.scss";

.search-results {
    margin-bottom: $spacing;
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-bottom: $spacing;
        line-height: 1;
    }

    &__model {
        font-weight: normal;
        margin-right: $spacing;
    }

    &__duration {
        margin-right: $spacing;
        font-weight: normal;
        font-size: 12px;
        white-space: nowrap;
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: $spacing-larger;
    }

    &-previews {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        width: 100%;
        flex-wrap: wrap;
    }

    &-preview {
        flex: 1 1 22%;
        margin: 5px;
        position: relative;
        background: #ffffff;
    }

    &__load-more {
        font-size: 14px;
        margin: 0 $spacing 0 auto;
    }

    &__view-change {
        font-weight: normal;
        padding: 0 $spacing;
        margin-right: auto;
        cursor: pointer;

        span {
            font-weight: bold;
        }
    }
}
