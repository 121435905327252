
.editable-group {
    &__add {
        width: 100%;
    }

    &__values {
        width: 100%;
    }

    .show-form {
        &--showing {
            display: none;
        }
    }
}
