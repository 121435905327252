
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

$error-and-hint-content-spacing: $spacing-smaller;

.hox-input {
    display: block;
    width: 100%;

    &--margin-bottom-base {
        margin-bottom: $spacing;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--margin-bottom-large {
        margin-bottom: $spacing-large;
    }

    &--margin-bottom-none {
        margin-bottom: 0;
    }
}

.hox-input__error-and-hint-wrapper {
    margin: 0 0 (0 - $error-and-hint-content-spacing);

    @include clearfix();
}

.hox-input__error-container,
.hox-input__hint-container {
    color: $error-color;
    font-size: $font-size-small;
    line-height: $line-height-base;

    > p {
        margin: 0 0 $error-and-hint-content-spacing;
    }
}

.hox-input__hint-container {
    color: $cmp-light-font-color;
}

.hox-input__input {
    background: $white;
    border: 1px solid $cmp-light-border-color;
    color: $cmp-light-secondary-font-color;
    font-size: $font-size-small;
    margin: 0;
    padding: 0 $spacing-small;
    position: relative;
    transition:
        border-color $transition-time-base,
        box-shadow $transition-time-base;
    transition-timing-function: $transition-function-base;
    width: 100%;
    z-index: 1;

    &:hover {
        border-color: $primary-color;
    }

    &:focus {
        border-color: $primary-color;
        outline: none;
        @include box-shadow-solid($color: $primary-color);
    }

    &::placeholder {
        color: $cmp-light-tertiary-font-color;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--error {
        border-color: $error-color;

        &:hover {
            border-color: $error-color;
        }

        &:focus {
            border-color: $error-color;
            outline: none;
            @include box-shadow-solid($color: $error-color);
        }
    }

    &--size-base {
        height: $input-height-base;
    }

    &--size-large {
        font-size: $font-size-base;
        height: $input-height-large;
    }

    &--size-small {
        height: $input-height-small;
    }
}

.hox-input__label {
    color: $cmp-light-secondary-font-color;
    display: inline-block;
    font-size: $font-size-base;
    margin: 0 0 $spacing-small;
}

.hox-input__required-indicator {
    color: $error-color;
}
