
@import "../sass/variables";

.footer-nav {
    width: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    svg.logo {
        height: 26px;
        width: 20px;
        margin-bottom: $spacing;
        margin-top: 68px;
    }

    &--dark {
        svg.logo {
            path {
                fill: $hogarth-H-light-fill;
            }
        }
    }
}
