@drawer-prefix-cls: ~"@{css-prefix}drawer";

.@{drawer-prefix-cls} {
    width: auto;
    height: 100%;
    position: fixed;
    top: 0;

    &-inner{
        position: absolute;
    }

    &-left{
        left: 0;
    }
    &-right{
        right: 0;
    }

    &-hidden {
        display: none !important;
    }

    &-wrap {
        position: fixed;
        overflow: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: @zindex-drawer;
        -webkit-overflow-scrolling: touch;
        outline: 0;

        &-inner{
            position: absolute;
            overflow: hidden;
        }

        &-dragging{
            user-select: none;
        }
    }

    &-wrap * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &-mask {
        .mask;
        &-inner{
            position: absolute;
        }
    }

    &-content {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: #fff;
        border: 0;
        background-clip: padding-box;
        box-shadow: 0 4px 12px rgba(0,0,0,.15);

        &-no-mask{
            pointer-events: auto;
        }
    }

    &-header {
        .content-header;
    }

    &-close {
        z-index: 1;
        .content-close(1px, 31px);
    }

    &-body {
        width: 100%;
        height: calc(~'100% - 51px');
        padding: 16px;
        font-size: 12px;
        line-height: 1.5;
        word-wrap: break-word;
        position: absolute;
        overflow: auto;
    }

    &-no-header &-body{
        height: 100%;
    }

    &-no-mask{
        pointer-events: none;

        .@{drawer-prefix-cls}-drag{
            pointer-events: auto;
        }
    }

    &-drag{
        top: 0;
        height: 100%;
        width: 0;
        position: absolute;
        &-left{
            right: 0;
        }
        &-move-trigger{
            width: 8px;
            height: 100px;
            line-height: 100px;
            position: absolute;
            top: 50%;
            background: rgb(243, 243, 243);
            transform: translate(-50%, -50%);
            border-radius: ~"4px / 6px";
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, .2);
            cursor: col-resize;
            &-point{
                display: inline-block;
                width: 50%;
                transform: translateX(50%);
                i{
                    display: block;
                    border-bottom: 1px solid rgb(192, 192, 192);
                    padding-bottom: 2px;
                }
            }
        }
    }
}