@font-face {
    font-family: "Open Sans";
    src: url(/fonts/Open-Sans/OpenSans-Regular.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    src: url(/fonts/Open-Sans/OpenSans-Italic.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 300;
    src: url(/fonts/Open-Sans/OpenSans-Light.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 300;
    src: url(/fonts/Open-Sans/OpenSans-LightItalic.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 600;
    src: url(/fonts/Open-Sans/OpenSans-SemiBold.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    src: url(/fonts/Open-Sans/OpenSans-SemiBoldItalic.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 700;
    src: url(/fonts/Open-Sans/OpenSans-Bold.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 700;
    src: url(/fonts/Open-Sans/OpenSans-BoldItalic.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 800;
    src: url(/fonts/Open-Sans/OpenSans-ExtraBold.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 800;
    src: url(/fonts/Open-Sans/OpenSans-ExtraBoldItalic.ttf);
}

@font-face {
    font-family: "Silka";
    src: url(/fonts/Silka/silka-bolditalic-webfont.woff) format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Silka";
    src: url(/fonts/Silka/silka-regularitalic-webfont.woff) format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Silka";
    src: url(/fonts/Silka/silka-semibolditalic-webfont.woff) format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Silka";
    src: url(/fonts/Silka/silka-bold-webfont.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Silka";
    src: url(/fonts/Silka/silka-regular-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Silka";
    src: url(/fonts/Silka/silka-semibold-webfont.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

/* We are not using these brand book fonts
@font-face {
    font-family: "Silka Black";
    src: url(/fonts/Silka/silka-black-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Silka Black";
    src: url(/fonts/Silka/silka-blackitalic-webfont.woff) format("woff");
    font-weight: normal;
    font-style: italic;
}
*/

/*
@font-face {
    font-family: "Silka Extra Light";
    src: url(/fonts/Silka/silka-extralight-webfont.woff) format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Silka Extra Light";
    src: url(/fonts/Silka/silka-extralightitalic-webfont.woff) format("woff");
    font-weight: normal;
    font-style: italic;
}
*/

/*

@font-face {
    font-family: "Silka Light";
    src: url(/fonts/Silka/silka-lightitalic-webfont.woff) format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Silka Light";
    src: url(/fonts/Silka/silka-light-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}
*/

/*
@font-face {
    font-family: "Silka Medium";
    src: url(/fonts/Silka/silka-medium-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Silka Medium";
    src: url(/fonts/Silka/silka-mediumitalic-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}
*/

/*
@font-face {
    font-family: "Silka Thin";
    src: url(/fonts/Silka/silka-thinitalic-webfont.woff) format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Silka Thin";
    src: url(/fonts/Silka/silka-thin-webfont.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}
*/
