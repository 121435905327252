
@import "../../sass/_variables.scss";

.fonts {
    margin-top: 70px;
    &__action {
        &-items {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0;
        }

        &-item {
            flex: 0 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }

    &__list--no-action-items {
        padding: 15px 0 0 0;
    }

    &__section-divider {
        border: none;
        border-top: $border-width-base solid $cmp-light-border-color;
        flex: 1;
    }

    &__section-empty-message {
        color: $cmp-light-font-color;
        font-size: $font-size-base;
        margin: (0 - $spacing-small) 0 $spacing;

        p {
            margin: 0 0 $spacing-small;

            &:first-child {
                font-weight: bold;
            }
        }
    }

    &__section-header {
        align-items: center;
        display: flex;
        margin: 0 0 $spacing;
        padding: $spacing 0 0;
    }

    &__section-title {
        font-size: $font-size-large;
        font-weight: bold;
        padding: 0 $spacing-small 0 0;
    }

    .fonts__action-items {
        align-items: center;
        .data-filter__select {
            line-height: 30px;
        }
        button {
            &.fonts__action-item {
                padding: 6px 20px;
                height: 30px;
                line-height: 32px;
            }
        }
    }
}

.fonts-dashboard__empty-message-wrapper {
    padding: $spacing-large 0 0 0;

    button {
        margin: $spacing-small 0;
    }
}

.fonts-dashboard__loading-wrapper {
    height: 120px;
    position: relative;
}
