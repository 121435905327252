
@import "@/../sass/_variables.scss";

.qa-banner-annotations__annotation-dot-container {
    cursor: pointer;
    opacity: 0.8;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s linear;
    z-index: 1;

    &:hover {
        opacity: 1;
        z-index: 2;
    }
}

.qa-banner-annotations__annotation-dot-container--selected {
    opacity: 1;
    z-index: 1;
}
