@list-prefix-cls: ~"@{css-prefix}list";

.@{list-prefix-cls} {
    position: relative;

    &-items {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &-item{
        display: flex;
        align-items: center;
        padding: @list-item-padding;

        &-content {
            color: @text-color;
        }

        &-meta{
            display: flex;
            flex: 1;
            align-items: flex-start;
            font-size: 0;

            &-avatar {
                margin-right: @list-item-meta-avatar-margin-right;
            }
            &-content {
                flex: 1 0;
            }
            &-title {
                font-weight: bold;
                margin-bottom: 4px;
                color: @text-color;
                font-size: @font-size-base;
                line-height: 22px;
                > a {
                    color: @text-color;
                    transition: all @transition-time;
                    &:hover {
                        color: @primary-color;
                    }
                }
            }
            &-description {
                color: @text-color-secondary;
                font-size: @font-size-base;
                line-height: 22px;
            }
        }

        &-action {
            flex: 0 0 auto;
            margin-left: 48px;
            padding: 0;
            font-size: 0;
            list-style: none;
            & > li {
                position: relative;
                display: inline-block;
                padding: 0 8px;
                color: @text-color-secondary;
                font-size: @font-size-base;
                line-height: 22px;
                text-align: center;
                cursor: pointer;

                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: 14px;
                    margin-top: -7px;
                    background-color: @border-color-split;
                }
            }
            & > li:first-child {
                padding-left: 0;
            }
            & > li:last-child {
                &:after{
                    display: none;
                }
            }
            &-split {

            }
        }
    }

    &-header {
        background: @list-header-background;
    }

    &-footer {
        background: @list-footer-background;
    }

    &-header,
    &-footer {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &-split &-item {
        border-bottom: 1px solid @border-color-split;
        &:last-child {
            border-bottom: none;
        }
    }

    &-split &-header {
        border-bottom: 1px solid @border-color-split;
    }
    &-split &-footer{
        border-top: 1px solid @border-color-split;
    }

    &-large &-item {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &-small &-item {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &-vertical &-item {
        align-items: initial;

        &-main {
            display: block;
            flex: 1;
        }

        &-extra {
            margin-left: 40px;
        }

        &-meta {
            margin-bottom: @list-item-meta-margin-bottom;

            &-title {
                margin-bottom: @list-item-meta-title-margin-bottom;
                color: @heading-color;
                font-size: @font-size-large;
                line-height: 24px;
            }
        }

        &-action {
            margin-top: @padding-md;
            margin-left: auto;

            > li {
                padding: 0 16px;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    // without flex
    &-item-no-flex {
        display: block;
    }

    // Horizontal
    &:not(.@{list-prefix-cls}-vertical) {
        .@{list-prefix-cls}-item-no-flex {
            .@{list-prefix-cls}-item-action {
                float: right;
            }
        }
    }
}

.@{list-prefix-cls}-bordered {
    border: 1px solid @border-color-base;
    border-radius: @border-radius-base;
    .@{list-prefix-cls}-header {
        padding-right: 24px;
        padding-left: 24px;
    }

    .@{list-prefix-cls}-footer {
        padding-right: 24px;
        padding-left: 24px;
    }

    .@{list-prefix-cls}-item {
        padding-right: 24px;
        padding-left: 24px;
        border-bottom: 1px solid @border-color-split;
    }

    .@{list-prefix-cls}-pagination {
        margin: 16px 24px;
    }

    &.@{list-prefix-cls}-small {
        .@{list-prefix-cls}-item {
            padding-right: 16px;
            padding-left: 16px;
        }
        .@{list-prefix-cls}-header,
        .@{list-prefix-cls}-footer {
            padding: 8px 16px;
        }
    }

    &.@{list-prefix-cls}-large {
        .@{list-prefix-cls}-header,
        .@{list-prefix-cls}-footer {
            padding: 16px 24px;
        }
    }
}

// responsive

@media screen and (max-width: @screen-md) {
    .@{list-prefix-cls} {
        &-item {
            &-action {
                margin-left: 24px;
            }
        }
    }

    .@{list-prefix-cls}-vertical {
        .@{list-prefix-cls}-item {
            &-extra {
                margin-left: 24px;
            }
        }
    }
}

@media screen and (max-width: @screen-sm) {
    .@{list-prefix-cls} {
        &-item {
            flex-wrap: wrap;
            &-action {
                margin-left: 12px;
            }
        }
    }

    .@{list-prefix-cls}-vertical {
        .@{list-prefix-cls}-item {
            flex-wrap: wrap-reverse;
            &-main {
                min-width: 220px;
            }
            &-extra {
                margin: auto auto 16px;
            }
        }
    }
}
