
@import "../../../sass/variables";

.grid-view {
    background-color: $cmp-dark-secondary-sidebar-color;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex: 1 0 100%;

    .editable-image-thumbnail {
        display: flex;
        margin: 5px 1px;
    }
}
