
.meta-nf-post {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccd0d5;
    border-bottom: 1px solid #ccd0d5;
    margin-bottom: 20px;

    &__header {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        .meta-nf-post__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            i {
                width: 100%;
                height: 100%;
                font-size: 42px;
            }
        }

        .meta-nf-post__user-info {
            flex-grow: 1;

            .meta-nf-post__user-name {
                font-weight: bold;
                font-size: 14px;
            }

            .meta-nf-post__sponsored {
                font-weight: normal;
                font-size: 12px;
                color: #606770;
                i {
                    font-size: 14px;
                }
            }

            .meta-nf-post__time {
                color: #606770;
                font-size: 12px;
            }
        }

        .meta-nf-post__options {
            font-size: 24px;
            color: #606770;
            cursor: pointer;
        }
    }

    &__content {
        padding: 0 0 10px;

        .meta-nf-post__text {
            font-size: 16px;
            padding: 0 16px;
            margin-bottom: 15px;
            color: #1c1e21;
        }

        .meta-nf-post__image {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__description {
        display: flex;
        padding: 10px 16px;
        background: #f7f8fa;
        border-bottom: 1px solid #e4e5e6;
        color: #000;
        &-text {
            padding-right: 10px;
            a {
                display: inline-block;
                color: #000 !important;
            }
        }
        &-btn {
            background: #e3e5e9;
            color: #000;
            margin: auto 0 auto auto;
            flex: 0 0 auto;
            border-radius: 4px;
            font-weight: 600;
            border: 0;
            padding: 5px 15px;
        }
    }
    &__footer {
        padding: 0 16px 12px 16px;

        .meta-nf-post__divider {
            height: 1px;
            background-color: #dddfe2;
            margin: 8px 0;
        }

        .meta-nf-post__actions {
            display: flex;
            justify-content: space-around;
            color: #606770;
            font-size: 14px;

            .meta-nf-post__action {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 8px;
                flex-grow: 1;
                justify-content: center;

                &:hover {
                    background-color: #f0f2f5;
                    border-radius: 4px;
                }

                span {
                    display: flex;
                    color: #000;
                    align-items: center;
                }

                .meta-nf-post__action-icon {
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &--video {
        background: #373737;
        color: #fff;
        .meta-nf-post__header {
            border: 0;
        }
        .meta-nf-post__content {
            padding-top: 0;
        }
        .meta-nf-post__description {
            flex-direction: column;
            border-bottom: 0;
        }
        .meta-nf-post__description-btn {
            background: #141415;
            width: 100%;
            color: #fff;
            margin: 10px 0;
        }
        .meta-nf-post__description {
            color: #fff;
            background: #333;
        }
        .meta-nf-post__header {
            .meta-nf-post__options {
                color: #fff;
            }
        }
        .meta-nf-post__header {
            .meta-nf-post__user-info {
                .meta-nf-post__sponsored {
                    color: #fff;
                }
            }
        }
        .meta-nf-post__footer {
            .meta-nf-post__actions {
                span {
                    color: #fff;
                }
                .meta-nf-post__action {
                    color: #fff;
                    padding: 0 8px;
                }
            }
        }
    }
    &--tab {
        .meta-nf-post__description-btn,
        .meta-nf-post__description {
            background: #e9eaee;
        }

        .meta-nf-post__description-btn {
            color: #000;
            border: 1px solid #000;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 12px;
        }
    }

    &--desktop {
        .post_media {
            position: relative;
        }
        .meta-nf-post__content {
            padding-bottom: 0;
        }
        .meta-nf-post__divider {
            background: none;
            margin-top: 0;
        }
        .meta-nf-post__overlay {
            background: rgba(0, 0, 0, 0.75);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            &.hidden {
                display: none;
            }
        }
        .meta-nf-post__desktop {
            &-info {
                display: flex;
                > div {
                    display: flex;
                    flex-direction: column;
                    span {
                        font-weight: 600;
                    }
                    a {
                        color: #fff;
                    }
                }
            }
            &-play {
                padding: 5px;
                border-radius: 50%;
                border: 2px solid #fff;
                margin-right: 10px;
                i {
                    font-size: 26px;
                    rotate: -30deg;
                }
            }
            &-refresh {
                position: absolute;
                bottom: 10px;
                left: 10px;
                font-size: 28px;
                font-weight: 600;
                cursor: pointer;
            }
        }
        .meta-nf-post__description-btn {
            color: #fff;
            background: #0766ff;
        }
    }
}
