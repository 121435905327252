
@import "@/../sass/_mixins.scss";
@import "../../../sass/variables";

$preview-width: 100px;

.editable-video-thumbnail {
    @include make-checked-background(#808080, 5px);
    display: flex;
    flex: 0 0 $preview-width;
    width: $preview-width;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $cmp-dark-bg-color;
    border-radius: 0 0 2px 2px;
    position: relative;

    &__label {
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 2;
    }

    &__video {
        width: 100%;
        height: 82px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__thumbnail {
        cursor: pointer;
    }

    &__icon .ivu-icon {
        font-size: 84px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 31px;
        line-height: 31px;
        overflow: hidden;
        padding: 0 5px;
        background-color: $cmp-dark-secondary-sidebar-color;

        &--icon {
            display: none;
            cursor: pointer;
        }

        &--name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        .editable-video-thumbnail__footer--icon {
            display: flex;
        }
    }

    &--locked:hover {
        .editable-video-thumbnail__footer--icon {
            display: none;
        }

        .editable-video-thumbnail__video {
            cursor: default;
        }
    }
}
