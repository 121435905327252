
@import "../../../sass/_variables.scss";

.banner__loading-spinner-wrapper {
    color: $cmp-light-font-color;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.psd-preview {
    width: 100%;
}

.banner-component-wrapper--resizable .bannerWrapper {
    overflow: hidden;
    position: relative;

    .banner-iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.bannerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-iframe {
        margin: 0;
        padding: 0;
        background-color: $banner-preview-bg-color;
    }
}
