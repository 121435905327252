
@import "@/../sass/_variables.scss";

$status-dot-size: 10px;

.qa-status-dot {
    display: inline-block;
    border-radius: 100%;
    height: $status-dot-size;
    width: $status-dot-size;
}

.qa-status-dot--approved {
    background: $success-color;
}

.qa-status-dot--in-review {
    background: $warning-color;
}

.qa-status-dot--rejected {
    background: $error-color;
}

.qa-status-dot--draft {
    background: $white;
    border: 1px solid $grey5;
}
