
@import "@/../sass/_variables.scss";

.library-deliverable-table-checkbox-cell {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background 0.2s linear;

    &:hover {
        background: $grey1;
    }

    .ivu-checkbox-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
