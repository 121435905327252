
@import "../../../sass/variables";

.manage-users-modal {
    &-alert {
        .alert__icon-container {
            padding-bottom: 0;
        }
    }

    &__tab-content {
        width: 100%;
    }

    &__notification-tab {
        display: flex;
    }

    &__add-client-wrapper {
        float: right;
        width: 100%;
    }
}

.notification-label {
    width: 650px;
    color: $darktheme20;
    padding-right: $spacing-large;

    &--shared {
        padding-top: $spacing-smaller;
    }
}

.labelled-switch {
    padding: $spacing-small 0;
}

.notification-settings-group {
    margin-top: $spacing-large;
    padding-bottom: $spacing-large;
    border-bottom: 1px solid $grey3;
}

.notification-radio-group {
    padding-top: $spacing-small;
}

.notification-settings {
    padding-top: $spacing-large;
    margin: 0 $spacing-large;
    width: 750px;
}

.notification-email-digest {
    margin-top: 20px;
}
