.@{row-prefix-cls} {
    .make-row();
    display: block;

    &-flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &:before,
        &:after {
            display: flex;
        }
        // x轴原点
        &-start {
            justify-content: flex-start;
        }
        // x轴居中
        &-center {
            justify-content: center;
        }
        // x轴反方向
        &-end {
            justify-content: flex-end;
        }
        // x轴平分
        &-space-between {
            justify-content: space-between;
        }
        // x轴有间隔地平分
        &-space-around {
            justify-content: space-around;
        }
        // 顶部对齐
        &-top {
            align-items: flex-start;
        }
        // 居中对齐
        &-middle {
            align-items: center;
        }
        // 底部对齐
        &-bottom {
            align-items: flex-end;
        }
    };
}

.@{col-prefix-cls} {
    position: relative;
    display: block;
}

.make-grid();

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(-xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
    .make-grid(-sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
    .make-grid(-md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the wide desktop device range.

@media (min-width: @screen-lg-min) {
    .make-grid(-lg);
}


// Extra large grid
//
// Columns, offsets, pushes, and pulls for the full hd desktop device range.

@media (min-width: @screen-xl-min) {
    .make-grid(-xl);
}


// Extra extra large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-xxl-min) {
    .make-grid(-xxl);
}
