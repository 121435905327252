
@import "@/../sass/_variables.scss";

$new-annotation-dot-size: 30px;

.qa-banner-add-annotation__new-annotation-dot {
    align-items: flex-end;
    background-color: rgba(127, 127, 127, 0.75);
    border-radius: 100%;
    border: 1px dashed $white;
    cursor: default;
    display: flex;
    height: $new-annotation-dot-size;
    margin: (0 - calc($new-annotation-dot-size / 2)) 0 0 (0 - calc($new-annotation-dot-size / 2));
    padding: 0 calc($new-annotation-dot-size / 2) 0 0;
    position: relative;
    width: $new-annotation-dot-size;
}
