
@import "@/../sass/_variables.scss";

.insight-preview {
    width: calc(100vw - 410px);

    &--collapsed {
        width: calc(100vw - 150px);
    }

    &-body {
        min-height: 100px;
        margin: 0 0 $spacing 0;
        width: 100%;
    }

    &.ivu-collapse {
        border: none;
        border-radius: 0;

        .ivu-collapse-content > .ivu-collapse-content-box {
            padding: 6px 0;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }

    &__panel {
        .ivu-collapse-header {
            height: 100px !important;
            display: flex;
            width: 100%;
            flex: 1 0 100%;
            justify-content: flex-start;
            align-items: flex-start;

            & > .ivu-icon {
                display: flex;
                margin-top: $spacing-larger;
            }
        }

        &-name {
            flex: 1 0;
            overflow: hidden;

            h4 {
                padding-top: 28px;
                color: $ci-font-color-secondary;
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
            }

            h2 {
                line-height: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &--inner {
            }
        }

        &-controls {
            display: flex;
            flex: 0 0;
            align-items: center;
            align-self: flex-end;
            margin: 12px 5px;
        }
    }

    &__body {
        padding: 0 $spacing-large;
    }

    span.tagname {
        color: $ci-font-color;
    }
}
