
.creative-intelligence-settings-button {
    &__header {
        font-size: 10px;
        line-height: 12px;
    }

    &__value {
        white-space: nowrap;
        line-height: 18px;
    }
}
