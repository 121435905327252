
@import "@/../sass/_variables.scss";

.master-template-library {
    &__filter-tags {
        flex: 0 0 auto;
        margin: 22px 0;
        padding: 0 20px;

        &.filterTags--empty {
            margin: 0;
            padding: 0;
        }
    }

    &__no-matches {
        margin-top: $spacing;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 0 0 auto;
        padding: 0 20px;
        border-bottom: 1px solid $grey3;
        height: 60px;
        margin: -30px 0 0;

        &.no-templates {
            .multiview-select-all {
                visibility: hidden;
            }
        }
    }

    &__select-all {
        margin-right: $spacing-semi-small;
    }
}

.templates-modal .master-template-library {
    &__header {
        margin-top: 0;
        border-bottom: 0;
    }
}
