.creative-insights-chart {
    height: 100%;
    position: relative;

    &__chart {
        position: relative;
        width: 100% !important;
        height: 100% !important;

        &-wrapper {
            height: 100%;
            position: relative;
        }
    }
}