
@import "../../../sass/variables";

.library-header__buttons {
    align-items: center;
    display: flex;
    height: 100%;
}

.library-header__group {
    align-items: center;
    display: flex;
    border-right: 1px solid $grey3;
    height: 100%;
    margin-right: 16px;
    padding-right: 20px;
}

.library-header__select-all {
    white-space: nowrap;
    margin-right: $spacing;
}

.library-header__selected-count {
    border-left: 1px solid $grey3;
    font-size: $font-size-base;
    padding: 0 0 0 $spacing-small;
}
