/** PARSING FUNCTIONS **/
@function str-explode ($string, $delimiter: '') {
    @if type-of($string) != 'string' {
      @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
    }
    @else if type-of($delimiter) != 'string' {
      @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
    }
  
    $result: ();
    $running: true;
  
    @if str-length($delimiter) == 0 {
      @for $i from 1 through str-length($string) {
        $result: append($result, str-slice($string, $i, $i));
      }
  
      @return $result;
    }
  
    @while $running {
      $index: str-index($string, $delimiter);
  
      @if $index != null {
        $item: str-slice($string, 1, ($index - 1));
        $result: append($result, $item);
        $string: str-slice($string, ($index + str-length($delimiter)));
      }
      @else {
        $running: false;
      }
    }
  
    @return append($result, $string);
}
@function deep-map-check ($map, $keys...) {
    @if type-of($map) != 'map' {
      @error 'The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
    }
  
    @each $key in $keys {
      @if not map-has-key($map, $key) {
        @return false;
      }
  
      $map: map-get($map, $key);
    }
  
    @return true;
}

@function deep-map-get ($map, $quote: "1", $keys...) {
    @if type-of($map) != 'map' {
      @error 'The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
    }
  
    @each $key in $keys {
      $map: map-get($map, $key);
    }
    @if $quote == "1" {
        @return unquote($map);
    }
    @return $map;
}
@function get ($key, $quote: "1", $map: $customTheme) {
    $keys: str-explode($key, '.');
  
    @if not deep-map-check($map, $keys...) {
      @error 'The argument $map: `#{$map}` doesn\'t has some of the $keys: `#{$keys}`!';
    }
  
    @return deep-map-get($map, $quote, $keys...);
}
$breakpoint-small-screen: 1440px;
/**** Imports & Variables ****/
@import "overwrite.variables";
@import url(get("settings.fontUrl"));
$whiteComp: get("content.light.color.grey.000");
$colorDatavizDark2Comp: get("content.light.color.dataviz.cat.dark.2");
$colorGrey100Comp: get("content.light.color.grey.100");
$colorGrey200Comp: get("content.light.color.grey.200");
$colorGrey300Comp: get("content.light.color.grey.300");
$colorGrey400Comp: get("content.light.color.grey.400");
$colorGrey500Comp: get("content.light.color.grey.500");
$colorGrey600Comp: get("content.light.color.grey.600");
$colorGrey700Comp: get("content.light.color.grey.700");
$colorGrey800Comp: get("content.light.color.grey.800");
$colorGrey900Comp: get("content.light.color.grey.900");
$colorGrey1000Comp: get("content.light.color.grey.1000");
$colorPrimary100Comp: get("content.light.color.primary.100");
$colorPrimary200Comp: get("content.light.color.primary.200");
$colorPrimary300Comp: get("content.light.color.primary.300");
$colorPrimary400Comp: get("content.light.color.primary.400");
$colorPrimary500Comp: get("content.light.color.primary.500");
$colorPrimary600Comp: get("content.light.color.primary.600");
$colorDanger400Comp: get("content.light.color.danger.400");
$colorDanger500Comp: get("content.light.color.danger.500");
$colorDanger600Comp: get("content.light.color.danger.600");
$colorSuccess200Comp: get("content.light.color.success.200");
$colorSuccess400Comp: get("content.light.color.success.400");
$colorSuccess500Comp: get("content.light.color.success.500");
$colorWarning400Comp: get("content.light.color.warning.400");
$colorWarning500Comp: get("content.light.color.warning.500");
$borderRadiusSComp: get("content.light.border.radius.s");
$borderRadiusMComp: get("content.light.border.radius.m");
$borderRadiusLComp: get("content.light.border.radius.l");
$borderWidthSComp: get("content.light.border.width.s");
$borderWidthMComp: get("content.light.border.width.m");
$borderWidthLComp: get("content.light.border.width.l");
$boxShadowSComp: get("content.light.boxShadow.s");
$boxShadowMComp: get("content.light.boxShadow.m");
$boxShadowLComp: get("content.light.boxShadow.l");


$mainBackColor:  var(--main-back);
$mainFontColor: var(--main-font);
$colorDatavizDark2: var(--color-daraviz-dark2);
$colorGrey100: var(--wpp-grey-color-100);
$colorGrey200: var(--wpp-grey-color-200);
$colorGrey300: var(--wpp-grey-color-300);
$colorGrey400: var(--wpp-grey-color-400);
$colorGrey500: var(--wpp-grey-color-500);
$colorGrey600: var(--wpp-grey-color-600);
$colorGrey700: var(--wpp-grey-color-700);
$colorGrey800: var(--wpp-grey-color-800);
$colorGrey900: var(--wpp-grey-color-900);
$colorGrey1000: var(--wpp-grey-color-1000);
$colorPrimary100: var(--wpp-primary-color-100);
$colorPrimary200: var(--wpp-primary-color-200);
$colorPrimary300: var(--wpp-primary-color-300);
$colorPrimary400: var(--wpp-primary-color-400);
$colorPrimary500: var(--wpp-primary-color-500);
$colorPrimary600: var(--wpp-primary-color-600);
$colorDanger400: var(--wpp-danger-color-400);
$colorDanger500: var(--wpp-danger-color-500);
$colorDanger600: var(--wpp-danger-color-600);
$colorSuccess200: var(--wpp-success-color-200);
$colorSuccess400: var(--wpp-success-color-400);
$colorSuccess500: var(--wpp-success-color-500);
$colorWarning400: var(--warning-color-400);
$colorWarning500: var(--warning-color-500);
$borderRadiusS: var(--wpp-border-radius-s);
$borderRadiusM: var(--wpp-border-radius-m);
$borderRadiusL: var(--wpp-border-radius-l);
$borderWidthS: var(--wpp-border-width-s);
$borderWidthM: var(--wpp-border-width-m);
$borderWidthL: var(--wpp-border-width-l);
$boxShadowS: var(--wpp-box-shadow-s);
$boxShadowM: var(--wpp-box-shadow-m);
$boxShadowL: var(--wpp-box-shadow-l);
/** Dark Theme **/
$tertiaryBackColor: #11141a;
$secondaryBackColor: #1b1f28;
$mainBackColorDark: #020202;

body {
    --main-back: #{$whiteComp};
    --main-font: #{$whiteComp};
    --color-daraviz-dark2: #{$colorDatavizDark2Comp};
    --wpp-grey-color-100: #{$colorGrey100Comp};
    --wpp-grey-color-200: #{$colorGrey200Comp};
    --wpp-grey-color-300: #{$colorGrey300Comp};
    --wpp-grey-color-400: #{$colorGrey400Comp};
    --wpp-grey-color-500: #{$colorGrey500Comp};
    --wpp-grey-color-600: #{$colorGrey600Comp};
    --wpp-grey-color-700: #{$colorGrey700Comp};
    --wpp-grey-color-800: #{$colorGrey800Comp};
    --wpp-grey-color-900: #{$colorGrey900Comp};
    --wpp-grey-color-1000: #{$colorGrey1000Comp};
    --wpp-primary-color-100: #{$colorPrimary100Comp};
    --wpp-primary-color-200: #{$colorPrimary200Comp};
    --wpp-primary-color-300: #{$colorPrimary300Comp};
    --wpp-primary-color-400: #{$colorPrimary400Comp};
    --wpp-primary-color-500: #{$colorPrimary500Comp};
    --wpp-primary-color-600: #{$colorPrimary600Comp};
    --wpp-danger-color-400: #{$colorDanger400Comp};
    --wpp-danger-color-500: #{$colorDanger500Comp};
    --wpp-danger-color-600: #{$colorDanger600Comp};
    --wpp-success-color-200: #{$colorSuccess200Comp};
    --wpp-success-color-400: #{$colorSuccess400Comp};
    --wpp-success-color-500: #{$colorSuccess500Comp};
    --warning-color-400: #{$colorWarning400Comp};
    --warning-color-500: #{$colorWarning500Comp};
    --wpp-border-radius-s: #{$borderRadiusSComp};
    --wpp-border-radius-m: #{$borderRadiusMComp};
    --wpp-border-radius-l: #{$borderRadiusLComp};
    --wpp-border-width-s: #{$borderWidthSComp};
    --wpp-border-width-m: #{$borderWidthMComp};
    --wpp-border-width-l: #{$borderWidthLComp};
    --wpp-box-shadow-s: #{$boxShadowSComp};
    --wpp-box-shadow-m: #{$boxShadowMComp};
    --wpp-box-shadow-l: #{$boxShadowLComp};
}
.wpp-theme-dark {
    --main-back: #020202;
    --main-font: #FFF;
    --color-daraviz-dark2: #{$colorDatavizDark2Comp};
    --wpp-grey-color-100: #{$colorGrey100Comp};
    --wpp-grey-color-200: #{$colorGrey200Comp};
    --wpp-grey-color-300: #{$colorGrey300Comp};
    --wpp-grey-color-400: #020202;
    --wpp-grey-color-500: #{$colorGrey500Comp};
    --wpp-grey-color-600: #{$colorGrey600Comp};
    --wpp-grey-color-700: #{$colorGrey700Comp};
    --wpp-grey-color-800: #{$colorGrey800Comp};
    --wpp-grey-color-900: #{$colorGrey900Comp};
    --wpp-grey-color-1000: #{$colorGrey1000Comp};
    --wpp-primary-color-100: #{$colorPrimary100Comp};
    --wpp-primary-color-200: #{$colorPrimary200Comp};
    --wpp-primary-color-300: #{$colorPrimary300Comp};
    --wpp-primary-color-400: #{$colorPrimary400Comp};
    --wpp-primary-color-500: #{$colorPrimary100Comp};
    --wpp-primary-color-600: #{$colorPrimary600Comp};
    --wpp-danger-color-400: #{$colorDanger400Comp};
    --wpp-danger-color-500: #{$colorDanger500Comp};
    --wpp-danger-color-600: #{$colorDanger600Comp};
    --wpp-success-color-200: #{$colorSuccess200Comp};
    --wpp-success-color-400: #{$colorSuccess400Comp};
    --wpp-success-color-500: #{$colorSuccess500Comp};
    --warning-color-400: #{$colorWarning400Comp};
    --warning-color-500: #{$colorWarning500Comp};
}

/**** STYLE Resets ****/
body {
    background: #f5f7f9;
}
.ivu-checkbox-indeterminate .ivu-checkbox-inner,
.ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: $colorPrimary500;
    background-color: $colorPrimary500;;
}
.ivu-select-dropdown {
    padding: 8px;
    border-radius: $borderRadiusM;
    li {
        border-radius: $borderRadiusM;
    }
    .ivu-input {
        border-radius: $borderRadiusM;
    }
    .ivu-dropdown-item-selected, .ivu-dropdown-item-selected:hover {
        color: $colorPrimary500;
    }
}
.ivu-checkbox-focus {
    box-shadow: none;
}
.ivu-input {
    border-radius: $borderRadiusM;
    &:focus {
        background: $colorGrey200;
        box-shadow: none;
    }
}
/******** Modals *******/
.ivu-modal-mask,
.hox-modal__wrapper {
    background: rgba(255, 255, 255, 0.85);
}
.ivu-select {
    font-size: 14px;
    border-radius: $borderRadiusM;
    padding: 6px 10px;
    border: $borderWidthS solid $colorGrey600;
    color: $colorGrey900;
    .ivu-select-selection {
        border:0;
    }
    &:hover {
        color: $colorGrey800;
        box-shadow: none;
    }
}
.ivu-select-visible {
    .ivu-select-selection {
        box-shadow: none;
    }
}
.theme-campaign-dark {
    .ivu-input {
        background: $mainBackColor;
        color: $colorGrey700;
        border-radius: $borderRadiusM;
        border: $borderWidthS solid $colorGrey600;
        padding: 6px 10px;
        height: auto;
        font-size: 14px;
    }
    .ivu-select {
        padding: 0;
        &.hox-select-dark {
            .ivu-select-selection {
                background: none;
                color: $colorGrey800;
            }
            .ivu-select-dropdown {
                background: $mainBackColor;
                color: $colorGrey800;
                .ivu-select-item {
                    background: $mainBackColor;
                    color: $colorGrey800;
                    border: 0;
                    border-radius: 0;
                    &:hover {
                        border-radius: 0;
                        background: $mainBackColor;
                        color: $colorGrey1000;
                    }
                }
            }
        }
    }
}
.ivu-modal {
    .edit-client {
        .ivu-input-wrapper {
            margin-bottom: 10px;
        }
        .ivu-form-item {
            margin-bottom: 14px;
        }
    }
    .image-upload__uploader {
        color: $colorPrimary400;
        &:hover {
            color: $colorPrimary400;
        }
    }

    .image-upload {
        .ivu-upload-drag {
            &:hover {
                border-color: $colorPrimary400;
            }
        }
    }
    &-content {
        border-radius: $borderRadiusL;
    }
    &-footer {

        .ivu-btn-primary[disabled] {
            border:1px solid $colorPrimary300;
            color: $colorPrimary300 !important;
            background: $mainBackColor;
        }
    }
}
.ivu-modal,
.hox-modal {
    border-radius: $borderRadiusL;
    box-shadow: $boxShadowL;
    .hox-modal__close-icon-container {
        top: 10px;
    }
    .ivu-input {
        border-radius: $borderRadiusM;
        border: $borderWidthS solid $colorGrey600;
        padding: 6px 10px;
        height: auto;
        font-size: 14px;
        line-height: 16px;
    }
    .ivu-input[disabled] {
        background: $colorGrey100;
        border-color: $colorGrey400;
    }
    .hox-input__input {
        border-radius: $borderRadiusM;
        padding: 6px 10px;
        border: $borderWidthS solid $colorGrey600;
        color: $colorGrey900;
        box-shadow: none;
        &:hover {
            border-color: $colorGrey700;
            background: $colorGrey100;
        }
        &:focus {
            box-shadow: none;
            background: $colorGrey200;
            border-color: $colorGrey800;
        }

    }
    .ivu-select-small {
        &.ivu-select-single {
            .ivu-select-selection {
                .ivu-select-selected-value,
                .ivu-select-placeholder {
                    height: auto;
                    line-height: 16px;
                }
            }
        }
    }
    .resource-group-input__enriched-resources {
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;
        > div {
            width: 100%;
        }
    }
    &-footer,
    &-confirm-footer,
    .dialog-footer {
        display: flex;
        width: 100%;
        button:first-child {
            margin-left: auto;
        }
    }
    &-footer {
        display: flex;
        button:first-child {
            margin-left: auto;
        }
        > div {
            display: flex;
            width: 100%;
            button:first-child {
                margin-left: auto;
            }
        }
    }
}
.hox-modal {
    &__header-container {
        padding: 24px 24px 0;
        border: 0;
        font-size: 20px;
        font-weight: 600;
        color: $colorGrey1000;
    }
    &__footer {
        div {
            display: flex;
            &:first-child {
                margin-left: auto;
            }
        } 
    }
    &__wrapper--creative-insights {
        font-family: get("content.light.font.family"), 'WPP Regular', sans-serif;;
    }
    .ivu-btn {
        padding: 9px 20px;
        line-height: 1;
        height: auto;
    }
    .team__invite-form {
        .ivu-btn {
            height: 34px;
        }
    }
}
.hox-modal__header {
    padding: 0;
}
.hox-modal__body {
    padding: 16px 24px;
}
.hox-modal__footer {
    padding: 14px 24px 24px;
    gap: 12px;
}
.share-preview-modal {
    &__show-all-deliverables-container {
        left: 0 !important;
        right: 0 !important;
    }
    &__deliverables-container {
        overflow-y: auto !important;
        padding-right: 12px;
    }
}
.hox-drawer {
    &-nav {
        display: flex;
    }
    .ivu-drawer-header {
        margin-top: 52px;
    }
}
.ivu-drawer {

    &-right {
        .ivu-drawer-close {
            top: 60px;
        }
    }
}
.alert-modal {
    .ivu-modal-footer {
        .ivu-btn {
            &:first-child {
                margin-left: auto;
            }
            &.ivu-btn-error {
                color: $mainFontColor;
                background: $colorDanger500;
                border-color: $colorDanger500;
                &:hover {
                    background-color: $colorDanger400;
                    border-color: $colorDanger400;
                }
            }
        }
    }
}
.language-modal-wrap {
    .ivu-input {
        padding-left: 30px;
        &-prefix {
            i {
                line-height: 30px;
            }
        }
        &-icon {
            line-height: 30px;
            height: 30px;
        }
    }
    .language-modal__tags {
        flex-direction: column;
        > .ivu-tag-default {
            align-self: baseline;
        }
        .tag-list {
            width: 100%;
        }
    }
}
.language-modal__aid {
    &.ivu-alert {
        &.ivu-alert-with-icon {
            border-radius: $borderRadiusM;
        }
    }
    .ivu-alert-icon {
        //color: $colorSuccess500;
    }
}
//General buttons
.ivu-btn {
    border-color: $colorPrimary500;
    border-radius: $borderRadiusM;
    color: $colorPrimary500;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        color: $colorPrimary500;
        border-color: $colorPrimary500;
        background: $colorPrimary100;
    }
    &:focus {
        color: $colorPrimary600;
        border-color: $colorPrimary600;
        background: $colorPrimary200;
        box-shadow: none;
    }
    &.hidden {
        display: none !important;
    }
    &-error {
        border-color: $colorDanger500;
        &:hover {
            border-color: $colorDanger400;
        }
    }
    &-warning {
        background-color: $colorWarning500;
        color: #fff;
        border-color:$colorWarning500;
        &:hover {
            background-color: $colorWarning400;
            border-color:$colorWarning500;
            color: #fff;
        }
    }
}
.ivu-select-item-selected,
.ivu-select-item-selected:hover {
    color: $colorPrimary500;
}
.ivu-btn-primary {
    background: $colorPrimary500;
    border-radius: $borderRadiusM;
    color: $mainFontColor !important;
    border: 0;
    padding: 9px 20px;
    font-size: 14px;
    &:hover {
        background: $colorPrimary400;
    }
    &:focus {
        background: $colorPrimary600;
    }
}
.ivu-select {
    padding: 6px 10px;
}
.ivu-select-single {
    .ivu-select-selection {
        height: auto;
        line-height: 1;
        .ivu-select-placeholder,
        .ivu-select-selected-value {
            height: auto;
            line-height: 1;
        }
    }
}
.ivu-btn-large {
    height: 32px;
}
.ivu-page {
    &-item {
        &:hover {
            border-color: $colorPrimary500;
        }
        &-active {
            border: 1px solid $colorPrimary500;
            a {
                color: $colorPrimary500;
            }
        }
    }
    &-next,
    &-prev {
        &:hover {
            border-color: $colorPrimary500;
        }
    }
}
.campaign-cell-buttons {
    .ivu-btn {
        margin-left: 12px;
    }
}
.multiselect-view-control {
    .ivu-btn {
        color: $colorGrey900;
        border-color: $colorGrey400;
        &.active {
            color: $colorPrimary500;
            border-color: $colorPrimary500;
            background: $mainBackColor;
        }
    }
}
.navigation-tab:hover,
.navigation-tab.navigation-tab--active,
.navigation-tab--color-tertiary:hover,
.navigation-tab--color-tertiary.navigation-tab--active {
    border-bottom-color: $colorPrimary500;
}
.main-nav-button {
    width: 24px;
}
.LoadingBar {
    background-color: $colorPrimary500;
}
.importExportModal__upload-controls {
    .ivu-input {
        padding: 8px 10px;
        margin-right: 8px;
    }
    .ivu-btn{
        margin-left: 10px;
        width: 92px;
    }
}
.image-upload {
    .ivu-upload {
        .ivu-upload-drag {
            color: $colorPrimary500;
            &:hover {
                color: $colorPrimary400;
            }
        }
    }
}
//Hoxton header
.hoxton-header {
    position: fixed;
    width: 100%;
    top: 52px;
    z-index: 2;
}
//Hoxton footer
.campaign-nav,
.hoxton-footer {
    flex-direction: row;
    background-color: $mainBackColor;
    height: 51px;
    padding: 0 28px;
    width: 300px;
    right: 0;
    left: auto;
    border-bottom: 0;
    .footer-nav {
        height: 52px;
        flex-direction: row;
        width: auto;
        align-items: center;
        svg {
            &.logo {
                margin: 0 auto 0 0;
                width: 100%;
                height: 34px;
            }
        }
    }
}
.hoxton-footer {
    position: fixed;
    z-index: 3;
    top: 0;
}
.notifications-drawer {
    position: relative;
    z-index: 11;
    .ivu-drawer-content {
        border-left: 0;
    }
}
.campaign-banner {
    .library-banner__scaling-controls--dark {
        background: $mainBackColor;
        color: $colorGrey900;
    }
    &-footer {
        background: $mainBackColor !important;
    }
}
//Templates library
.templates-modal__library {
    .master-templates-sort-by {
        .ivu-select {
            padding-top: 9px;
            padding-bottom: 9px;
        }
    }
    .section-nav {
        display: none;
    }
    .library-deliverable-table-action-items-cell {
        color: $colorPrimary500;
    }
}
.add-templates-modal {
    .templates-modal__sidebar {
        .ivu-collapse {
            > .ivu-collapse-item {
                &.ivu-collapse-item-active {
                    .ivu-collapse-header {
                        background: $mainBackColor !important;
                    }   
                }
            }
        }
    }
}
.master-template-text-filter {
    .ivu-input-with-suffix {
        padding:8px 28px 7px 10px;
    }
}
.deliverable-value-item {
    background: $mainBackColor;
    color: $colorGrey800;
    border-bottom: 0;
    .ivu-icon-md-create {
        color: $colorGrey800;
    }
    &.visible-row {
        border-bottom: 1px solid $colorGrey300;
    }
}
.deliverable-values {
    .lockable-row__padlock,
    .visible-row__eye {
        color: $colorGrey800;
        border-right: 1px solid $colorGrey300;
    }
}
.detached-panel {
    &.editables-wrapper {
        &::-webkit-scrollbar-track {
            background-color: $colorGrey300;
        }
    }
    .lockable-row__padlock,
    .visible-row__eye {
        color: $colorGrey800;
        border-right: 1px solid $colorGrey300;

    }
    .editable-image-thumbnail {
        border-color: $colorGrey400;
    }
}
.detachable-panel {
    .ivu-modal {
        .ivu-select-small {
            &.ivu-select-single {
                .ivu-select-selection {
                    .ivu-select-selected-value,
                    .ivu-select-placeholder {
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
.detachable-panel,
.attached-panel {
    .deliverable-label {
        background: $colorGrey100;
        border-color: $colorGrey400;
        color: $colorGrey800;
        height: 26px;
        line-height: 24px;
        border-radius: $borderRadiusS;
    }
}
.editable-widgets {
    .last-interaction {
        &.ivu-collapse {
            > .ivu-collapse-item {
                &.ivu-collapse-item-active {
                    .ivu-collapse-header {
                        background: $mainBackColor !important;
                    }
                }
            }
        }
    }
}
.editable-select-widget {
    .labelled-select {
        padding-left: 12px;
        padding-right: 12px;
    }
    .ivu-select-small {
        &.ivu-select-single {
            .ivu-select-selection {
                width: 100%;
            }
        }
    }
}
.theme-campaign-dark {
    &.detachable-panel {
        .ivu-switch-checked {
            border-color: $colorPrimary500;
            background-color: $colorPrimary500;
        }
        .expandable-settings-pane {
            &::-webkit-scrollbar-track {
                background-color: $colorGrey300;
            }
        }
        .ivu-modal-header {
            background: $colorGrey200;
            border-color: $colorGrey400;
            color: $colorGrey800;
        }
        .ivu-modal-content {
            background: $mainBackColor;
            box-shadow: $boxShadowS;
        }
        .editable-settings {
            &-control__button {
                border-color: $colorGrey400;
                color: $colorGrey800;
                svg {
                    fill: $colorGrey800;
                }
            }
            &__input {
                .ivu-input-group-append {
                    background: $mainBackColor;
                    color: $colorGrey800;
                    border-color: $colorGrey600;
                    border-top-right-radius: $borderRadiusS;
                    border-bottom-right-radius: $borderRadiusS;
                }
            }
    
        }
        .font-style__control--color-value {
            border-color: $colorGrey300;
        }
    }

    &.detached-editables {
        .ivu-modal-header  {
            background: $mainBackColor;
            border-bottom: 1px solid $colorGrey300;
        }
        .ivu-modal-content {
            background: $mainBackColor;
            color: $colorGrey800;
            box-shadow: $boxShadowM;
        }
        .ivu-collapse {
            > .ivu-collapse-item {
                &.ivu-collapse-item-active {
                    border-left: 0;
                    border-bottom:0;
                    .ivu-collapse-header {
                        border-bottom: 0;
                    }
                }
            }
        }
        .ivu-collapse {
            .ivu-collapse-content {
                > .ivu-collapse-content-box {
                    padding: 0;
                }
            }
        }
    }
    
    .ivu-collapse {
        background-color: $mainFontColor !important;
        > .ivu-collapse-item {
            &.ivu-collapse-item-active {
                border-color: $colorGrey300;
                .ivu-collapse-header {
                    background: $mainBackColor;
                    color: $colorGrey800;
                }
            }
            .ivu-collapse-header {
                background-color: $mainBackColor;
                color: $colorGrey800;
                border-bottom: 1px solid $colorGrey300;
            }
        }
        &-content {
            background: $mainBackColor;
            color: $colorGrey800;
            .grid-view {
                background: $colorGrey100;
            }
            .editable-image-thumbnail__footer {
                background: $colorGrey100;
            }
            .editable-image-thumbnail__label {
                color: $colorGrey1000;
            }
        }
    }
    .editable-select-widget {
        .labelled-select {
            padding-right: 10px;
        }
    }
    .deliverable-label {
        background: $colorGrey100;
        border-color: $colorGrey400;
        color: $colorGrey800;
    }
    .ivu-poptip-inner {
        background: $mainBackColor;
        li {
            &:hover {
                background: $mainBackColor;
                .editor-banner-option {
                    color: $colorPrimary500;
                }
            }
        }
        .editor-banner-option {
            color: $colorGrey900;
        }
    }
}
.app-wrapper.wpp-theme {
    padding-top: 52px;
    background-color: $mainBackColor;
    font-family: get("content.light.font.family"), 'WPP Regular', sans-serif;
    &.wpp-theme--sandbox {
        .campaign-edit__banner-controls {
            position: sticky;
            bottom: 0;
        }
    }

    &.wpp-theme--imaginematrix {
        max-height: 100vh;
        overflow: hidden;
        .ivu-layout-sider-children {
            overflow-y: scroll;
            max-height: calc(100vh - 52px);
            .ivu-icon-ios-close {
                margin-right: 5px;
            }
        }
        .imagine-header {
            position: sticky;
            top: 0;
            z-index: 2;
        }
    }
    &.wpp-theme--campaigneditor,
    &.wpp-theme--clienttemplates {
        .hoxton-footer {
            height: 50px;
        }
    }
    &.wpp-theme--login {
        padding-top: 0;
        a {
            color: $mainFontColor;
        }
    }
    &.wpp-theme--localdevnetworkerror,
    &.wpp-theme--sandbox,
    &.wpp-theme--home {
        .hoxton-footer {
            width: 100%;
            border-bottom: 1px solid $colorGrey400;
        }
    }
    &.wpp-theme-notopbar {
        padding-top: 0;
        .ivu-layout-sider-children {
            max-height: 100vh;
        }
        #app {
            min-height: 100vh;
        }
        .imagine-header {
            top:0;
        }
        .imagine-matrix__body {
            top: 102px;
        }
    }
    .loading-spinner__icon {
        color: $colorPrimary500;
    }
    .loading-layer--theme-dark {
        &.loading-layer--opaque {
            background-color: $colorGrey200;
        }
    }
    //General styles
    #app {
        min-height: calc(100vh - 52px);
    }
    .top-nav {
        border-top: 1px solid $colorGrey400;
        border-bottom: 1px solid $colorGrey400;
    }
    .alert-modal {
        .ivu-modal-footer {
            div {
                display: flex;
                &:first-child {
                    margin-left: auto;
                }
            }
        }
        &__alert-icon {
            color: $colorDanger500;
            font-size: 32px;
        }
    }
    .home-logo {
        display: flex;
        align-items: center;
        margin-right: auto;
        .ivu-icon {
            margin-right: 20px;
        }
    }
    .main-nav-button {
        height: auto;
        width: auto;
    }
    .main-nav-button__icon-container {
        width: 32px;
        height: 32px;
        font-size: 24px;
    }
    .footer-nav {
        &__links {
            display: flex;
            flex-direction: row;
            gap: 20px;
            margin-right: 25px;
            a {
                color: $colorGrey800;
            }
        }
        .main-nav-button__icon-container {
            width: 26px;
            height: 26px;
            font-size: 24px;
        }
        .disabled {
            opacity:0.4;
        }
    }
    .font-upload__uploader {
        color: $colorPrimary500;
    }
    .empty-message__container {
        .ivu-btn {
            margin: 0 auto;
        }
    }
    .theme-creative-insights {
        font-family: get("content.light.font.family", "0"), 'WPP Regular', sans-serif;
    }
    .link-like {
        color: $colorPrimary500;
    }
    a {
        color: $colorPrimary500;
    }
    .ivu-card-bordered {
        box-shadow: $boxShadowM;
        border-radius: $borderRadiusL;
        border: 0;
    }
    .ivu-checkbox-checked .ivu-checkbox-inner,
    .ivu-switch-checked {
        border-color: $colorPrimary500;
        background-color: $colorPrimary500;;
    }
    .ivu-select-single {
        .ivu-select-selection {
            height: auto;
            background: none;
            .ivu-select-selected-value {
                height: auto;
                line-height: 16px;
            }
        }
    }
    .ivu-btn {
        border-color: $colorPrimary500;
        border-radius: $borderRadiusM;
        color: $colorPrimary500;
        font-weight: 600;
        font-size: 14px;
        &:hover {
            color: $colorPrimary500;
            border-color: $colorPrimary500;
            background: $colorPrimary100;
        }
        &:focus {
            color: $colorPrimary600;
            border-color: $colorPrimary600;
            background: $colorPrimary200;
        }
        &[disabled],
        &.disabled {
            border:1px solid $colorPrimary300;
            background: $mainBackColor;
            color: $colorPrimary300 !important;;
        }
    }
    .ivu-btn-large {
        height: auto;
    }
    .ivu-btn-error {
        border: 0;
        color: $mainFontColor !important;
        background: $colorDanger500;
        border-color: $colorDanger500;
        &:hover {
            background: $colorDanger400;
            border-color: $colorDanger400;
        }
        &:focus {
            background: $colorDanger600;
            border-color: $colorDanger600;
        }
        &.media-library__asset-delete-button {
            color: $colorDanger500 !important;
        }
    }
    .ivu-tag {
        background-color: $colorGrey300;
        border-radius: $borderRadiusS;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 8px 3px 12px;
        height: 28px;
        color: $colorGrey1000;
        font-weight: 400;
        .ivu-icon-ios-close {
            top: 1px;
            &:before {
                color: $colorGrey600;
                font-weight: 700;
                font-size: 20px;
            }
        }
        .ivu-tag-text {
            font-size: 14px;
            line-height: 22px;
        }
        &:hover {
            .ivu-icon-ios-close {
                &:before {
                    color: $colorGrey800;
                }
            }
        }
        &:focus {
            .ivu-icon-ios-close {
                &:before {
                    color: $colorGrey900;
                }
            }
        }
    }
    .ivu-layout-sider {
        left: 0;
    }
    .ivu-dropdown {
        .ivu-select-dropdown {
            padding: 8px;
            border-radius: $borderRadiusM;
            li {
                border-radius: $borderRadiusM;
            }
        }
        .ivu-btn {
            background: $mainBackColor;
            border-color: $colorGrey600;
        }
    }
    .ivu-radio-group {
        &-button {
            &:hover,
            .ivu-radio {
                &-wrapper-checked {
                    border-color: $colorPrimary500;
                    color: $colorPrimary500;
                    box-shadow: none;
                }
            }
        }
        &-item {
            .ivu-radio {
                &-checked {
                    .ivu-radio-inner {
                        border-color: $colorPrimary500;
                        color: $colorPrimary500;
                        &:after {
                            background-color: $colorPrimary500;
                        }

                    }
                }
            }
        }
    }
    .ivu-radio-group-button {
        .ivu-radio-wrapper {
            border-radius: 0 $borderRadiusS $borderRadiusS 0;
            &:first-child {
                border-radius: $borderRadiusS 0 0 $borderRadiusS;
            }
            &:hover,
            &-checked {
                color: $colorPrimary500;
                border-color: $colorPrimary500;
                box-shadow: none;
            }
        }
    }
    .ivu-collapse {
        > .ivu-collapse-item {
            &.ivu-collapse-item-active {
                .ivu-collapse-header {
                    background: $mainBackColor;
                    border-bottom: 0;
                }
            }
            > .ivu-collapse-header {
                > i {
                    margin-right: 5px;
                }
            }
        }
    }
    .ivu-upload-drag {
        &:hover {
            border-color: $colorPrimary400;
        }
    }
    .hox-container {
        padding: 0 28px;
    }
    .user-menu__actions {
        &:hover {
            color: $colorPrimary500;
        }
    }
    .collapsible-widget {
        &__panel-controls {
            margin: 0 5px;
            height: 100%;
        }
        &__panel-name {
            overflow: visible;
        }
    }
    .client-sort {
        .ivu-form-item {
            align-items: center;
            display: flex;
            &-content {
                margin-left: 0 !important;
            }
            &-label {
                padding: 0 12px 0 0;
                @media (max-width: $breakpoint-small-screen) {
                    font-size: 12px;
                }  
            }
        }
    }
    //Account settings 
    .manage-users-modal__add-client-wrapper {
        padding-bottom: 15px;
        .ivu-icon {
            font-size: 14px;
            line-height: 14px;
        }
    }
    .ivu-page-item-active {
        border: 1px solid $colorPrimary500;
    }
    .manage-users-modal-content {
        .ivu-table-cell {
            .ivu-btn {
                padding: 9px 12px;
            }
        }
        .user-list-table {
            .ivu-input-inner-container {
                display: flex;
                align-items: center;
            }
        }
    }
    //Insights Dashboard
    .discard_header__add-btn {
        color: $colorPrimary500;
    }
    .insights-dashboard {
        width: calc(100% - 32px);
        margin: 0 16px;
    }
    .dashboard-filters {
        margin: 0 12px;
    }
    .insights-dashboard-tag-component {
        padding: 12px;
    }
    .dashboard-content-dropdown__type-value {
        color: $colorGrey1000;
        font-size: 28px;
        line-height: 40px;
        font-weight: 600;
    }
    .dashboard-content-dropdown {
        margin: 24px 12px;
    }
    .insight-preview {
        width: calc(100vw - 396px);
    }
    .local-chart-settings {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .insights-dashboard-tag-component__layout-header-name {
        color: $colorGrey1000;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
    }
    .insights-dashboard-tag-component__layout {
        border: 0;
    }
    .insights-tags__marketplace h3 span {
        color: $colorPrimary500;
    }
    .insights-tags__marketplace .ivu-btn,
    .insights-dashboard__marketplace .ivu-btn,
    .ivu-btn-primary {
        background: $colorPrimary500;
        border-radius: $borderRadiusM;
        color: $mainFontColor !important;
        border: 0;
        padding: 9px 20px;
        font-size: 14px;
        &:hover {
            background: $colorPrimary400;
        }
        &:focus {
            background: $colorPrimary600;
        }
    }
    .insights-tags .pane-handle {
        left: 0;
    }
    .insights-tags .pane-handle--open {
        left: 250px;
    }
    .insights-tags__list {
        padding-top: 55px;
    }
    .creative-insights-header {
        &.creative-insights-header,
        &.creative-insights-header--sidebar-closed {
            padding: 0 24px 0 36px;
            height: 57px;
            border-bottom: 1px solid $colorGrey400;
        }

        &__advanced-search {
            padding-top: 0;
            padding-bottom: 0;
        }
        &__item {
            &.creative-insights-header__date-picker {
                margin-top: 0;
                .ivu-date-picker-cells-header {
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
        &__logo {
            margin-right: 20px;
            &-image {
                height: 36px;
                max-height: 36px;
            }
            a {
                margin: auto 0;
            }
        }
    }
    .insights-wrapper {
        display: block;
        max-width: calc(100vw - 250px);
    }

    .creative-insights-header--sidebar-closed {
        + .creative-insights-content {
            .insights-wrapper {
                max-width: calc(100vw - 24px);
            }
        }
    }
    .insights {
        margin: 0 24px;
        width: calc(100% - 48px);
        .ivu-collapse {
            border:0;
            > .ivu-collapse-item {
                border:0;
                .ivu-collapse-header {
                    box-shadow: $boxShadowM;
                    border-radius: $borderRadiusL;
                }
            }
            > .ivu-collapse-item-active {
                .ivu-collapse-header {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            .insight-preview__panel {
                >  .ivu-collapse-content {
                    box-shadow: $boxShadowM;
                    border-bottom-left-radius: $borderRadiusL;
                    border-bottom-right-radius: $borderRadiusL;
                }
            }
        }
    }
    .insight-preview {
        width: 100%;
        max-width: 100%;
    }
    .kpi-matrix {
        max-width: calc(100vw - 305px);
    }
    .creative-insights-header--sidebar-closed {
        + .creative-insights-content {
            .kpi-matrix {
                max-width: calc(100vw - 62px);
            }
        }
    }
    .ci-settings-modal__section {
        padding: 20px 0;
        &-number {
            margin-left: 5px;
        }
    }
    .empty-message__title {
        .ivu-btn-primary {
            margin: 0 auto;       
        }
    }
    .importExportModal__upload-controls {
        .ivu-input-wrapper{
            margin-right: 12px;
        }
        .ivu-btn {
            margin-left: 0;
            width: auto;
        }
    }
    //NavBar
    .imagine-navigation,
    .section-nav {
        padding-right: 30px;
        margin-right: auto;
        font-weight: 600;
        font-size: 14px;
        color: $colorGrey1000;
        @media (max-width: $breakpoint-small-screen) {
            padding-right: 10px;
            font-size: 12px;
        }  
        .dropdown-selection{
            cursor: pointer;
        }
        &.ivu-dropdown {
            .ivu-select-dropdown {
                padding: 0;
                ul {
                    padding: 8px;
                }
            }
        }
        li {
            padding: 0;
            span {
                color: $colorGrey1000;
                font-weight: 500;
                padding: 7px 16px;
                display: inline-block;
                margin: 0;
            }
            a {
                color: $colorGrey1000;
                font-weight: 500;
                padding: 7px 16px;
                display: inline-block;
                width: 100%;
                height: 100%;
            }
            &:hover {
                a {
                    color: $colorPrimary500;
                }
            }
        }
    }
    .main-nav-button__icon-container--circle-border {
        border-color: #9b9b9b;
        .ivu-icon {
            color: #9b9b9b;
        }
        &:hover {
            border-color: #9b9b9b;
            .ivu-icon {
                color: #9b9b9b;
            }
        }
    }
    .creative-insights-nav {
        width: 100%;
        height: auto;
        background: $mainBackColor;
        flex-direction: row;
        background-color: $mainBackColor;
        border-bottom: 1px solid $colorGrey400;
        height: 52px;
        padding: 0 28px 0;
        .footer-nav {
            flex-direction: row;
            width: auto;
            align-items: center;
            svg.logo {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .main-nav-button {
        .main-nav-button__background {
            background: $mainBackColor;
        }
        &:hover {
            .main-nav-button__background {
                background: $mainBackColor;
            }
        }
    }
    .user-menu,
    .main-nav-button__wrapper--margin-bottom-base,
    .creative-insights-nav__home-button-wrapper {
        margin-bottom: 0;
    }
    .creative-insights-nav__home-button-wrapper {
        display: flex;
        align-items: center;
        .ivu-icon {
            margin-right: 20px;
            font-size: 26px;
        }
    }
    .main-nav-button__icon-container > svg {
        fill: $colorPrimary500;
        height: 100%;
        width: 100%;
        path {
            fill: $colorPrimary500;
        }
    }
    .main-nav-button__wrapper--margin-bottom-base {
        margin-right: 25px;
    }
    .user-menu {
        &__avatar-icon {
            background: $colorDatavizDark2;
            color: $mainFontColor;
        }
    }
    //Campaign Navbar
    .top-nav {
        padding-left: 28px;
        &:before {
            content: "";
            display: block;
            width: 100vw;
            height: 52px;
            background-color: $mainBackColor;
            position: absolute;
            top: -52px;
            left: 0;
            z-index: 0;
        }
        &__menu-icon {
            font-size: 26px;
            position: absolute;
            top: -39px;
            left: 28px;
        }
        .client-logo {
            position: absolute;
            z-index: 10;
            height: 50px;
            top: -51px;
            left: 0;
            display: flex;
            align-items: center;
            margin-left: 28px;
            .logo-image {
                max-height: 36px;
            }
            .ivu-select-dropdown {
                width: 200px;
                .ivu-dropdown-item {
                    &.active {
                        color: $colorPrimary500;
                    }
                }
            }

        }
        .dropdown-selection--section {
            text-transform: none;
            color: $colorGrey1000;
            font-size: 14px;
            font-weight: 600;
            @media (max-width: $breakpoint-small-screen) {
                font-size: 12px;
            }  
        }
        .hox-menu {
            line-height: 22px;
            height: auto;
        }
        .ivu-menu-light {
            &:after {
                display: none;
            }
            &.ivu-menu-horizontal {
                .ivu-menu-submenu,
                .ivu-menu-item {
                    border: 0;
                    color: $colorGrey800;
                    font-size: 14px;
                    border-radius: $borderRadiusS;
                    padding: 9px 15px;
                    margin: 0 5px;
                    height: auto;
                    line-height: 24px;
                    font-weight: 600;
                    @media (max-width: $breakpoint-small-screen) {
                        font-size: 12px;
                        padding: 9px 5px;
                    }
                    &:hover {
                        background: $colorGrey200;
                    }
                    &.ivu-menu-item-selected {
                        background: $colorGrey300;
                        color: $colorGrey1000;
                    }
                }
            }
            .ivu-select-selection {
                height: auto;
                .ivu-select-selected-value {
                    height: auto;
                }
            }
        }
    }
    //Advanced search
    .creative-insights-header__date-picker {
        .ivu-btn {
            height: 34px;
            border-color: $colorGrey600;
            color: $colorGrey900;
            font-weight: 600;
            display: flex;
            align-items: center;
            font-size: 14px;
            &:hover {
                color: $colorGrey800;
            }
            &:focus {
                background: $colorGrey300;
                color: $colorGrey1000;
                border-color: $colorGrey800;
            }
            span {
                display: flex;
                align-items:center ;
            }
            i {
                margin-right: 5px;
            }
        }
        .ivu-input {
            border-radius: $borderRadiusS;
            border-color: $colorGrey600;
            color: $colorGrey900;
        }
        .ivu-date-picker-cells-cell-range:before {
            background: $colorPrimary100;

        }
        .ivu-date-picker-cells-cell-selected em, 
        .ivu-date-picker-cells-cell-selected:hover em {
            background: $colorPrimary500;
        }
    }
    .advanced-search {
        border: 0;
        input {
            border: $borderWidthS solid $colorGrey700;
            border-radius: $borderRadiusS;
            padding: 4px 8px;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-top: 0;
            margin-right: 14px;
            &::placeholder {
                color: $colorGrey700;
                font-size: 14px;
                line-height: 22px;
            }
            &:focus {
                background: $colorGrey200;
                border: $borderWidthS solid $colorGrey700;
                color: $colorGrey1000;
                outline: none;
            }
        }
        .advanced-search__icon {
            margin-top: 0;
            color: $colorGrey900;
        }
        .advanced-search__type {
            margin-top: 0;
        }
        .advanced-search__button {
            background: $colorPrimary500;
            border-radius: $borderRadiusM;
            color: $mainFontColor !important;
            border: 0;
            padding: 0px 20px;
            height: 34px;
            &:hover {
                background: $colorPrimary400;
            }
            &:focus {
                background: $colorPrimary600;
            }
        }
        .creative-insights-header__item {
            margin-top: 0;
            padding-top: 0;
        }
        .ivu-dropdown-rel {
            span {
                font-weight: 600;
                color: $colorGrey900;
            }
        }
    }
    .advanced-search__type {
        padding: 0 14px;
        .ivu-dropdown-rel {
            a {
                font-size: 14px;
                border-radius: $borderRadiusM;
                padding: 6px 10px;
                border: $borderWidthS solid $colorGrey600;
                display: inline-block;
                height: 34px;
                line-height: 1;
                i {
                    &::before {
                        color: $colorGrey800;
                    }
                }
            }
        }
    }
    // Workspage settings
    .hox-modal__wrapper--creative-insights {
        .ivu-table-row {
            .ivu-btn {
                padding: 6px 12px;
            }
            .ivu-input {
                line-height: 16px;
            }
        }
    }
    .user-permission-modal-table-wrapper {
        > .ivu-btn {
            margin-top: -10px;
        }
        .ivu-btn {
            > .ivu-icon {
                font-size: 16px;
                line-height: 16px;
            }
        }
        .client-select {
            margin: 0 5px;
            .ivu-select {
                padding: 3px 0;
            }
        }
    }
    // Marketplace items modal
    .insights-dashboard__marketplace {
        h3 {
            span {
                color: $colorPrimary500;
            }
        }
    }
    .marketplace__navigation-select {
        margin-right: 36px;
        padding: 9 10px;
    }
    .insights-marketplace {
        .hox-modal {
            .hox-modal__close-icon-container {
                top: 18px;
            }
            .hox-modal__header-container {
                padding-bottom: 16px;
            }
            .hox-modal__body {
                height: calc(90vh - 85px);
            }
        }     
    }
    .marketplace-item__footer-action--add {
        color: $colorPrimary500;
        &:hover {
            color: $colorPrimary400;
        }
    }
    //Media Library
    .media-library__data-card:hover .media-library__data-card-copy-button {
        color: $colorPrimary500;
        padding: 5px;
        border-color: $colorPrimary500;
    }
    .media-library__data-card {
        .ivu-btn-error {
            color: $colorDanger500 !important;
            padding: 6px 10px;
        }
    }
    .media-library__data-col {
        &.selected {
            .ivu-card-body {
                border: 1px dotted $colorPrimary500;
                border-radius: $borderRadiusS;
            }
        }
    }
    .media-library-add-asset-from-url {
        .ivu-input {
            padding-left: 30px;
        }
        .ivu-input-prefix {
            i {
                line-height: 30px;
            }
        }
    }
    //Campaigns
    .campaigns {
        padding-top: 93px;
    }
    .campaign-content {
        // margin-left: -12px;
        margin-left: 0;
    }
    .campaign-nav {
        > div {
            display: flex;
        }
        &__home-button-wrapper {
            margin-bottom: 0;
        }
    }
    .card__container {
        border: 0;
        border-radius: $borderRadiusL;
        box-shadow: $boxShadowM;
        .campaign-card__logo {
            border-top-right-radius: $borderRadiusL;
            border-top-left-radius: $borderRadiusL;
        }
    }
    
    .card__container--clickable {
        transition: box-shadow 0.15s;
        &:hover {
            box-shadow: $boxShadowM;
        }
    }
    .ivu-layout {
        .ivu-layout-has-sider {
            position: relative;
            > .ivu-layout-content {
                overflow-x: visible;
            }
        }

        .campaigns__action-items {
            top: 44px;
            width: 570px;
            position: fixed;
            z-index: 3;
            right: 28px;
            .ivu-form-item {
                display: flex;
                align-items: center;
                .ivu-form-item-content {
                    margin-left: 0 !important;
                }
            }
        }
        .clients__tabs {
            display: flex;
            align-items: center;
            margin-right: auto;
            &--title {
                font-weight: 600;
                font-size: 14px;
                color: $colorGrey1000;
                margin-right: 20px;
            }
            span {
                display: inline-block;
                text-transform: capitalize;
                padding: 8px 15px;
                border-radius: $borderRadiusS;
                margin:0 5px;
                background: $colorGrey300;
                border:none;
                color: $colorGrey900;
                font-weight: 500;
                &.clients__tabs--sandbox {
                    background: $mainBackColor;
                    &:hover {
                        cursor: pointer;
                        background: $colorGrey300;
                    }
                }
            }
            .section-nav {
                li {
                    &:hover {
                        span {
                            color: $colorPrimary500;
                        }
                    }
                }
                span {
                    background: none;
                    color: $colorGrey1000;
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .clients__action-items {
            position: relative;
            padding: 10px 0;
            margin-bottom: 30px;
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: -28px;
                width: calc(100% + 56px);
                height: 1px;
                background-color: $colorGrey400;
            }
        }
        .clients__action-items,
        .campaigns__action-items {
            .ivu-select-large {
                &.ivu-select-single {
                    .ivu-select-selection {
                        height: auto;
                        .ivu-select-selected-value {
                            height: auto;
                            line-height: 22px;
                            @media (max-width: $breakpoint-small-screen) {
                                font-size: 12px;
                            }  
                        }
                    }
                }
            }
            .ivu-btn {
                border: 0;
                color: $colorGrey900;
                position: relative;
                padding-left: 30px;
                line-height: 22px;
                &:hover {
                    background: $colorGrey200;
                }
                &:focus {
                    background: $colorGrey400;
                }
                &:before, 
                &:after {
                    content: " ";
                    width: 14px;
                    height: 2px;
                    background-color: $colorGrey600;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transition: all 0.15s cubic-bezier(.42, 0, .58, 1);
                    opacity: 1;
                    border-radius: 2px;
                }
                &:before {
                    transform: translate(-50%, -50%) rotate(90deg);
                }
                &:after {
                    transform: translate(-50%, -50%);
                }
                @media (max-width: $breakpoint-small-screen) {
                    font-size: 12px;
                }  
            }

            .ivu-btn-primary {
                background:none;
                color: $colorPrimary500 !important;
                &:before, 
                &:after {
                    background-color: $colorPrimary500;
                }
                @media (max-width: $breakpoint-small-screen) {
                    font-size: 12px;
                }
            }
        }
    }
    .campaigns-dashboard__empty-message-wrapper,
    .fonts-dashboard__empty-message-wrapper {
        padding: 96px 0 0;
    }
    .fonts-dashboard__loading-wrapper {
        padding: 96px 0 0;
        height: 216px;
    }
    .campaign-header {
        background: none;
        border: 0;
        width: auto;
        padding-left: 0;
        height: 52px;
        z-index: 3;
        position: relative;
        top: 0;
        margin-left: 0;
        margin-right: auto;
        flex: 1;
        &__breadcrumbs {
            display: flex;
            flex-direction: row;
            flex: 1;
            min-width: 500px;
            margin-right: auto;
            margin-left: 0;
        }
        &__breadcrumb {
            .ivu-breadcrumb-item-separator,
            a {
                color: $colorGrey800;
                font-weight: 400;
                &:hover {
                    border-bottom: 0;
                    color: $colorPrimary400;
                }
            }
            .router-link-exact-active {
                border-bottom: 0;
                color: $colorGrey1000;
            }
        }
        &__nav {
            width: 100%;
            display: flex;
            flex-direction: row;
            background-color: $mainBackColor;
            border-bottom: 1px solid $colorGrey400;
            height: 52px;
            padding: 0 28px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 6;
            .ivu-icon-md-menu {
                display: flex;
                align-items: center;
                margin-right: 20px;
                font-size: 26px;
            }
            .footer-nav {
                flex-direction: row;
                z-index: 10;
            }
        }
    }
    //Templates
    .master-templates__filters {
        padding-top: 52px;
    }
    .master-template-library__no-matches {
        margin-top: 56px;

    }
    .master-template-select {
        .master-template-select__wrapper {
            padding-top: 16px;
        }
    }
    .master-template-library__header {
        position: fixed;
        z-index: 2;
        width: 100%;
        background: $mainBackColor;
        left: 0;
        top: 81px;
    }
    .master-templates__filters {
        padding-top: 110px;
        background: $mainBackColor;
        .collapsible-widget {
            .ivu-checkbox-wrapper {
                padding-left: 44px;
            }
        }
        .ivu-collapse {
            &-content {
                background: $mainBackColor;

            }
            > .ivu-collapse-item {
                border: 0;
                .ivu-collapse-header {
                    background: $mainBackColor;
                }
                > .ivu-collapse-header {
                    line-height: 1;
                    height: auto;
                    margin-top: 16px;
                    > i {
                        margin-right: 7px;
                    }
                }
                &.ivu-collapse-item-active {
                    .ivu-collapse-header {
                        border: 0;
                        background: $mainBackColor;
                        line-height: 1;
                        height: auto;
                    }
                }
            }
        }
    }
    //Campaign Editor
    .attached-panel {
        .editable-widgets {
            height: calc(100vh - 165px);
        }
    }
    .add-template-to-campaign {
        background: #fafafa;
        width: 100%;
        h2 {
            color: $colorGrey1000
        }
        &__action {
            background:#fff;
            border-radius: $borderRadiusM;
            box-shadow: $boxShadowM;
            color: $colorGrey1000;
        }
        .ivu-btn {
            background: none;
            border:1px solid $colorPrimary500;
        }
    }
    .campaign-light__content-header {
        position: fixed;
        left: 0;
        width: 100%;
        background: #fff;
        top: 81px;
        z-index: 2;
        display: flex;
        align-items: center;
        overflow: visible;
        padding: 0 28px;
    }
    .campaign-layout {
        .campaign-library {
            &__sidebar-nav {
                margin-top: 110px;
                border-bottom: 0;
                height: 80px;
                padding: 35px 20px 15px;
                .navigation-tab {
                    text-transform: capitalize;
                    padding: 0;
                    border-radius: $borderRadiusS;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin:0 15px;
                    width: calc(50% - 30px);
                    flex: 1;
                    border:none;
                    color: $colorGrey900;
                    font-weight: 500;
                    &:hover,
                    &--active {
                        border:none;
                        background: $colorGrey300;
                    }
                }
            }
            &__variations {
                color: $colorPrimary500;
            }
            .ivu-dropdown {
                .ivu-btn {
                    color: $colorGrey1000 !important;
                    border: 1px solid $colorGrey600;
                    &:hover {
                        color: $colorGrey800 !important;
                        border-color: $colorGrey700;
                        background: $colorGrey100;
                        box-shadow: none;
                    }
                    &:focus {
                        color: $colorGrey1000 !important;
                        border-color: $colorGrey800;
                        background: $colorGrey300;
                        box-shadow: none;
                    }
                }
            }
            .set-deliverables-visible-to-dropdown__dropdown-footer {
                .ivu-btn {
                    border-color: $colorPrimary500 !important;
                    color: $colorPrimary500 !important;
                    &:hover {
                        color: $colorPrimary500 !important;
                        background: $colorPrimary100;
                    }
                    &:focus {
                        color: $colorPrimary600 !important;
                        border-color: $colorPrimary600 !important;
                        background: $colorPrimary200;
                    }
                }
            }
            .library-header__select-all {
                display: flex;
            }

        }
        .sidebar-panel{
            padding-top: 112px;
            border-right: 1px solid $colorGrey400;
            &__header {
                height: 48px;
                align-items: center;
            }
            &__inner-pane {
                margin-top: 0;
                border-top: 1px solid $colorGrey400;
            }
            .editables-controls {
                border-bottom: 1px solid $colorGrey400;
                height: 49px;
                align-items: center;
                .ivu-icon-md-create {
                    color: $colorGrey800 !important;
                }
                .ivu-btn {
                    padding: 0;
                    border: 0;
                    margin-left: 5px;
                    margin-bottom: 0;
                    &:hover {
                        color: $colorPrimary400;
                        background: none;
                    }
                }
                .ivu-input {
                    &:focus,
                    &:hover {
                        border-color: $colorGrey400;
                    }
                }
                .campaign-edit__header-action-btn {
                    margin-bottom: 0;
                }
            }
            .ivu-collapse {
                > .ivu-collapse-item {
                    > .ivu-collapse-header {
                        align-items: center;
                        height: auto;
                        line-height: 1;
                        padding: 16px 12px;  
                    }
                    &.ivu-collapse-item-active {
                        > .ivu-collapse-header {
                            padding-bottom: 0;
                        }

                    }
                }
            }
            
        }
        .sidebar-panes {
            left: 0;
            .editable {
                &-image-thumbnail__footer {
                    background: $colorGrey200;
                    color: $colorGrey800;
                }
                &-widgets {
                    .grid-view {
                        background: $mainBackColor;
                    }
                    .last-interaction {
                        &.ivu-collapse {
                            > .ivu-collapse-item {
                                &.ivu-collapse-item-active {
                                    .ivu-collapse-header {
                                        background: $mainBackColor;
                                    }
                                }
                            }
                        }
                    }
                }
                &-controls {
                    border-bottom: 1px solid $colorGrey400;
                }
                &-group {
                    &__add {
                        background: $mainBackColor;
                        border-left: 2px solid $colorPrimary500;
                    }
                    &__controls {
                        display: flex;
                        align-items: center;
                        &--tip {
                            font-style: normal;
                            color: $colorPrimary500;
                        }
                    }
                    &__values {
                        &:hover {
                            background:none;
                        }
                    }
                    &__body {
                        .ivu-radio-wrapper,
                        .editable-group__controls {
                            &.ivu-radio-wrapper-checked,
                            &:hover {
                                background: $mainBackColor;
                                border-radius: $borderRadiusS;
                
                                .ivu-radio .ivu-radio-inner {
                                    border-color: $colorPrimary300;
                                }
                            }
                        }
                    }
                    &__feed-upload {
                        color: $colorGrey800;
                    }
                    &__value-actions {
                        i {
                            opacity: 0.5;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                    &--editing {
                        .ivu-collapse {
                            > .ivu-collapse-item {
                                &.ivu-collapse-item-active {
                                    .ivu-collapse-header {
                                        background: $mainBackColor;

                                    }
                                }
                            }
                        }
                    }
                    .ivu-collapse-content-box {
                        border-bottom: 0;
                        padding-left: 16px;
                    }
                    .show-form {
                        border: 0;
                        color: $colorPrimary500;
                        font-size: 16px;
                    }
                }
            }
            .ivu-icon-md-add:before {
                color: $colorPrimary500;
            }
            .ivu-select {
                height: 24px;
                padding: 0px;
                line-height: 24px;
                border-radius: $borderRadiusS;
                &-single {
                    .ivu-select-selection {
                        .ivu-select-selected-value {
                            height: 24px;
                            line-height: 22px;
                        }
                    }
                }
            }
            .ivu-input {
                border-color: $colorGrey600;
            }
            .ivu-input-group-with-append {
                &.ivu-input-group-small {
                    .ivu-input {
                        border-color: $colorGrey600;
                        border-bottom-left-radius: $borderRadiusS;
                        border-top-left-radius: $borderRadiusS;
                    }
                }
            }
        }
        .expandable-settings-pane svg {
            fill: $colorGrey800;
        }
        .expandable-text-input__expanded {
            border-left:2px solid $colorPrimary500;
            &-header {
                color: $colorPrimary500;
            }
        }
        .editable-settings {
            &-control__button {
                color: $colorGrey800;
                border-right: 1px solid $colorGrey600;
            }
            &__input  {
                .ivu-input-group-append {
                    background-color: $colorGrey200;
                    color: $colorGrey800;
                    border-color: $colorGrey600;
                    border-bottom-right-radius: $borderRadiusS;
                    border-top-right-radius: $borderRadiusS;
                    padding:0 4px 0 5px;
                }
            }
        }
        .labelled-value__text {
            &:hover {
                .labelled-value__icons-container {
                    color: $colorGrey800;
                }
            }
          }
        .lockable-row__padlock,
        .visible-row__eye {
            background: $mainBackColor;
            border-right: 1px solid $colorGrey400;
            color: $colorGrey800;
        }
        .deliverable-value-item {
            background: $mainBackColor;
            color: $colorGrey800;
            border: 0;
            &.visible-row {
                border-bottom: 1px solid $colorGrey400;
            }
        }
        .editable-widget__body {
            > .deliverable-value-item {
                &:first-child {
                    border-top: 1px solid $colorGrey400;                 
                }
            }
        }
        .deliverable-label {
            background: $colorGrey100;
            border-color: $colorGrey400;
            color: $colorGrey800;
        }
        .font-style__control--color-value {
            border-left: 1px solid $colorGrey400;
        }
        .library-deliverable-table-action-items-cell {
            color: $colorPrimary500;
        }
        .qa-drawer-header__action-item {
            .ivu-btn-primary {
                
                color: $colorPrimary500 !important;
                border:1px solid $colorPrimary500;
                box-shadow:none;
                &:hover {
                    background: $colorPrimary100;
                }
                &:focus {
                    color: $colorPrimary600;
                    border-color: $colorPrimary600;
                    background: $colorPrimary200;
                    box-shadow: none;
                }
            }
        }

    }
    .campaign-content {
        .hoxton-header {
            position: relative;
            top: 0;
            padding-left: 0;
        }
        .main-content {
            padding-top: 60px;
        }
    }
    .campaign-edit {
        &__banner-controls {
            background: $colorGrey200;
        }
        &__header-action-select {
            padding: 6px 10px;
            .ivu-select-selection {
                .ivu-select-selected-value {
                    line-height: 19px;
                }
            }
            .ivu-select-dropdown {
                background: $mainBackColor;
                &:hover {
                    background: $mainBackColor;
                }
                .ivu-select-item {
                    &:hover {
                        background: $mainBackColor;
                    }
                    &.ivu-select-item-selected {
                        color: $colorGrey800;
                    }
                }
            }
        }
        &__header-action-btn {
            background: none;
            border: none;
            position: relative;
            display: flex;
            align-items: center;
            &--add-assets,
            &--add-template,
            &--add-platform,
            &--add-language {
                &:before {
                    content: "\f330";
                    display:inline-block;
                    font-family: "Ionicons";
                    position: relative;
                    bottom: initial;
                    right: initial;
                    top: 0%;
                    left: 0;
                    opacity: 1;
                    margin-right: 5px;
                    font-weight: 600;
                    background: none;
                }
            }
            &--export {
                background-color: $mainBackColor;
                border:1px solid $colorPrimary500;
                color: $colorPrimary500;
                margin-bottom: 0;
            }
        }
        &__header {        
            position: fixed;
            z-index: 2;
            left: 0;
            top: 73px;
            width: 100%;
            background: $mainBackColor;
            border-bottom: 1px solid $colorGrey400;
            overflow: visible;
            &-action-select { 
                margin-right: 5px;
                .ivu-select-dropdown,
                .ivu-select-selection {
                    border: none;
                    height: auto;
                    color: $colorGrey900;
                    .ivu-select-item {
                        border-bottom: 0;
                        color: $colorGrey800;
                        &.ivu-select-item-focus {
                            background: $mainBackColor;
                            color: $colorGrey900;

                        }
                    }
                }
            }
        }
        .save-changes {
            display: flex;
            .ivu-btn {
                color:$colorPrimary500 !important;
                border: 1px solid $colorPrimary500;
                &:hover {
                    background: $colorPrimary100;
                }
                &:focus {
                    color:$colorPrimary600 !important;
                    background: $colorPrimary200;
                }
            }
            .ivu-btn-primary {
                color: $mainFontColor !important;
                background: $colorPrimary500;
                &:hover {
                    background: $colorPrimary400;
                }
                &:focus {
                    color:$colorPrimary600 !important;
                    background: $colorPrimary600;
                }
            }
        }
    }

    /**** IMAGINE ****/
    .cost-indicator__cost {
        color: $colorPrimary400;
    }
    //Custom options
    .ivu-slider-bar {
        background: $colorPrimary500;
    }
    .ivu-slider-button {
        border: 2px solid $colorPrimary500;
    }
    .imagine {
        &-header {
            height: 70px;
            padding: 10px 28px;
            margin-left: 0;
            position: sticky;
            top: 52px;
            z-index: 2;
            border-bottom: 1px solid $colorGrey400;
            &__cost {
                .cost-indicator__title {
                    font-size: 13px;
                    line-height: 14px;
                }
                .cost-indicator__cost,
                .cost-indicator__left {
                    font-size: 11px;
                    line-height: 14px;
                }
            }
            &__item {
                padding-left: 0;
            }
            &__model {
                display: flex;
                flex-direction: column;
                width: 163px;
                min-width: 163px;
                flex: 0 0 163px;
            }
            .model-select-button {
                &__title {
                    padding-bottom: 0;
                    font-size: 13px;
                    line-height: 14px;
                }
                .ivu-icon {
                    font-size: 14px;
                }
            }
            .imagine-navigation {
                padding-right: 0;
                li {
                    &:hover {
                        span,
                        a {
                            color: $colorPrimary500;
                        }
                    }
                }
                span,
                a {
                    color: $colorGrey900;
                }
            }
        }
        &__tips-header-title {
            color: $colorGrey1000;
        }
        &__body {
            margin: 32px 28px;
            width: calc(100% - 56px);
        }
        &__searches {
            width: 100%;
            margin: 0;
        }
        &__sidebar {
            width: 100%;
            color: $colorGrey1000;
            .ivu-collapse {
                > .ivu-collapse-item {
                    &.ivu-collapse-item-active {
                        .ivu-collapse-header {
                            background: $mainBackColor;
                        }
                    }
                    > .ivu-collapse-header {
                        height: auto;
                        line-height: 1;
                        padding: 9px 0;
                        color: $colorGrey1000;
                        > i {
                            margin-right: 2px;
                            color: $colorGrey600;
                        }
                    }
                }
            }
            .ivu-select {
                margin-top: 5px;
            }
            .ivu-select-single {
                .ivu-select-selection {
                    .ivu-select-selected-value,
                    .ivu-select-placeholder {
                        height: auto;
                        line-height: 1;
                    }
                }
            }
            .ivu-input {
                border-radius: $borderRadiusM;
                border: 1px solid $colorGrey600;
                &:focus {
                    border-color: $colorGrey700;;
                    box-shadow: none;
                }
            }
        }
        &__tip {
            .ivu-collapse-content  {
                > .ivu-collapse-content-box {
                    padding: 10px 0;
                }
            }
            .ivu-collapse-simple {
                border: 0;
                border-radius: $borderRadiusM;
            }
        }
        
        &-prompt {
            padding-bottom: 0;
            border-bottom: 0;
            &__button {
                background: $colorPrimary500;
                border-radius: $borderRadiusM;
                color: $mainFontColor !important;
                border: 0;
                padding: 9px 20px;
                font-size: 14px;
                height: auto;
                margin-right: 30px;
                margin-top: -1px;
                &:hover {
                    background: $colorPrimary400;
                }
                &:focus {
                    background: $colorPrimary600;
                }

            }
            &__icon {
                margin-top: 0;
                color: $colorPrimary500;
            }
            .ivu-dropdown {
                text-transform: none;
                color: $colorGrey1000;
                font-size: 16px;
                font-weight: 600;
                margin-right: 30px;
            }
            input {
                border: $borderWidthS solid $colorGrey700;
                border-radius: $borderRadiusS;
                padding: 3px 8px;
                font-size: 1rem;
                line-height: 1.5rem;
                margin-top: 0;
                margin-right: 14px;
                &::placeholder {
                    color: $colorGrey700;
                    font-size: 14px;
                    line-height: 22px;
                }
                &:focus {
                    background: $colorGrey200;
                    border: $borderWidthS solid $colorGrey700;
                    color: $colorGrey1000;
                    outline: none;
                }
            }
        }
        &__reset-styles {
            border:1px solid $colorPrimary500;
            border-radius: $borderRadiusM;
            padding: 9px 20px;
            line-height: 1;
            &:hover {
                background: $colorPrimary200;
            }
            &:focus {
                background: $colorPrimary300;
            }
        }   

        .style-select-boxes__value {
            margin: 5px 0;
        }
        .search-results {
            color: $colorGrey1000;
            background: $mainBackColor;
            padding: 5px;
            border-radius: $borderRadiusL;
            box-shadow: $boxShadowM;
            &__load-more {
                border:1px solid $colorPrimary500;
                border-radius: $borderRadiusM;
                color: $colorPrimary500;
                font-weight: 600;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 9px 20px;
                &:hover {
                    color: $colorPrimary500;
                    border-color: $colorPrimary500;
                    background: $colorPrimary100;
                }
                &:focus {
                    color: $colorPrimary600;
                    border-color: $colorPrimary600;
                    background: $colorPrimary200;
                }
            }
            &-preview {
                border-radius: $borderRadiusM;
                overflow: hidden;
                padding-left: 10px;
                padding-right: 10px;
            }
            .search-preview__image {
                border-radius: $borderRadiusM;
                overflow: hidden;
            }
            &__title {
                font-weight: 700;
                font-size: 16px;
                overflow: visible;
                margin:0 25px 0 15px;
            }
            &__model {
                font-size: 15px;
            }
            &__header {
                align-items: center;
                padding:10px 5px 0;
            }
        }
        &-matrix {
            &__table {
                th {
                    font-family: get("content.light.font.family", "0"), 'WPP Regular', sans-serif;
                }
                .ivu-input {
                    &:focus {
                        background: none;
                    }
                }
                .model-select-button {
                    width: 100%;
                    flex: 0 0 100%;
                    padding: 0;
                }
                .select-model {
                    background: #f3f4f6;
                }
                .imagine-matrix__new-cell {
                    color: $colorGrey800;
                }
            }
            &__body {
                position: fixed;
                top: 156px;
                width: 100%;
                max-width: calc(100vw - 64px);
                max-height: calc(100vh - 172px);
            }
            &__loading-dimensions {
                display: flex;
                align-items: center;
                border-radius: $borderRadiusM;
                padding: 12px;
                .alert {
                    &__icon-container--info {
                        margin: 0 8px 0 0;
                        padding:0;
                    }
                    &__content > h3 {
                        margin-bottom: 0;
                    }
                }

            }
        }
    }
    .add-account-modal__info {
        span {
            &.link-type {
                color: $colorPrimary500;
            }
        }
    }
}
@import "dark.theme";
