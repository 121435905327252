
@import "@/../sass/_variables.scss";

.team__invite-form {
    margin: 0 0 $spacing-large;
}

.team_invite-input-wrapper {
    display: flex;
    margin: $spacing-small 0 0;
}

.team_invite-input {
    flex: 1;
    margin: 0 $spacing-small 0 0;
}

.team__loading-container {
    position: relative;
    height: 300px;
}
