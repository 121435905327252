
/**** DARK THEME ****/
html:has( > body > .wpp-theme-dark) {
    background: $secondaryBackColor !important;
}
body:has( > .wpp-theme-dark) {
    background: $secondaryBackColor !important;
}
.app-wrapper.wpp-theme-dark {
    background: $secondaryBackColor !important;
    padding-top: 51px;
    #app {
        background: $secondaryBackColor !important;
    }
    ~ div {
        .add-templates-modal {
            .templates-modal__sidebar {
                background-color: $secondaryBackColor;
            }
        }
        .ivu-modal {
            &-mask {
                background: rgba(2, 2, 2, 0.8);
            }
            &-content {
                background-color: $secondaryBackColor;
            }
            &-header {
                p,
                &-inner {
                    color: $mainFontColor;
                }
                background-color: $secondaryBackColor;
                border-color: $colorGrey800;
                color: $mainFontColor;
            }
            &-body {
                color: $colorGrey400;
            }
            &-footer {
                border-color: $colorGrey800;
                .ivu-btn {            
                    border:1px solid $colorPrimary300;
                    color: $colorPrimary300 !important;
                    background: $mainBackColorDark !important;
                    &:hover {
                        background: $secondaryBackColor !important;
                        border-color: $colorPrimary300;
                        color: $colorPrimary200 !important;
                    }
                    &:focus {
                        background: $secondaryBackColor;
                        border-color: $colorPrimary300;
                        color: $colorPrimary100 !important;
                    }
                    &.edit-client-button__delete { 
                        border-color: $colorDanger400;
                        color: $colorDanger400 !important;
                        &:hover {
                            border-color: $colorDanger500;
                            color: $colorDanger500 !important;
                        }
                    }
                }
            }
            .ivu-input {
                background-color: $colorGrey1000;
                border-color: $colorGrey900;
            }
            .image-upload {
                &__uploader {
                    background-color: $colorGrey1000;
                }
                &--image {
                    border-color: $secondaryBackColor;
                }
                .ivu-upload-drag {
                    background-color: $colorGrey1000;
                    border-color: $colorGrey500;
                }
            }
            .ivu-collapse {
                background-color: $secondaryBackColor;
                &-content {
                    background-color: $secondaryBackColor;
                    color: $colorGrey300;
                    .editable-image-thumbnail__footer {
                        background: $secondaryBackColor;
                        color: $colorGrey300;
                    }
                    .grid-view {
                        background-color: $secondaryBackColor;
                    }
                }
                > .ivu-collapse-item {
                    border-color: $secondaryBackColor;
                    background-color: $secondaryBackColor;
                    .ivu-collapse-header {
                        color: $colorGrey300;
                        background-color: $secondaryBackColor;
                        border-bottom:1px solid  $colorGrey700;
                    }
                    &.ivu-collapse-item-active {
                        .ivu-collapse-header {
                            background-color: $secondaryBackColor !important;
                            color: $mainFontColor;
                        }
                    }
                }
            }
            .deliverable-value-item {
                background-color: $secondaryBackColor;
                color: $colorGrey300;
                .visible-row {
                    border-color: $colorGrey700;
                }
                .visible-row__eye,
                .lockable-row__padlock {
                    color: $colorGrey300;
                    border-color: $colorGrey700;
                }
            }

            .ivu-page-item,
            .ivu-page-prev,
            .ivu-page-next {
                background-color: $secondaryBackColor;
                border-color: $colorGrey700;
                &:hover {
                    border-color: $colorGrey500;
                }
                &-active {
                    background-color: $mainBackColorDark;
                    border-color: $colorGrey500;
                    a {
                        color: $colorGrey100;
                    }
                }
            }
            .master-template-library {
                .ivu-select-single {
                    .ivu-select-selection {
                        background-color: $secondaryBackColor;
                        color: $colorGrey300;
                    }
                }
                .multiselect-view-control {
                    .ivu-btn {
                        background-color: $secondaryBackColor;
                        color: $colorGrey300;
                        border-color: $colorGrey300;

                        &.active {
                            background-color: $secondaryBackColor;
                            color: $colorPrimary400;
                            border-color: $colorPrimary400;
                        }
                    }
                }
            }
        }
        .ivu-form {
            .ivu-form-item-label {
                color: $colorGrey300;
            }
        }
        .detachable-panel {
            .editables-wrapper {
                &::-webkit-scrollbar-track {
                    background-color: $colorGrey1000;
                }
            }
        }
        .ivu-tag {
            background-color: $mainBackColorDark;
            border-color: $colorGrey700;
            &-text {
                color: $colorGrey300;
            }
            .ivu-icon-ios-close {
                color: $colorGrey300;
            }
        }
        .ivu-switch-checked {
            border-color: $colorPrimary500;
            background-color: $colorPrimary500;
        }
        .ivu-table {
            border-color: $colorGrey700;
            &:before,
            &:after {
                background-color: $colorGrey700;
            }
            th {
                background-color: $mainBackColorDark;
                color: $mainFontColor;
                border-color: $colorGrey700;
            }
            td {
                background-color: $secondaryBackColor;
                color: $colorGrey300;
                border-color: $colorGrey700;
            }
            &-wrapper {
                border-color: $colorGrey700;
            }
        }
        .language-modal__aid {
            background-color: $mainBackColorDark;
            color: $colorGrey300;
        }
    }
    ~ .ivu-poptip-popper {
        .ivu-poptip-inner {
            .editor-banner-option {
                color: $colorGrey300;
                &:hover {
                    background-color: $secondaryBackColor;
                    color: $colorPrimary300;
                }
            }
        }
        .ivu-poptip-body-content {
            background-color: $secondaryBackColor;
        }
    }
    ~ .ivu-select-dropdown {
        background-color: $mainBackColorDark;
        li {
            color: $colorGrey300;
            &:hover {
                background: $colorGrey1000;
            }
        }
        .ivu-tree-title {
            color: $colorGrey300;
            &:hover {
                background-color: $secondaryBackColor;
            }
        }
        .ivu-input {    
            background: $colorGrey1000;
            border:1px solid $colorGrey900;
            color: $colorGrey300;
        }
        .ivu-dropdown-item-divided { 
            &:before {
                background-color: $secondaryBackColor;
            }
        }
    }
    ~ .ivu-poptip-popper {
        .master-template-banner-option {
            &:hover {
                background-color: $secondaryBackColor;
                color: $mainFontColor;
            }
        }
        .ivu-poptip-arrow {
            &:after {
                border-bottom-color: $secondaryBackColor;
            }
        }
    }
    ~ .ivu-message {
        .ivu-message-notice-content {
            background-color: $mainBackColorDark !important;
            color: $mainFontColor;
        }
    }
    .selectall-notice {
        background: $secondaryBackColor;
        color: $colorGrey300;
    }
    .share-preview-modal {
        &__show-all-deliverables-container {
            background: $mainBackColorDark;
            box-shadow: 0 -10px 20px $mainBackColorDark;
        }
    }
    .analytics-nav {
        color: $mainFontColor;
        li {
            a {
                color: $colorGrey300;
            }
        }
    }
    .square-button--default {
        &.square-button--active {
            background-color: $secondaryBackColor;
        }
    }
    .hox-modal {
        background-color: $secondaryBackColor;
        &__wrapper {
            background: rgba(2, 2, 2, 0.8);
        }
        &__header-container {
            color: $mainFontColor;
        }
        &__body {
            color: $colorGrey300;
        }
        .ivu-select,
        .ivu-input {
            background: $colorGrey1000;
            border-color: $colorGrey900;
            color: $colorGrey300;
        }
        .hox-input__input {
            background: $colorGrey1000;
            border-color: $colorGrey900;
            color: $colorGrey300;
        }
        .teamMemberScope {
            border-color: $colorPrimary300;
            .ivu-tag-text {
                color: $colorPrimary300 !important;
            }
        }
    }
    .hox-input__label {
        color: $colorGrey300;
    }
    .ivu-modal {
        &-header {
            p,
            &-inner {
                color: $mainFontColor;
            }
            border-color: $colorGrey800;
        }
        &-footer {
            border-color: $colorGrey800;
        }
        &-content {
            background-color: $mainBackColor;
            color: $colorGrey300;
        }
        &-mask {
            background: rgba(2, 2, 2, 0.8);      
        }
    }
    .ivu-form {
        .ivu-form-item-label {
            color: $colorGrey300;
        }
    }
    .ivu-input {
        background-color: $colorGrey1000;
        border-color: $colorGrey900;
        color: $mainFontColor;
    }
    .hox-drawer {
        &__header,
        &__body {
            background: $secondaryBackColor;
        }
        .ivu-drawer-header {
            border-color: $colorGrey700;
        color: $colorGrey300;
        }
    }
    .qa {
        &-creative__body {
            background: $secondaryBackColor;
        }
        &-annotations__annotations {
            background: $secondaryBackColor;
        }
        &-drawer-sidebar__header {
            color: $colorGrey300;
        }
        &-drawer__sidebar {
            border-color: $colorGrey700;
        }
        &-annotations__no-annotations-message {
            background: $secondaryBackColor;
            border-color: $colorGrey700;
        }
        &-banner__title {
            color: $colorGrey300;
        }
        &-banner__footer {
            background-color: $secondaryBackColor;
        }
    }
    &.wpp-theme--home {
        .hoxton-footer {
            background: $secondaryBackColor;
        }
    }
    .ivu-menu-light,
    .hoxton-footer,
    .top-nav {
        background: $secondaryBackColor;
        &:before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 52px;
            background: $secondaryBackColor;
            position: absolute;
            top: -52px;
            left: 0;
            z-index: -1;
            border-bottom: 1px solid $colorGrey700;
        }
    }
    .top-nav {
        border-bottom: 1px solid $colorGrey700;
        .dropdown-selection--section {
            color: $mainFontColor;
        }
        .ivu-menu-light {
            &.ivu-menu-horizontal {
                .ivu-menu-item {
                    background-color: $secondaryBackColor;
                    color: $mainFontColor;
                    &:hover,
                    &.ivu-menu-item-selected  {
                        background-color: $mainBackColor;
                        color: $mainFontColor;
                    }
                }
            }
        }
    }
    .footer-nav {
        &__links {
            a {
                color: $mainFontColor;
            }
        }
    }
    .main-nav-button {
        &__background {
            background: none !important;
        }
    }
    .hoxton-footer {
        height: 51px;
    }
    .ivu-menu-light {
        &:before {
            top: -61px;
        }
    }
    .vb-dragger {
        background-color: $colorPrimary300;
    }
    .section-nav {
        color: $mainFontColor;
        li {
            &:hover {
                background-color: $mainBackColor;
            }
            a {
                color: $mainFontColor;;
            }
        }
    }
    .navigation-tab {
        color: $colorGrey300;
    }
    .image-upload {
        &__uploader {
            background-color: $colorGrey1000;
        }
        .ivu-upload-drag {
            background-color: $colorGrey1000;
            border-color: $colorGrey500;
        }
    }
    .ivu-upload-drag {
        background-color: $colorGrey1000;
        border-color: $colorGrey500;
    }
    .ivu-card {
        background-color: $secondaryBackColor;
    }
    .ivu-alert {
        &-warning {
            border-color: $colorWarning400;
            background-color: $secondaryBackColor;
        }
        &-message {
            color: $colorWarning400;
        }
        &-desc {
            color: $colorGrey300;
        }
        &-error {
            background-color: $secondaryBackColor;
        }
    }
    .alert {
        background-color: $secondaryBackColor;
    }
    #media-library {
        .media-library {
            &__data-row {
                .ivu-card {
                    background-color: $mainBackColor;
                }
            }
            
            &__data-card {
                &-name {
                    border-color: $colorGrey700;
                }
                .ivu-btn-error {
                    background-color: $mainBackColor;
                    &:hover {
                        background-color: $secondaryBackColor;
                    }
                }
            }
            &__action-tabs {
                span {
                    color: $mainFontColor;   
                }
            }
            &__imagine-results {
                background: $mainBackColor;
                border-color: $mainBackColor;
            }
        }
    }
    .notification {
        &-settings-group {
            h3 {
                color: $mainFontColor;
            }
        }
        &-label {
            color: $colorGrey300;
        }
    }
    .campaigns {
        background: $mainBackColor;
    }
    .ivu-layout-sider,
    .ivu-collapse.main-content,
    .ivu-collapse-content,
    .main-content.main-content {
        background: $mainBackColor;
    }
    .ivu-collapse {
        background: $secondaryBackColor;
        &-content {
            background: $secondaryBackColor;
            color: $mainFontColor;
        }
        > .ivu-collapse-item {
            &.ivu-collapse-item-active {
                border-color: $secondaryBackColor;
                .ivu-collapse-header {
                    background: $secondaryBackColor;
                    &:hover {
                        background: $secondaryBackColor;
                    }
                }
            }
            .ivu-collapse-header {
                background: $secondaryBackColor;
                border-color: $mainFontColor;
                color: $mainFontColor;
            }
        }
    }
    .ivu-tag {
        background: $secondaryBackColor;
        &-border {
            background: $secondaryBackColor !important;
        }
        &-text {
            color: $mainFontColor;
        }
    }
    .ivu-table {
        border-color: $colorGrey700;
        &:before,
        &:after {
            background-color: $colorGrey700;
        }
        th {
            background-color: $mainBackColor;
            color: $mainFontColor;
            border-color: $colorGrey700;
        }
        td {
            background-color: $secondaryBackColor;
            color: $colorGrey300;
            border-color: $colorGrey700;
        }
        &-wrapper {
            border-color: $colorGrey700;
        }
    }
    .ivu-poptip {
        &-inner {
            background-color: $mainBackColorDark;
            .ivu-poptip-body-content{
                background-color: $mainBackColorDark;
            }
        }
        &-arrow {
            &:after {
                border-left-color: $mainBackColor;
            }
        }
    }
    .ivu-drawer-content {
        background-color: $secondaryBackColor;
    }
    .ivu-layout {
        background-color: $mainBackColor;
        &-sider {
            .sidebar-body {
                background: $secondaryBackColor;
            }
        }
        .campaigns__action-items {
            .ivu-btn {
                height: 37px;
                margin-top: 3px;
                background: $secondaryBackColor;
                color: $colorPrimary300 !important;
                &:after,
                &:before {
                    background: $colorPrimary300 !important;;
                }
                &:hover {
                    background: $mainBackColor;
                }
            }
        }
        .clients {
            &__tabs {
                span {
                    color: $mainFontColor;
                    background-color: $mainBackColor;
                    &.clients__tabs--sandbox {
                        background-color: $secondaryBackColor;
                        &:hover {
                            background-color: $mainBackColor;
                            
                        }
                    }
                }
            }
            &__action-items {
                padding-left: 28px;
                padding-right: 28px;
                margin-left: -28px;
                margin-right: -28px;
                background-color: $secondaryBackColor;
                border-bottom: 1px solid $colorGrey700;
                border-top: 1px solid $colorGrey700;
                .ivu-btn-primary {
                    &:hover {
                        background: $mainBackColor;
                    }
                }
            }
        }
    }
    .table-header-column {
        background-color: $mainBackColor;
        color: $mainFontColor;
    }
    .table-row {
        &:nth-child(2n) {
            td {
                background-color: $secondaryBackColor;
                color: $colorGrey500;
            }
        }
        td {
            background-color: $mainBackColor;
            color: $colorGrey500;
        }
    }
    .table-header-column,
    .table-header-column--sticky-right,
    .table-header-column--sticky-left,
    .table-row-column--sticky-left,
    .table-row-column--sticky-right {
        &:after {
            border-color: $colorGrey900;
        }
    }
    .ivu-page-item,
    .ivu-page-prev,
    .ivu-page-next {
        background-color: $secondaryBackColor;
        border-color: $colorGrey700;
        &:hover {
            border-color: $colorGrey500;
        }
        &-active {
            background-color: $mainBackColor;
            border-color: $colorGrey500;
        }
    }
    .ivu-dropdown {
        &-item-divided {
            &:before {
                background: $secondaryBackColor;
            }
        }
        .ivu-select-dropdown {
            li {
                color: $colorGrey100;
                background: $mainBackColor;
                &:hover {
                    background: $secondaryBackColor;
                }
            }
        }
    }
    .ivu-select-dropdown {
        background: $mainBackColor;
        .ivu-select-item {
            background: $mainBackColor;
            &:hover {
                background-color: $secondaryBackColor;
            }
        }
    }
    .ivu-switch {
        background-color: $secondaryBackColor;
        border-color: $colorPrimary400;
        &:after {
            background-color: $colorPrimary400;
        }
        &-checked {
            border-color: $colorPrimary400;
            &:after {
                background-color: $colorPrimary300;
            }
        }
    }
    .ivu-switch-checked {
        background-color: $colorPrimary400;
    }
    .ivu-checkbox-checked {
        .ivu-checkbox-inner {
            background-color: $secondaryBackColor;
        }
    }
    .ivu-checkbox-inner,
    .ivu-switch-checked {
        background-color: $secondaryBackColor;
    }
    .ivu-select {
        border-color: $colorPrimary300;
        color: $colorPrimary300 !important;
        background: $mainBackColor;
    }
    .ivu-btn {
        border:1px solid $colorPrimary300;
        color: $colorPrimary300 !important;
        background: $mainBackColor;
        &:hover {
            background: $secondaryBackColor;
            border-color: $colorPrimary300;
            color: $colorPrimary200 !important;
        }
        &:focus {
            background: $secondaryBackColor;
            border-color: $colorPrimary300;
            color: $colorPrimary100 !important;
        }
        &.hidden {
            display: none !important;
        }
        &-error {
            border-color: $colorDanger400;
            color: $colorDanger400 !important;
            &:hover {
                border-color: $colorDanger500;
                color: $colorDanger500 !important;
            }
        }
        &-warning {
            background-color: $colorWarning500;
            color: #fff;
            border-color:$colorWarning500;
            &:hover {
                background-color: $colorWarning400;
                border-color:$colorWarning500;
                color: #fff;
            }
        }
        &-group:not(.ivu-btn-group-vertical) {
            border-color: $colorGrey700;
            .ivu-btn,
            .ivu-btn-primary:last-child:not(:first-child) {
                border-color: $colorGrey700;
            }
        }
    }
    .ivu-btn[disabled] {
        border-color: $colorPrimary300;
        color: $colorPrimary300 !important;
        background: $mainBackColor;
        opacity: 0.5;
    }
    .ivu-radio-group {
        &-button {
            .ivu-radio-wrapper-checked,
            .ivu-radio-wrapper {
                background: $mainBackColor;
                border-color: $colorGrey700;
                &:hover {
                    background: $secondaryBackColor;

                }
            }
        }
        &-item {
            .ivu-radio-checked {
                .ivu-radio-inner {
                    &:after {
                        background: $colorPrimary400;
                    }
                }
            }
        }
    }
    .detachable-panel {
        .ivu-modal-content {
            background-color: $secondaryBackColor;

        }
    }
    .editable-widgets {
        .last-interaction {
            &.ivu-collapse {
                > .ivu-collapse-item {
                    &.ivu-collapse-item-active {
                        .ivu-collapse-header {
                            background: $secondaryBackColor !important;
                        }
                    }
                }
            }
        }
    }
    .empty-message__title {
        color: $colorGrey300;
    }
    .notifications-drawer {
        &__header {
            border-color: $colorGrey700;
        }
        .empty-message__title {
            color: $colorGrey300;
        }
    }
    .theme-campaign-light {
        .campaign-banner {
            border-color: $colorGrey700;
            box-shadow: none;
            &-footer {
                background-color: $secondaryBackColor !important;
            }
        }
    }
    .campaign-banner {
        &-footer {
            background-color: $secondaryBackColor;
        }
    }
    .campaign-light__content-header,
    .campaign-header {
        background-color: $secondaryBackColor;
        border-color: 1px solid $mainBackColor;
        &__breadcrumb {
            a {
                color: $mainFontColor;
                &:hover {
                    color: $colorPrimary300;
                }
            }
        }
    }
    .campaign-edit__header,
    .campaign-header__nav {
        background-color: $secondaryBackColor;
        border-color: 1px solid $mainBackColor;
    }
    .campaign {
        &-header {
            height: 51px;
            &__breadcrumb {
                .router-link-exact-active {
                    color: $mainFontColor;
                }
            }
        }
        &-layout {
            .deliverable-label {
                background-color: $colorGrey1000;
                border-color: $colorGrey900;
                color: $mainFontColor;
            }
            .deliverable-value-item  {
                color: $colorGrey600;
            }
            .sidebar-panes {
                .ivu-select,
                .ivu-input {
                    background-color: $colorGrey1000;
                    border-color: $colorGrey900;
                    color: $mainFontColor;
                }
                .ivu-input-group-with-append {
                    &.ivu-input-group-small {
                        .ivu-input {
                            border-color: $colorGrey900;

                        }
                    }
                }
                .editable-group {
                    &--editing {
                        .ivu-collapse {
                            > .ivu-collapse-item {
                                &.ivu-collapse-item-active {
                                    .ivu-collapse-header {
                                        background: $secondaryBackColor;
                                    }
                                }
                            }
                        }
                    }
                    &__body {
                        .ivu-radio-wrapper {
                            &:hover,
                            &.ivu-radio-wrapper-checked {
                                background-color: $colorGrey1000;
                            }
                        }
                    }
                    &__add {
                        background-color: $colorGrey1000;
                    }
                    &__value-actions {
                        background-color: $secondaryBackColor;
                        padding: 0 3px;
                    }
                    .ivu-collapse-content-box {
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
            }
            .sidebar-panel {
                background: $secondaryBackColor;
                .editables-controls {
                    .campaign-edit__header-action-btn {
                        background: none;
                    }
                }
            }
            .deliverable-value-item {
                background: $secondaryBackColor;
            }
            .expandable-settings {
                &-pane {
                    svg {
                        fill: $colorGrey300;
                    }
                }
            }
            .editable-settings {
                &__input {
                    .ivu-input-group-append {
                        background: $colorGrey900;
                        border-color: $colorGrey900;
                        color: $colorGrey600;
                    }
                }
                &-control__button {
                    color: $colorGrey300;
                }
            }
            .campaign-library {
                &__variations {
                    border-color: $colorGrey700;
                }
                &__sidebar-nav {
                    .navigation-tab {
                        color: $mainFontColor;
                        &:hover,
                        &--active {
                            color: $mainBackColor;
                        }
                    }
                }
                &__footer {
                    border-color: $colorGrey700;
                }
                .ivu-dropdown {
                    .ivu-btn {
                        border-color: $colorPrimary300;
                        color: $colorPrimary300 !important;
                        &:hover {
                            background: $secondaryBackColor;
                            border-color: $colorPrimary300;
                            color: $colorPrimary200 !important;
                        }
                    }
                }
                .library-header__select-all {
                    color: $mainFontColor;
                }
            }
            .lockable-row__padlock,
            .visible-row__eye {
                background-color: $colorGrey1000;
                color: $colorGrey600;
            }
        }
        &-edit {
            &__header-action {
                &-select {
                    .ivu-select-selection,
                    .ivu-select-dropdown {
                        color: $colorPrimary300;
                        &-list {
                            background-color: $mainBackColor;
                            color: $colorGrey300;
                        }
                    }
                    .ivu-select-dropdown {
                        .ivu-select-item {
                            color: $colorGrey500;
                            &.ivu-select-item-focus {
                                background-color: $secondaryBackColor;
                                color: $colorGrey100;
                            }
                        }
                    }
                }
                &-btn {
                    border:1px solid $colorPrimary300;
                    &:hover {
                        background-color: $secondaryBackColor;
                    }
                }
            }
            .save-changes {
                .ivu-btn {
                    border-color: $colorPrimary300;
                    color: $colorPrimary300 !important;
                    background: $mainBackColor;
                    &:hover {
                        background: $secondaryBackColor;
                        border-color: $colorPrimary300;
                        color: $colorPrimary200 !important;
                    }
                    &:focus {
                        background: $secondaryBackColor;
                        border-color: $colorPrimary300;
                        color: $colorPrimary100 !important;
                    }
                }
            }
        }
        &-light__sidebar {
            .collapsible-widget {
                .ivu-checkbox-wrapper {
                    &:hover {
                        background: $secondaryBackColor;
                    }
                }
            }
        }
    }
    .ivu-slider-bar {
        background-color: $colorPrimary400;
    }
    .campaign-edit__banner-controls {
        background-color: $secondaryBackColor;
    }
    .card {
        &__footer {
            border-color: $mainBackColor;
            &-title {
                color: $mainFontColor;
            }
            &-action-items {
                .card-footer-button {
                    &:hover {
                        background-color: transparent !important;
                        color: $colorPrimary200;
                    }
                }
            }
            .ivu-dropdown-rel {
                background-color: none;
                color: $colorPrimary200;

            }
        }
        &__container--clickable {
            background-color: $secondaryBackColor;
        }
        &-footer-button {
            &:hover {
                background-color: none;
                color: $colorPrimary200;
            }
        }
    }
    .library-banner {
        &__footer,
        &__scaling-controls {
            background-color: $mainBackColor;
            color: $colorGrey300;
        }
        &__title {
            color: $colorGrey300;
        }
    }
    .master-templates {
        &__filters {
            background: $secondaryBackColor;
            .ivu-collapse {
                > .ivu-collapse-item {
                    &.ivu-collapse-item-active {
                        .ivu-collapse-header {
                            background: $secondaryBackColor;
                        }
                    }
                }
            }
            .ivu-collapse-content {
                background: $secondaryBackColor;
            }
        }
    }
    .master-template {
        &-library__header {
            background: $secondaryBackColor;
            border-color: $colorGrey700;

            .ivu-input {
                border-color: $colorPrimary300;
                color: $colorPrimary300;
            }
        }
        &-select {
            .master-template-select__footer {
                border-color: $colorGrey700;
            }
        }
    }
    .add-template-to-campaign {
        background-color: $secondaryBackColor;
        &__action {
            background: $mainBackColor;
            color: $colorGrey100;
        }
        h2 {
            color: $mainFontColor;
        }
    }
    /**** IMAGINE ***/
    .imagine {
        background-color: $mainBackColor;
        &-header {
            background-color: $secondaryBackColor;
            color: $mainFontColor;
            border-color: $colorGrey700;
            .imagine-navigation {
                a {
                    color: $colorGrey300;
                }
            }
        }
        &__no-data {
            color: $colorGrey300;
            &-warning {
                background-color: $secondaryBackColor;
                margin-top: 20px;
                border-radius: $borderRadiusM;
            }
        }
        &__sidebar {
            color: $colorGrey300;
            .ivu-collapse {
                > .ivu-collapse-item {
                    > .ivu-collapse-header {
                        color: $mainFontColor;
                    }
                    &.ivu-collapse-item-active {
                        .ivu-collapse-header {
                            background-color: $secondaryBackColor;
                        }
                    }
                }       
            }
            .ivu-select,
            .ivu-input {
                background-color: $colorGrey1000;
                border-color: $colorGrey900;
                color: $mainFontColor;
            }
            .ivu-select {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
        &__tips-header-title { 
            color: $mainFontColor;
        }
        &-prompt {
            input {
                border-color: $colorPrimary300;
                background-color: $mainBackColor;
                color: $mainFontColor;
                &:focus {
                    background-color: $secondaryBackColor;
                    color: $mainFontColor;
                    border-color: $colorPrimary300;
                }
            }
            .ivu-dropdown {
                color: $mainFontColor;
            }
        }
        &-matrix__table {
            th {
                background-color: $secondaryBackColor;
                border-color: $mainBackColor;
            }
            td {
                background-color: $mainBackColor;
                border-color: $secondaryBackColor;
            }
            .select-model {
                background-color: $mainBackColor;
                border-color: $mainBackColor;
            }
            .imagine-matrix__new-cell {
                background-color: $secondaryBackColor;
                border-color: $secondaryBackColor;
                color: $colorGrey300;
            }
        }
        &-preview__result-loading {
            border-color: $secondaryBackColor;
        }
        .search-results {
            background-color: $secondaryBackColor;
            &__header {
                color: $mainFontColor;
            }
            &-preview {
                background-color: $secondaryBackColor;
            }
            &__load-more {
                background-color: $mainBackColor;
                color: $colorPrimary300;
                border-color: $colorPrimary300;
                &:hover {
                    color: $colorPrimary300;
                    border-color: $colorPrimary300;
                    background-color: $secondaryBackColor;
                }
            }
        }
    }
    .search-preview {
        &__Loading {
            .search-preview__image {
                background-color: $mainBackColor;
            }
        }
    }
    .creative-insights {
        &-nav {
            background-color: $secondaryBackColor;
            border-color: $colorGrey700;
        }
        &__tags-slider {
            .ivu-layout-sider-children {
                background-color: $secondaryBackColor;
            }
        }
        &-header {
            background-color: $secondaryBackColor;
            &.creative-insights-header,
            &.creative-insights-header--sidebar-closed {
                border-bottom: 1px solid $colorGrey700;
            }
            &__date-picker {
                .ivu-btn,
                .ivu-input {
                    border-color: $colorPrimary300 !important;
                    color: $colorPrimary300;
                    &:focus {
                        background-color: $mainBackColorDark !important;
                    }
                }
            }
            &__item {
                color: $colorPrimary300;
            }
        }
    }
    .insight {
        &-preview__panel-name {
            h4 {
                color: $mainFontColor;
            }
        }
    }
    .insights {
        &__back-link {
            color: $mainFontColor;
        }
        &-tags {
            background-color: $secondaryBackColor;
            &__marketplace {
                h3 {
                    color: $colorGrey300;
                }
                .ivu-btn {
                    border:1px solid $colorPrimary300;
                    color: $colorPrimary300 !important;
                    background: $mainBackColor;
                    &:hover {
                        background: $secondaryBackColor;
                        border-color: $colorPrimary300;
                        color: $colorPrimary200 !important;
                    }
                    &:focus {
                        background: $secondaryBackColor;
                        border-color: $colorPrimary300;
                        color: $colorPrimary100 !important;
                    }

                }
            }
            .pane-handle {
                color: $colorPrimary300;
                background-color: $secondaryBackColor;
            }
        }
        &-creative-preview {
            &__footer {
                color: $colorGrey300;
            }
        }
        &-dashboard {
            &-tag-component__layout-header {
                color: $mainFontColor;
                &-name {
                    color: $mainFontColor;
                }
            }
            &__marketplace {
                h3 {
                    color: $mainFontColor;
                }
                .ivu-btn {
                    background-color: $colorPrimary400;
                }
            }

            &-tag-components__draggable-wrapper {
                .ivu-collapse-content,
                .ivu-collapse {
                    background: $mainBackColorDark;
                    border-color: $secondaryBackColor;
                    > .ivu-collapse-item {
                        &.ivu-collapse-item-active {
                            background: $mainBackColorDark;
                            border: 0;
                            .ivu-collapse-header {
                                background: $mainBackColorDark;
                            }
                        }
                        .ivu-collapse-header {
                            background: $mainBackColorDark;
                        }
                    }
                }
            }
        }
        .dashboard-card {
            background-color: $mainBackColor;
        }
    }
    .marketplace {
        &-category {
            background: $mainBackColor;
        }
        &-item {
            border-color: $colorGrey700;
            h4 {
                color: $colorGrey300;
            }
        }
    }
    .ci-tag {
        color: $colorGrey300;
        &--selected {
            background-color: $mainBackColor;
            color: $colorPrimary300;
            box-shadow: inset -3px 0px 0px $colorPrimary300;
        }
    }
    .kpi-matrix {
        &__sticky {
            background-color: $secondaryBackColor;
        }
        &__table {
            tbody {
                td {
                    background-color: $secondaryBackColor;
                    box-shadow: inset 0px 1px 0 $colorGrey700, inset 1px 0px 0 $colorGrey700;
                }
            }
        }
    }
    .dashboard {
        &-content {
            &-dropdown {
                color: $colorGrey300;
                &__type-value {
                    color: $mainFontColor;
                }
            }
        }
    }
    .advanced-search {
        &__type {
            .ivu-dropdown-rel {
                a {
                    border-color: $colorPrimary300;
                    i {
                        &:before {
                            color: $colorPrimary300;
                        }
                    }
                }
                span {
                    color: $colorPrimary300;
                }
            }
        }
        &__icon {
            &:before {
                color: $colorPrimary300;
            }
        }
        input {
            background-color: $mainBackColorDark;
            border-color: $colorPrimary300;
            color: $colorPrimary300;
            &:focus {
                background-color: $secondaryBackColor;
                color: $mainFontColor;
                border-color: $colorPrimary300 !important;
            }
        }
        .advanced-search__button {                   
            border:1px solid $colorPrimary300 !important;
            color: $colorPrimary300 !important;
            background: $mainBackColorDark !important;
            &:hover {
                background: $secondaryBackColor !important;
                border-color: $colorPrimary300 !important;
                color: $colorPrimary200 !important;
            }
            &:focus {
                background: $secondaryBackColor;
                border-color: $colorPrimary300;
                color: $colorPrimary100 !important;
            }
        }
    }
    .loading-layer--theme-light {
        background: rgba(2, 2, 2, 0.8);
    }
    .user-permission-modal-table {
        .ivu-table {
            padding: 10px;
            background-color: $secondaryBackColor;
            border-color: $secondaryBackColor;
            &:before,
            &:after {
                display: none;
            }
            td,
            th {
                border-color: $secondaryBackColor;
                background-color: $secondaryBackColor;
            }
        }
        .new-entry-row {
            td {
                &.bordered-column {
                    > .ivu-table-cell {
                        background-color: $secondaryBackColor;
                    }
                }
            }
        }
    }
    .expandable-text-input__expanded_text_edit {
        i {
            color: $mainFontColor;
        }
    }
    .generate-cta-modal {
        &__content {
            .search-results-preview {
                background: $secondaryBackColor;
                h4 {
                    color: $colorPrimary200;
                }
            }
            .imagine-preview__result-loading {
                border: 1px solid $mainBackColor;
                padding: 0;
            }
        }
    }
    .font-card {
        background: $secondaryBackColor;
    }
}