
@import "../../../sass/variables";

.editor-banner-option {
    text-align: left;
    height: 33px;
    width: 100%;
    padding: 0 11px;
    line-height: 33px;
    cursor: pointer;
    color: $cmp-dark-font-color;

    span {
        padding-left: $spacing-small;
    }

    &:hover {
        background-color: $cmp-dark-secondary-bg-color;
    }

    .ivu-poptip-body {
        padding: 0;
        border-radius: 0;
    }
}
