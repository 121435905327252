
@import "../../../../sass/_variables.scss";
$bgColor: transparent !important;

.key-takeaway {
    width: 100%;
    &.ivu-collapse > .ivu-collapse-item {
        border: 1px solid transparent;

        .ivu-collapse-header {
            border: none;
            background: $bgColor;
            box-shadow: none !important;
        }

        &.ivu-collapse-item-active {
            border-bottom: 0px solid transparent !important;

            .ivu-collapse-header {
                background: $bgColor !important;
            }
        }
    }

    .ivu-collapse-content {
        background: $bgColor !important;
    }

    &.ivu-collapse {
        border: none;
        border-radius: 0;
        background-color: $bgColor;

        .ivu-collapse-content {
            background-color: $bgColor;
        }

        .ivu-collapse-content > .ivu-collapse-content-box {
            padding: 6px 0;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }

    &__panel {
        .ivu-collapse-header {
            height: 24px !important;
            line-height: 24px !important;
            padding: 0 !important;
            display: flex;
            width: 100%;
            flex: 1 0 100%;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: transparent;
            box-shadow: none;
            font-weight: normal;

            & > .ivu-icon {
                display: flex;
                margin-top: 0;
            }
        }
    }
}
