@tag-prefix-cls: ~"@{css-prefix}tag";
@tag-close-prefix-cls: ivu-icon-ios-close;

.@{tag-prefix-cls} {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    margin: 2px 4px 2px 0;
    padding: 0 8px;
    border: 1px solid @border-color-split;
    border-radius: @btn-border-radius-small;
    background: @background-color-base;
    font-size: @tag-font-size;
    vertical-align: middle;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;
    //transition: all @transition-time @ease-in-out;

    // for color and unchecked
    &:not(&-border):not(&-dot):not(&-checked){
        background: transparent;
        border: 0;
        color: @text-color;
        .@{tag-close-prefix-cls} {
            color: @text-color !important;
        }
    }

    &-color{
        &-error{
            color: @error-color !important;
            border-color: @error-color;
        }
        &-success{
            color: @success-color !important;
            border-color: @success-color;
        }
        &-primary{
            color: @link-color !important;
            border-color: @link-color;
        }
        &-warning{
            color: @warning-color !important;
            border-color: @warning-color;
        }
        &-white{
            color: rgb(255, 255, 255) !important;
        }
    }

    &-dot{
        height: 32px;
        line-height: 32px;
        border: 1px solid @border-color-split !important;
        color: @text-color !important;
        background: #fff !important;
        padding: 0 12px;

        &-inner{
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 8px;
            border-radius: 50%;
            background: @border-color-split;
            position: relative;
            top: 1px;
        }
        .@{tag-close-prefix-cls} {
            color: #666 !important;
            margin-left: 12px !important;
        }
    }

    &-border{
        height: 24px;
        line-height: 24px;
        border: 1px solid @border-color-split;
        color: @border-color-split;
        background: #fff !important;
        position: relative;

        .@{tag-close-prefix-cls} {
            color: #666;
            margin-left: 12px !important;
        }

        &:after{
            content: "";
            display: none;
            width: 1px;
            background: currentColor;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 22px;
        }

        &.@{tag-prefix-cls}-closable {
            &:after{
                display: block;
            }
            .@{tag-close-prefix-cls} {
                margin-left: 18px !important;
                left: 4px;
                top: -1px;
            }
        }

        &.@{tag-prefix-cls}-primary {
            color: @link-color !important;
            border: 1px solid @link-color !important;

            &:after{
                background: @link-color;
            }
            .@{tag-close-prefix-cls}{
                color: @link-color !important;
            }
        }
        &.@{tag-prefix-cls}-success {
            color: @success-color !important;
            border: 1px solid @success-color !important;

            &:after{
                background: @success-color;
            }
            .@{tag-close-prefix-cls}{
                color: @success-color !important;
            }
        }
        &.@{tag-prefix-cls}-warning {
            color: @warning-color !important;
            border: 1px solid @warning-color !important;

            &:after{
                background: @warning-color;
            }
            .@{tag-close-prefix-cls}{
                color: @warning-color !important;
            }
        }
        &.@{tag-prefix-cls}-error {
            color: @error-color !important;
            border: 1px solid @error-color !important;

            &:after{
                background: @error-color;
            }
            .@{tag-close-prefix-cls}{
                color: @error-color !important;
            }
        }
    }

    &:hover {
        opacity: 0.85;
    }

    &,
    a,
    a:hover {
        // color: @text-color;
    }

    &-text {
        a:first-child:last-child {
            display: inline-block;
            margin: 0 -8px;
            padding: 0 8px;
        }
        color: @text-color;
    }

    .@{tag-close-prefix-cls} {
        .iconfont-size-under-12px(20px);
        cursor: pointer;
        margin-left: 2px;
        color: #666;
        opacity: 0.66;
        position: relative;
        top: -1px;
        //transition: all @transition-time @ease-in-out;

        &:hover {
            opacity: 1;
        }
    }

    &-primary,
    &-success,
    &-warning,
    &-error {
        border: 0;
        &,
        a,
        a:hover,
        .@{tag-close-prefix-cls},
        .@{tag-close-prefix-cls}:hover {
            color: #fff;
        }
        //.@{tag-close-prefix-cls}{
        //    top: 2px;
        //}
    }

    &-primary,
    &-primary&-dot &-dot-inner
    {
        background: @link-color;
    }

    &-success,
    &-success&-dot &-dot-inner
    {
        background: @success-color;
    }

    &-warning,
    &-warning&-dot &-dot-inner
    {
        background: @warning-color;
    }

    &-error,
    &-error&-dot &-dot-inner
    {
        background: @error-color;
    }

    @colors: pink, magenta, red, volcano, orange, yellow, gold, cyan, lime, green, blue, geekblue, purple;

    .make-color-classes(@i: length(@colors)) when (@i > 0) {
        .make-color-classes(@i - 1);
        @color: extract(@colors, @i);
        @lightColor: "@{color}-1";
        @lightBorderColor: "@{color}-3";
        @darkColor: "@{color}-6";
        &-@{color} {
            line-height: 20px;
            background: @@lightColor;
            border-color: @@lightBorderColor;
            .@{tag-prefix-cls}-text{
                color: @@darkColor !important;
            }
            &.@{tag-prefix-cls}-dot{
                line-height: 32px;
            }
        }
    }

    .make-color-classes();
}