
@import "@/../sass/_variables.scss";

.navigation-tab {
    border-bottom: $border-width-thicker solid transparent;
    color: $darkgrey2;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-large;
    line-height: 1em;
    padding: $spacing $spacing-large ($spacing - $border-width-thicker);
    transition: border-color 0.2s ease-in-out;
    &:hover,
    &.navigation-tab--active {
        border-bottom-color: $primary-color;
    }
}

.navigation-tab--color-secondary {
    &:hover,
    &.navigation-tab--active {
        border-bottom-color: $fluogreen;
    }
}

.navigation-tab--color-tertiary {
    &:hover,
    &.navigation-tab--active {
        border-bottom-color: $cmp-light-active;
    }
}

.navigation-tab--size-small {
    border-bottom-width: $border-width-thick;
    font-size: $font-size-base;
    padding: $spacing-semi-small $spacing ($spacing-semi-small - $border-width-thick);
}
