.slide-motion(@className, @keyframeName) {
    .make-motion(@className, @keyframeName);
    .@{className}-enter-active, .@{className}-appear {
        opacity: 0;
        animation-timing-function: @ease-in-out;
    }
    .@{className}-leave-active {
        animation-timing-function: @ease-in-out;
    }
}

.slide-motion(transition-drop, ivuTransitionDrop);
.slide-motion(slide-up, ivuSlideUp);
.slide-motion(slide-down, ivuSlideDown);
.slide-motion(slide-left, ivuSlideLeft);
.slide-motion(slide-right, ivuSlideRight);

@keyframes ivuTransitionDropIn {
    0% {
        opacity: 0;
        transform: scaleY(0.8);
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}

@keyframes ivuTransitionDropOut {
    0% {
        opacity: 1;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform: scaleY(0.8);
    }
}

@keyframes ivuSlideUpIn {
    0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(.8);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
}

@keyframes ivuSlideUpOut {
    0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(.8);
    }
}

@keyframes ivuSlideDownIn {
    0% {
        opacity: 0;
        transform-origin: 100% 100%;
        transform: scaleY(.8);
    }
    100% {
        opacity: 1;
        transform-origin: 100% 100%;
        transform: scaleY(1);
    }
}

@keyframes ivuSlideDownOut {
    0% {
        opacity: 1;
        transform-origin: 100% 100%;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform-origin: 100% 100%;
        transform: scaleY(.8);
    }
}

@keyframes ivuSlideLeftIn {
    0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleX(.8);
    }
    100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleX(1);
    }
}

@keyframes ivuSlideLeftOut {
    0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleX(1);
    }
    100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleX(.8);
    }
}

@keyframes ivuSlideRightIn {
    0% {
        opacity: 0;
        transform-origin: 100% 0%;
        transform: scaleX(.8);
    }
    100% {
        opacity: 1;
        transform-origin: 100% 0%;
        transform: scaleX(1);
    }
}

@keyframes ivuSlideRightOut {
    0% {
        opacity: 1;
        transform-origin: 100% 0%;
        transform: scaleX(1);
    }
    100% {
        opacity: 0;
        transform-origin: 100% 0%;
        transform: scaleX(.8);
    }
}