/*
  An attempt to normalize the iview icons sizes
*/

$default-icon-fs: 20px;
$select-arrow-icon-fs: 14px;

$ios-grid-outline-fs: 24px;
$ios-grid-outline-weight: bold;

$ios-list-fs: 24px;
$ios-list-weight: bold;

$file-fs: 20px;
$file-weight: normal;

$ios-folder-outline-fs: 20px;
$ios-folder-outline-weight: normal;

$error-fs: 20px;
$error-weight: normal;

$ios-document-outline-fs: 20px;
$ios-document-outline-weight: normal;

$ios-folder-outline-fs: 20px;
$ios-folder-outline-weight: normal;

$ios-trash-outline-fs: 20px;
$ios-trash-outline-weight: normal;

$ios-home-outline-fs: 20px;
$ios-home-outline-weight: normal;

$ios-cloud-upload-outline-fs: 20px;
$ios-cloud-upload-outline-weight: normal;

$ios-cog-outline-fs: 20px;
$ios-cog-outline-weight: normal;

$ios-cog-outline-fs: 20px;
$ios-cog-outline-weight: normal;

$ios-funnel-fs: 12px;
$ios-funnel-weight: normal;
