
@import "../../../sass/_variables.scss";

.alert {
    background-color: $grey1;
    display: flex;
    padding: $spacing-large $spacing ($spacing-large - $spacing-small);
    width: 100%;
}
.alert--dark {
    background-color: $cmp-dark-bg-color;

    &.alert--type-info {
        background-color: $info-color;

        .alert__icon-container {
            color: $white;
        }
    }
}

.alert--small.alert__align--center {
    .alert__icon-container {
        padding: 0 $spacing-small 0 0;
    }
}

.alert--small {
    padding: $spacing-small;
}

.alert--margin-bottom-base {
    margin-bottom: $spacing;
}

.alert--margin-bottom-large {
    margin-bottom: $spacing-large;
}

.alert--margin-bottom-none {
    margin-bottom: 0;
}

.alert--margin-top-base {
    margin-top: $spacing;
}

.alert--margin-top-large {
    margin-top: $spacing-large;
}

.alert--margin-top-none {
    margin-top: 0;
}

.alert__action-items {
    margin: 0 0 $spacing-small;
    padding: $spacing-small 0 0;
}

.alert__content {
    flex: 1 0 auto;
    flex-basis: 0;

    > h3 {
        font-size: $font-size-large;
        margin: 0 0 $spacing;
    }

    > p {
        font-size: $font-size-base;
        margin: 0 0 $spacing-small;
    }

    &-align {
        display: flex;

        p {
            flex: 1;
            margin: 0;
        }

        &--start {
            align-items: flex-start;
        }

        &--end {
            align-items: flex-end;
        }

        &--center {
            align-items: center;
        }
    }
}

.alert__icon-container {
    flex: 0 1 auto;
    flex-basis: 0;
    padding: 0 $spacing $spacing-small 0;
}

.alert__icon-container--danger {
    color: $error-color;
}

.alert__icon-container--info {
    color: $info-color;
}

.alert__icon-container--warning {
    color: $warning-color;
}

.alert__icon-container--success {
    color: $success-color;
}
