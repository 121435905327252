
@import "../../../../sass/variables";

.master-template-banner-option {
    text-align: left;
    height: 33px;
    width: 100%;
    padding: 0 11px;
    line-height: 33px;
    cursor: pointer;
    color: $cmp-light-font-color;

    display: flex;
    gap: 6px;
    align-items: center;

    &:hover {
        background-color: $grey2;
    }
}
