
@import "../../../../sass/_variables.scss";

$bgColor: transparent !important;

.key-takeaway__carousel-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    min-height: 352px;
    margin-bottom: $spacing;
}

.key-takeaway__creatives {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}
.key-takeaway__creative {
    flex: 0 1;
}

.key-takeaway__next {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 342px;
    border: 0 none;
    cursor: pointer;

    &--disabled {
        cursor: default;
    }
}
