
@import "@/../sass/_variables.scss";

.master-template-select {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .master-template-select__wrapper {
        overflow-x: auto;
        padding: $spacing-large 20px $spacing;
        flex: 1;
    }

    .master-template-select__footer {
        padding: $spacing 20px;
        border-top: 1px solid $grey3;
    }
}
