
@import "../../../sass/variables";

.list-view {
    background-color: $cmp-dark-secondary-sidebar-color;
    font-size: 12px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1 0 100%;

    &.labelled-value {
        height: 34px;
    }

    &__item {
        line-height: 34px;
        padding-left: 10px;
        height: 34px;
    }
}
