
.preview-values-overlay {
    position: absolute;
    color: green;
    top: 0;
    left: 0;
    z-index: 300;
    background-color: #0d3349;
    opacity: 0.9;
    width: 100%;
    height: 100%;
}

.campaign-edit__poptip {
    &--transferred .ivu-poptip-body {
        padding: 0;
    }

    &-templateName {
        padding: 10px;
    }
}

.campaign-edit__deliverable {
    display: inline-block;
    margin: 0 15px 15px 0;
    vertical-align: top;

    &--resizable {
        display: block;
    }
}
