@alert-prefix-cls: ~"@{css-prefix}alert";
@icon-prefix-cls: ~"@{css-prefix}icon";

.@{alert-prefix-cls}{
    position: relative;
    padding: 8px 48px 8px 16px;
    border-radius: @border-radius-small;
    color: @text-color;
    font-size: @font-size-small;
    line-height: 16px;
    margin-bottom: 10px;

    &&-with-icon{
        padding: 8px 48px 8px 38px;
    }

    &-icon {
        font-size: @font-size-large;
        top: 6px;
        left: 12px;
        position: absolute;
    }

    &-desc {
        font-size: @font-size-small;
        color: @text-color;
        line-height: 21px;
        display: none;
        text-align: justify;
    }

    &-success {
        border: @border-width-base @border-style-base ~`colorPalette("@{success-color}", 3)`;
        //background-color: tint(@success-color, 90%);
        background-color: ~`colorPalette("@{success-color}", 1)`;
        .@{alert-prefix-cls}-icon {
            color: @success-color;
        }
    }

    &-info {
        border: @border-width-base @border-style-base ~`colorPalette("@{primary-color}", 3)`;
        //background-color: tint(@primary-color, 90%);
        background-color: ~`colorPalette("@{primary-color}", 1)`;
        .@{alert-prefix-cls}-icon {
            color: @primary-color;
        }
    }

    &-warning {
        border: @border-width-base @border-style-base ~`colorPalette("@{warning-color}", 3)`;
        //background-color: tint(@warning-color, 90%);
        background-color: ~`colorPalette("@{warning-color}", 1)`;
        .@{alert-prefix-cls}-icon {
            color: @warning-color;
        }
    }

    &-error {
        border: @border-width-base @border-style-base ~`colorPalette("@{error-color}", 3)`;
        //background-color: tint(@error-color, 90%);
        background-color: ~`colorPalette("@{error-color}", 1)`;
        .@{alert-prefix-cls}-icon {
            color: @error-color;
        }
    }

    &-close {
        .content-close(-3px);
    }

    &-with-desc {
        padding: 16px;
        position: relative;
        border-radius: @border-radius-small;
        margin-bottom: 10px;
        color: @text-color;
        line-height: 1.5;
    }

    &-with-desc&-with-icon{
        padding: 16px 16px 16px 69px;
    }

    &-with-desc &-desc{
        display: block;
    }

    &-with-desc &-message {
        font-size: 14px;
        color: @title-color;
        display: block;
    }

    &-with-desc &-icon {
        top: 50%;
        left: 24px;
        margin-top: -24px;
        font-size: 28px;
    }

    &-with-banner{
        border-radius: 0;
    }
}