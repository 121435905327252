
.banner-scrubbar {
    .ivu-slider {
        &-wrap {
            margin: 26px 20px;
        }

        &-button {
            width: 18px;
            height: 18px;

            &-wrap {
                width: 40px;
                height: 40px;
                top: -6px;
            }
        }
    }
}
