
@import "@/../sass/_variables.scss";

$selected-box-shadow-size: 3px;

.qa-single-annotation {
    background: $white;
    border: 1px solid $grey3;
    cursor: pointer;
    margin: 0 0 $spacing-small;
    padding: $spacing;
    transition:
        border-color 0.2s linear,
        box-shadow 0.2s linear;

    &:hover {
        border-color: $darkgrey2;
        box-shadow: 3px 5px 8px 3px lighten($darkgrey2, 15%);
    }
}

.qa-single-annotation--internal-selected {
    border-color: $darkgrey1;
    box-shadow: 3px 5px 8px 3px lighten($darkgrey1, 15%);
    cursor: default;

    &:hover {
        border-color: $darkgrey2;
        box-shadow: 3px 5px 8px 3px lighten($darkgrey2, 15%);
    }
}

.qa-single-annotation--external-selected {
    border-color: $external-annotation-color;
    box-shadow: 0 0 0 $selected-box-shadow-size $external-annotation-color;
    cursor: default;

    &:hover {
        border-color: $external-annotation-color;
        box-shadow: 0 0 0 $selected-box-shadow-size $external-annotation-color;
    }
}

.qa-single-annotation__action-items {
    display: flex;
}

.qa-single-annotation__add-comment-container {
    border-bottom: 1px solid $grey3;
    margin: 0 (0 - $spacing) 0 0;
    padding: $spacing-small $spacing 0 0;
}

.qa-single-annotation__annotation-dot-wrapper {
    align-self: flex-start;
}

.qa-single-annotation__body {
    font-size: $font-size-small;
    margin: 0 0 $spacing;
    white-space: pre-wrap;
}

.qa-single-annotation__comments-wrapper {
    border-top: 1px solid $grey3;
    margin: 0 (0 - $spacing) 0 0;

    .ivu-collapse-content > .ivu-collapse-content-box {
        border-bottom: 1px solid $grey3;
    }
}

.qa-single-annotation__date {
    color: $grey5;
    font-size: $font-size-small;
}

.qa-single-annotation__footer {
    display: flex;
    justify-content: space-between;
    margin: 0 (0 - $spacing) 0 0;
    padding: $spacing-small $spacing 0 0;
}

.qa-single-annotation__footer--align-content-right {
    justify-content: flex-end;
}

.qa-single-annotation__footer--border-top {
    border-top: 1px solid $grey3;
}

.qa-single-annotation__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-small;
}

.qa-single-annotation__header-metadata {
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
}

.qa-single-annotation__info {
    padding: 0 $spacing-smaller 0 $spacing-small;
    overflow: hidden;
}

.qa-single-annotation__resolution-text {
    font-weight: bold;
}

.qa-single-annotation__resolution-text--resolved {
    color: $success-color;
}

.qa-single-annotation__resolution-text--unresolved {
    color: $grey5;
}

.qa-single-annotation__user-name {
    font-size: $font-size-small;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
