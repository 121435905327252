
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

$check-color: $grey3;
$image-width: 80px;

.library-deliverable-table-image-cell {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: $spacing-smaller 0;
    margin: (0 - $spacing-small) 0;
}

.library-deliverable-table-image-cell__fallback-image {
    filter: invert(40%);
    height: 90%;
}

.library-deliverable-table-image-cell__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.library-deliverable-table-image-cell__image-container {
    @include make-checked-background($grey3, $spacing-small, $white);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0 $spacing-small 0 0;
    min-width: $image-width;
    position: relative;
    width: $image-width;
}

.library-deliverable-table-image-cell__text {
    color: $grey5;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
