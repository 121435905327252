
@import "@/../sass/_variables.scss";

.tag-component-filter-select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__nav {
        cursor: pointer;
        margin-left: $spacing-small;
        margin-right: $spacing-small;
    }
}
