
.data-explorer {
    &__controls {
        width: 100px;
        float: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: -30px;
    }

    &__scale {
        /*flex: 1;
        width: 100px;*/
    }
}
