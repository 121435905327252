
@import "@/../sass/_variables.scss";
.reporting-drawer {
    &__body {
        height: 100%;
        flex: 1;
        border: 1px solid #0d3349;
    }

    &__container {
        transform: translateZ(0);
        overflow: hidden;
    }

    &__header {
        padding: $spacing $spacing-large;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-select {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
        }

        &-breakdown {
            margin-right: $spacing;
        }
    }
}
