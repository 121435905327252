
@import "../../../sass/_variables.scss";

.creative-insights-header {
    height: 120px;
    width: calc(100% + 3px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $spacing-semi-large $spacing-large $spacing-semi-large $spacing-larger - 4;
    background-color: $white;
    margin-left: -3px;

    &.creative-insights-header--sidebar-closed {
        margin-left: -13px;
        padding: $spacing-semi-large $spacing-large $spacing-semi-large $spacing-large + 26;
        width: calc(100% + 13px);
    }

    &__item {
        flex: 0 0;
        padding: 7px;

        &.creative-insights-header__date-picker {
            margin-top: -10px;
        }
    }

    &__advanced-search {
        flex: 1 1;
        margin: 0 28px 0 0;
        padding-left: 0px;
    }

    &__date-picker-control {
        width: 190px;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-right: 35px;

        &-image {
            max-width: 100px;
            max-height: 50px;
            image-rendering: -webkit-optimize-contrast;
        }

        &-name {
            font-size: 12px;
        }
    }
}
