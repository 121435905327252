
@import "../sass/variables";

.user-menu {
    margin: 0 0 $spacing-base;

    &__avatar {
        flex: 1 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        color: $cmp-light-font-color;

        &-icon {
            text-align: center;
            border-radius: 50%;
            width: 30px;
            color: $darkgrey3;
            height: 30px;
            line-height: 26px;
            font-size: 12px;
            vertical-align: center;
            background-image: linear-gradient(90deg, #00fff0 0%, #00d1ff 100%);
            border: 2px solid $white;
        }

        &-poptip .ivu-poptip-body {
            color: $cmp-light-font-color;
        }
    }

    &__actions {
        cursor: pointer;
        padding: 10px 5px;

        &:hover {
            color: $cmp-light-active;
        }

        > i {
            padding-right: 5px;
        }
    }
}
