
@import "../../../sass/variables";

.language-modal {
    &__tagged-input {
        .ivu-select-dropdown {
            display: none;
        }
    }

    &__tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 7px;
    }

    &__table-checkbox {
        .ivu-table-header .ivu-table-cell-with-selection {
            display: none;
        }
    }

    &__body {
        margin-top: 20px;
        max-height: 300px;
        overflow-x: auto;
    }

    &__aid {
        margin-top: 7px;
        background-color: $grey1;
        border: none;

        &.ivu-alert.ivu-alert-with-icon {
            padding: 19px 12px 22px 82px;
        }

        .ivu-alert-icon {
            padding: 15px 23px 0 11px;
        }
    }
}
