
@import "../../../sass/variables";

.detachable-panel {
    color: $cmp-dark-font-color;

    & .ivu-collapse-header {
        font-size: $font-size-base;
    }

    &__modal {
        overflow: hidden;
    }

    &__content {
        min-width: 200px;
        width: 100%;
        height: 100%;
    }

    &--closable &__header {
        padding-right: 24px;
    }

    .ivu-modal-content {
        box-shadow: 1px 1px 5px 0 #000000;
        background-color: $cmp-dark-bg-color;
        border-radius: $border-radius-base;
        resize: both;
        overflow: hidden;
        min-width: 200px;
        min-height: 300px;
        padding-bottom: 45px;
        height: 100%;
    }

    .ivu-modal {
        margin: 0 0 0 auto;
    }

    .ivu-modal-header {
        border-color: $cmp-dark-border-color;

        p,
        &-inner {
            color: $cmp-dark-font-color;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;

        &-icon {
            cursor: pointer;
            color: #999;

            &:hover {
                color: #444;
            }
        }
    }

    .ivu-modal-body {
        padding: 0;
        height: 100%;
    }
}
