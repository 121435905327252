
@import "@/../sass/_variables.scss";

.library-commented-on-by-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}
