
@import "../../../sass/variables";

.campaign-editor-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.campaign-edit {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &__body {
        flex: 1;
        overflow-y: auto;
        position: relative;
        padding: 22px 20px;
    }

    &__confirm-unsaved-changes-save-button {
        background: $blue;
        border: 1px solid $blue;

        &:focus {
            background: $blue;
            border: 1px solid $blue;
        }

        &:hover {
            background: lighten($blue, 10);
            border: 1px solid lighten($blue, 10);
        }

        &:active {
            background: $blue;
            border: 1px solid $blue;
        }
    }

    &__header {
        padding: 0 20px;
        overflow-x: auto;
    }

    &__banner-controls {
        flex: 0 0 60px;
        height: 60px;
        padding: 0 10px;
        width: 100%;
        background-color: $cmp-dark-bg-color;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .timeline-slider {
            width: 100%;
            display: flex;
            flex: 1 0;
            align-items: center;
        }
    }

    .feed-import-modal--update {
        opacity: 0.01;
    }
}

.add-templates-modal {
    .ivu-modal {
        top: 3vh;
    }

    .ivu-modal-content {
        height: 100%;
    }

    .templates-modal {
        display: flex;
        flex: 1;

        &__sidebar {
            display: flex;
            flex: 0 0 215px;
            width: 215px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: $white;

            &-filter {
                width: 100%;
                flex: 0 0;
            }

            .ivu-collapse {
                background: $white;
            }

            .ivu-collapse > .ivu-collapse-item {
                .ivu-collapse-header {
                    border: none;
                    background: $white;
                }

                &.ivu-collapse-item-active {
                    border-bottom: 1px solid $cmp-light-filters-border-color;

                    .ivu-collapse-header {
                        background: $cmp-light-filters-border-color;
                    }
                }
            }
        }

        &__body {
            flex: 1;
            padding: 0 20px;
        }

        &__library {
            height: 74vh;
            display: flex;
            flex-direction: column;
        }
    }
}

.sidebar-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
}

.sidebar-panes {
    display: flex;
    justify-content: flex-start;
    position: fixed;
    left: 60px;
    top: 0;
    height: 100vh;
    width: 750px;
}

.sidebar-panel {
    flex: 0 0 250px;
    height: 100vh;
    width: 250px;
    border-right: $cmp-dark-border-width solid $cmp-dark-border-color;

    &__header {
        height: $campaign-nav-height;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 8px;
    }

    &__inner-pane {
        border-top: $cmp-dark-border-width solid $cmp-dark-border-color;
        margin-top: $campaign-nav-height;
    }

    &--settings {
        .sidebar-panel__inner-pane {
            margin-top: 0;
        }
    }

    &--groups {
        .groups-pane {
            height: calc(100vh - 80px);
        }
    }

    &--with-scrollbar {
        .groups-pane__element {
            width: calc(100% - 15px);
            padding-bottom: 50px;
        }
    }

    .preview-social__switch {
        padding: 10px;
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        align-items: baseline;
        align-items: center;
        justify-content: flex-end;
    }
}

.slide-to-left {
    transform: translateX(0px);
    transition: all 150ms linear 0s;
}

.slide-to-right {
    transform: translateX(-250px);
    transition: all 150ms linear 0s;
}

.campaign-edit-sidebar {
    overflow: hidden;
    position: relative;
}

.detached-editables .ivu-modal-content {
    top: 40px;
    left: calc(100% - 270px);
}

.detached-settings .ivu-modal-content {
    top: 80px;
    left: calc(100% - 260px);
}
