
@import "../../../sass/variables";
.app-wrapper.wpp-theme--intelligentdelivery .campaign-content .main-content {
    padding-top: 0;
    margin-top: 60px;
}
.intelligent-delivery {
    &__wrapper {
        padding: 0px 20px;
        max-width: 100vw;
        .save-changes {
            display: flex;
        }
    }
    &-accounts__draggable-wrapper {
        display: flex;
        padding: 20px 0;
        flex-direction: column;
    }
    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: -20px 0 0 0;
        width: 100%;
        height: 60px;
        position: fixed;
        z-index: 2;
        left: 0;
        top: 73px;
        overflow: visible;
        padding: 0 20px;
        background: #fff;
        border-bottom: 1px solid #c1c7cd;

        &-wrapper {
            flex: 1;
            display: flex;
            width: 100%;
        }

        &-action-wrapper {
            flex: 1;
            display: flex;
            width: 100%;
            align-items: center;

            &.right {
                display: flex;
                justify-content: flex-end;
                margin-left: auto;
                white-space: nowrap;
            }
        }

        &-action-select {
            max-width: 175px;
        }

        .notification-label {
            width: auto;
            margin-left: 20px;
            padding-right: 10px;
        }

        .save-changes {
            button {
                &:not(:first-child) {
                    margin-left: 15px;
                }
            }
        }
    }
    &__account {
        margin-bottom: 20px;
        &-title {
            display: flex;
            margin-bottom: 10px;
        }
        &-name {
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            min-width: 80%;
            svg {
                max-height: 32px;
                margin-right: 10px;
            }
        }
        &-actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            gap: 10px;
            i {
                cursor: pointer;
            }
            .intelligent-delivery__section-drag-handle {
                margin-top: 0;
                margin-left: 10px;
                cursor: pointer;
            }
        }
        &-rules {
            width: 100%;
            padding: 16px 0 0 0;
            background-color: $white;
            margin-bottom: 24px;
        }
        &-signals {
            padding: 13px 0;
            border-bottom: 1px solid var(--wpp-grey-color-400);
            border-top: 1px solid var(--wpp-grey-color-300);
            table {
                width: 100%;
                text-align: left;
                border-collapse: collapse;

                th,
                td {
                    padding: 0 16px;
                }
                tr {
                    th {
                        padding-bottom: 13px;
                    }
                    td {
                        padding-top: 13px;
                    }
                }
            }
        }
        &-variants {
            padding: 8px 16px 16px 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
    &__signal {
        &-actions {
            display: flex;
            flex-direction: row;
            gap: 5px;
            height: 100%;
            align-items: center;
            justify-content: center;
            margin-left: auto;

            a {
                opacity: 0.7;
                transition: all 0.5;
                display: flex;
                align-items: center;
                gap: 3px;
                cursor: pointer;
                &:hover {
                    opacity: 1;
                }
            }
        }
        &-name,
        &-status {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            border-bottom: 1px solid var(--wpp-grey-color-600);
        }
        &-name {
            width: 85%;
        }
        &-status {
            width: 15%;
        }
        &-add {
            padding: 5px 16px;

            a {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                padding: 5px;
                display: flex;
                align-items: center;
                gap: 4px;
                width: max-content;
            }
        }
        &-add-icon {
            border: 1px solid var(--wpp-primary-color-500);
            border-radius: 50%;
        }
    }
    &__rule {
        &-row {
            background: #fff;
            border-top: 0;
            border-bottom: 1px solid var(--wpp-grey-color-200);
            &:last-child {
                border-bottom: 0;
            }
            &.intelligent-delivery__rule-row--add {
                border-top: 1px solid var(--wpp-grey-color-300);
                border-bottom: 0;
                background: none;
                a {
                    color: var(--wpp-primary-color-900);
                    font-weight: 600;
                }
            }
        }
        &-disabled {
            .intelligent-delivery__account-variants,
            .intelligent-delivery__account-signals,
            .intelligent-delivery__signal-add {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        &-header {
            padding-bottom: 16px;
            margin: 0 16px;
            border-bottom: 1px solid var(--wpp-grey-color-400);
            display: flex;
            align-items: center;
            gap: 10px;
            &-content {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
        &-number {
            display: inline-block;
            padding-left: 5px;
            font-size: 12px;
            line-height: 16px;
        }
        &-variants {
            color: #5e00b5;
            cursor: pointer;
        }
        &-name {
            font-size: 16px;
            font-weight: 600;
        }
    }
    &__no-data {
        border: 1px solid #c1c7cd;
        padding: 5px;
    }
    &__dashboard {
        position: relative;
        height: 100%;
        width: 100%;
        > div {
            width: 100%;
            height: 100%;
        }
        .empty-message {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .loading-layer--theme-light {
            position: absolute;
            top: 1px;
        }
    }
    &__add-rule {
        width: 100%;
    }
    &__no-signals {
        padding: 0 16px;
    }
    &__status {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        padding: 1px 8px;
        background-color: var(--wpp-grey-color-300);
        border-radius: 4px;
        color: var(--wpp-grey-color-600);
        text-transform: capitalize;
        width: max-content;
        &.green {
            color: #2ac63f;
            background-color: rgba(#9eeba8, 0.2);
        }
        &.blue {
            color: var(--wpp-primary-color-500);
            background-color: var(--wpp-primary-color-100);
        }
        &.red {
            color: #ea2a4a;
            background-color: rgba(#ea2a4a, 0.2);
        }
    }
    &__highlighted {
        color: var(--wpp-primary-color-500);
    }
    &__signal-container {
        cursor: pointer;
    }
}
.rule-edit-modal {
    &__platform {
        li {
            display: flex;
            align-items: center;
            svg {
                height: 32px;
                width: 32px;
                margin-right: 10px;
            }
        }
    }
}
