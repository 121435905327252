
@import "@/../sass/_variables.scss";

.duplicate-campaign-modal {
    &__checkbox-group-error {
        color: $error-color;
        font-size: $font-size-small;
        padding: $spacing-small 0 0;
    }

    &__checkbox-group-wrapper {
        margin: 0 0 $spacing;

        .ivu-checkbox-group-item {
            display: block;
        }

        .ivu-checkbox-wrapper:hover {
            background-color: $cmp-light-filters-hover-color;
        }
    }

    &__checkbox-group-label {
        display: block;
        font-size: $font-size-base;
        padding: 0 0 $spacing-small;
    }

    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;

        .ivu-alert {
            margin: 0;
        }
    }
}
