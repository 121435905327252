
@import "@/../sass/_variables.scss";

.delete-metadata-field-modal__alert-wrapper {
    padding: $spacing 0 0;
}

.delete-metadata-field-modal__button {
    margin: 0 0 0 $spacing-small;
}
