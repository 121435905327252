
@import "@/../sass/_variables.scss";
.library-deliverable-table-metadata-header-cell {
    font-weight: bold;
    width: 100%;
}

.library-deliverable-table-metadata-header-cell__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.library-deliverable-table-metadata-header-cell__edit-button-wrapper {
    margin: (0 - $spacing-semi-small) 0 (0 - $spacing-semi-small) $spacing-smaller;
}

.library-deliverable-table-metadata-header-cell__title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
