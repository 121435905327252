@anchor-prefix: ~"@{css-prefix}anchor";

.@{anchor-prefix}{
    &-wrapper{
        overflow: auto;
        padding-left: 4px;
        margin-left: -4px;
    }

    &{
        position: relative;
        padding-left: @anchor-border-width;

        &-ink {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            &:before {
                content: ' ';
                position: relative;
                width: @anchor-border-width;
                height: 100%;
                display: block;
                background-color: @border-color-split;
                margin: 0 auto;
            }
            &-ball {
                display: inline-block;
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                border: 2px solid @primary-color;
                background-color: @body-background;
                left: 50%;
                transition: top @transition-time @ease-in-out;
                transform: translate(-50%, 2px);
            }
        }

        &.fixed &-ink &-ink-ball {
            display: none;
        }
    }

    &-link {
        padding: 8px 0 8px 16px;
        line-height: 1;

        &-title {
            display: block;
            position: relative;
            transition: all .3s;
            color: @text-color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 8px;
            &:only-child {
                margin-bottom: 0;
            }
        }

        &-active > &-title {
            color: @primary-color;
        }
    }

    &-link &-link {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}
