
@import "@/../sass/_variables.scss";

.library-banner__scaling-controls {
    align-self: flex-end;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    background: $white;
    color: $darktheme20;
    display: flex;
    font-size: $font-size-small;

    &--dark {
        background-color: $cmp-dark-secondary-bg-color;
        color: $grey1;
    }

    p {
        margin: 0 $spacing-smaller 0 0;
    }
}
