
@import "@/../sass/_variables.scss";

.ci-settings-modal {
    .hox-modal__header {
        font-size: 16px;
    }

    &__dynamic-input {
        margin-bottom: $spacing-semi-small;
    }
}

.ci-settings-modal__section {
    padding: $spacing-semi-large $spacing-larger;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-weight: normal;
            white-space: nowrap;
        }

        .report-kpi-selector {
            width: 210px;
            flex: 0 0 210px;
        }
    }

    p {
        padding: $spacing 0;
    }

    &-inputs {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ci-settings-modal__metric-mode,
        .ci-settings-modal__discard-name {
            width: 180px;
            flex: 0 0 180px;
            margin-right: auto;
        }

        .ivu-icon {
            margin-right: $spacing-small;
        }
    }

    &-number {
        width: 80px;
    }
}

.discard_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__add-btn {
        color: $ci-blue;
        display: flex;
        align-items: center;
        line-height: 20px;
        cursor: pointer;
    }
}
