
@import "../../sass/_variables.scss";

.campaigns {
    &__action {
        &-items {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0;
        }

        &-item {
            flex: 0 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }

    &__list--no-action-items {
        padding: 15px 0 0 0;
    }

    .hox-collapsible {
        width: 100%;

        > .ivu-collapse-item.ivu-collapse-item-active {
            border-bottom: 1px transparent;
        }

        &__body {
            margin-top: $spacing;
        }
    }

    .ivu-collapse {
        border-top: 1px transparent;
    }

    .square-button--default:hover {
        background-color: transparent;
    }

    &__section-drag-handle {
        float: right;
        margin-top: 16px;
    }

    &__section-divider {
        border: none;
        border-top: $border-width-base solid $cmp-light-border-color;
        flex: 1;
    }

    &__section-empty-message {
        color: $cmp-light-font-color;
        font-size: $font-size-base;
        margin: (0 - $spacing-small) 0 $spacing;

        p {
            margin: 0 0 $spacing-small;

            &:first-child {
                font-weight: bold;
            }
        }
    }

    &__section-header {
        align-items: center;
        display: flex;
        margin: 0 0 $spacing;
        padding: $spacing 0 0;
    }

    &__section-title {
        font-size: $font-size-large;
        font-weight: bold;
        padding: 0 $spacing-small 0 0;
    }
}

.campaigns-dashboard__empty-message-wrapper {
    padding: $spacing-large 0 0 0;
}

.campaigns-dashboard__loading-wrapper {
    height: 120px;
    position: relative;
}
