
@import "@/../sass/_variables.scss";

.insights-creative-preview-carousel {
    position: relative;

    &:hover .creative-carousel-arrow,
    &:hover .creative-carousel-numbers {
        visibility: visible;
    }

    &__item {
        position: relative;

        .loading-layer--position-absolute {
            z-index: 4;
            pointer-events: none;
        }
    }
}

.creative-carousel-arrow {
    visibility: hidden;

    border: none;
    outline: none;

    padding: 0;
    margin: 0;

    width: 20px;
    height: 20px;

    cursor: pointer;

    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    color: $cmp-light-font-color;

    &:focus {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    }

    text-align: center;
    font-size: 14px;

    &.left {
        left: 14px;
    }

    &.right {
        right: 14px;
    }
}

.creative-carousel-numbers {
    visibility: hidden;
    display: block;
    position: absolute;
    bottom: 30px;
    font-size: 11px;
    left: 50%;
    transform: translate(-50%, -50%);

    li button {
        transition: none;
    }
}
