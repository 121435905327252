.move-motion(@className, @keyframeName) {
    .make-motion(@className, @keyframeName);
    .@{className}-enter-active, .@{className}-appear {
        opacity: 0;
        animation-timing-function: @ease-in-out;
    }
    .@{className}-leave-active {
        animation-timing-function: @ease-in-out;
    }
}

.move-motion(move-up, ivuMoveUp);
.move-motion(move-down, ivuMoveDown);
.move-motion(move-left, ivuMoveLeft);
.move-motion(move-right, ivuMoveRight);

@keyframes ivuMoveDownIn {
    0% {
        transform-origin: 0 0;
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform-origin: 0 0;
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes ivuMoveDownOut {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
        opacity: 1;
    }
    100% {
        transform-origin: 0 0;
        transform: translateY(100%);
        opacity: 0;
    }
}

@keyframes ivuMoveLeftIn {
    0% {
        transform-origin: 0 0;
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform-origin: 0 0;
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes ivuMoveLeftOut {
    0% {
        transform-origin: 0 0;
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform-origin: 0 0;
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes ivuMoveRightIn {
    0% {
        opacity: 0;
        transform-origin: 0 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform-origin: 0 0;
        transform: translateX(0%);
    }
}

@keyframes ivuMoveRightOut {
    0% {
        transform-origin: 0 0;
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform-origin: 0 0;
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes ivuMoveUpIn {
    0% {
        transform-origin: 0 0;
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform-origin: 0 0;
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes ivuMoveUpOut {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
        opacity: 1;
    }
    100% {
        transform-origin: 0 0;
        transform: translateY(-100%);
        opacity: 0;
    }
}

// specific transition for Notice

.move-motion(move-notice, ivuMoveNotice);
@import '../components/notice.less';

@keyframes ivuMoveNoticeIn {
    0% {
        opacity: 0;
        transform-origin: 0 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform-origin: 0 0;
        transform: translateX(0%);
    }
}

@keyframes ivuMoveNoticeOut {
    0% {
        transform-origin: 0 0;
        transform: translateX(0%);
        opacity: 1;
    }
    70% {
        transform-origin: 0 0;
        transform: translateX(100%);
        height: auto;
        padding: @notice-padding;
        margin-bottom: @notice-margin-bottom;
        opacity: 0;
    }
    100% {
        transform-origin: 0 0;
        transform: translateX(100%);
        height: 0;
        padding: 0;
        margin-bottom: 0;
        opacity: 0;
    }
}
