
@import "../../../sass/variables";

.expandable-text-input {
    &--locked {
        color: $cmp-dark-disabled-color;
    }

    &__expanded {
        padding: 0 10px 8px;
        border-left: 4px solid $cmp-dark-tag-primary-border;

        &-header {
            align-items: flex-end;
            color: $cmp-dark-font-color;
            display: flex;
            font-weight: bold;
            justify-content: space-between;
            padding: 4px 0;
            width: 100%;

            i {
                cursor: pointer;

                &:hover {
                    color: $cmp-dark-active;
                }
            }
        }
    }
    &__expanded_text_edit {
        display: flex;
        align-items: center;
        i {
            margin-left: 10px;
        }
    }

    .is-placeholder .labelled-value__text--value {
        opacity: 0.5;
    }
}
.generate-cta-modal {
    &__wrap {
        display: flex;
    }
    &__sidebar {
        width: 30%;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        &-head {
            margin-bottom: 40px;
            span {
                font-size: 18px;
            }
        }
    }
    &__prompt {
        display: flex;
        margin: 20px 0;
        .ivu-input {
            padding: 8px 10px;
        }
        .ivu-btn {
            margin-left: 10px;
        }
    }
}
