
@import "@/../sass/_variables.scss";

.resize-settings-template-section {
    border-bottom: 1px solid $grey3;
    margin: 0 0 $spacing;
    padding: 0 0 $spacing;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .new-size-modal__form {
        display: flex;
        justify-content: flex-end;
    }
}

.resize-settings-template-section__label {
    font-size: $font-size-large;
    font-weight: bold;
}

.resize-settings-template-section__preview-container {
    height: 240px;
}

.resize-settings-template-section__title-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing;
}
