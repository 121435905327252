@import "../styles/iview/index.less";

// For the reference: Default values used by iView

@import "../sass/_hox-colours";

// Prefix
@css-prefix: ivu-;
@css-prefix-iconfont: ivu-icon;

// Color
@primary-color: @electric-blue;
@info-color: @blue;
@success-color: @green;
@processing-color: @primary-color;
@warning-color: @yellow;
@error-color: @red;
@normal-color: @grey1;
@link-color: @blue;
@link-hover-color: tint(@link-color, 20%);
@link-active-color: shade(@link-color, 5%);
@selected-color: fade(@primary-color, 90%);
@tooltip-color: @white;
@subsidiary-color: @darkgrey1;
@rate-star-color: @yellow;

// Base
@body-background: @white;
@font-family: "Open Sans", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑",
    Arial, sans-serif;
@code-family: Consolas, Menlo, Courier, monospace;
@title-color: #17233d;
@text-color: #515a6e;
@font-size-base: 14px;
@font-size-small: 14px;
@font-size-large: 16px;
@line-height-base: 1.5;
@line-height-computed: floor((@font-size-base * @line-height-base));
@border-radius-base: 6px;
@border-radius-small: 4px;
@cursor-disabled: not-allowed;

// Border color
@border-color-base: #dcdee2; // outside
@border-color-split: #e8eaec; // inside
@border-width-base: 1px; // width of the border for a component
@border-style-base: solid; // style of a components border

// Background color
@background-color-base: #f7f7f7; // base
@background-color-select-hover: @input-disabled-bg;
@tooltip-bg: @darkgrey3;
@head-bg: #f9fafc;
@table-thead-bg: #f8f8f9;
@table-td-stripe-bg: #f8f8f9;
@table-td-hover-bg: #f7f7f7;
@table-td-highlight-bg: #ebf7ff;
@menu-dark-title: #515a6e;
@menu-dark-active-bg: #363e4f;
@menu-dark-subsidiary-color: rgba(255, 255, 255, 0.7);
@menu-dark-group-title-color: rgba(255, 255, 255, 0.36);
@date-picker-cell-hover-bg: #e1f0fe;

// Shadow
@shadow-color: rgba(0, 0, 0, 0.2);
@shadow-base: @shadow-down;
@shadow-card: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
@shadow-up: 0 -1px 6px @shadow-color;
@shadow-down: 0 1px 6px @shadow-color;
@shadow-left: -1px 0 6px @shadow-color;
@shadow-right: 1px 0 6px @shadow-color;

// Button
@btn-font-weight: normal;
@btn-padding-base: 5px 15px 6px;
@btn-padding-large: 9px 15px 9px 15px;
@btn-padding-small: 1px 7px 2px;
@btn-padding-base-icon: 5px 15px 6px;
@btn-padding-large-icon: 6px 15px 6px 15px;
@btn-padding-small-icon: 1px 7px 2px;
@btn-font-size: 14px;
@btn-font-size-large: 16px;
@btn-border-radius: 2px;
@btn-border-radius-small: 2px;
@btn-group-border: shade(@primary-color, 5%);

@btn-disable-color: #c5c8ce;
@btn-disable-bg: @background-color-base;
@btn-disable-border: @border-color-base;

@btn-default-color: @text-color;
@btn-default-bg: #fff;
@btn-default-border: @border-color-base;

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@btn-ghost-color: @text-color;
@btn-ghost-bg: #fff;
@btn-ghost-border: @border-color-base;

@btn-circle-size: 34px;
@btn-circle-size-large: 44px;
@btn-circle-size-small: 24px;

// Layout and Grid
@grid-columns: 24;
@grid-gutter-width: 0;
@layout-body-background: #f5f7f9;
@layout-header-background: #515a6e;
@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;

// Legend
@legend-color: #999;

// Input
@input-height-base: 34px;
@input-height-large: 44px;
@input-height-small: 24px;

@input-padding-horizontal: 7px;
@input-padding-vertical-base: 4px;
@input-padding-vertical-small: 1px;
@input-padding-vertical-large: 6px;

@input-placeholder-color: @btn-disable-color;
@input-color: @text-color;
@input-border-color: @border-color-base;
@input-bg: #fff;
@input-group-bg: #f8f8f9;

@input-hover-border-color: @primary-color;
@input-focus-border-color: @primary-color;
@input-disabled-bg: #f3f3f3;

// Tag
@tag-font-size: 12px;

// Media queries breakpoints
// Extra small screen / phone
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-xs-max: (@screen-xs-min - 1);

// Small screen / tablet
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-sm-max: (@screen-sm-min - 1);

// Medium screen / desktop
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-md-max: (@screen-md-min - 1);

// Large screen / wide desktop
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-lg-max: (@screen-lg-min - 1);

// Z-index
@zindex-spin: 8;
@zindex-affix: 10;
@zindex-back-top: 10;
@zindex-select: 900;
@zindex-modal: 1000;
@zindex-message: 1010;
@zindex-notification: 1010;
@zindex-tooltip: 1060;
@zindex-transfer: 1060;
@zindex-loading-bar: 2000;
@zindex-spin-fullscreen: 2010;

// Animation
@animation-time: 0.3s;
@transition-time: 0.2s;
@ease-in-out: ease-in-out;

// Slider
@slider-color: tint(@primary-color, 20%);
@slider-height: 6px;
@slider-margin: 16px 0;
@slider-button-wrap-size: 24px;
@slider-button-wrap-offset: -4px;
@slider-disabled-color: #ccc;

// Avatar
@avatar-size-base: 28px;
@avatar-size-lg: 40px;
@avatar-size-sm: 24px;
@avatar-font-size-base: 18px;
@avatar-font-size-lg: 24px;
@avatar-font-size-sm: 14px;
@avatar-bg: #ccc;
@avatar-color: #fff;
@avatar-border-radius: @border-radius-small;

// Anchor
@anchor-border-width: 2px;

.active-btn-color(@color) {
    &:focus {
        box-shadow: 0 0 0 0px fade(@color, 20%);
    }
}
