
@import "../../../sass/variables";

$height: 40px;
$button-width: 40px;

.campaign-banner-footer {
    width: 100%;
    height: $height;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 6px;

    &__name,
    &__controls,
    &__options {
        flex: 0 0 $button-width;
        width: $button-width;

        .ivu-icon {
            cursor: pointer;
        }
    }

    &__name {
        font-size: 12px;
        flex: 1 0;
        text-align: left;
        padding-left: 12px;
        overflow: hidden;
    }
}
