
@import "@/../sass/_variables.scss";

$check-color: $grey3;

.media-library-add-asset-from-url {
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: flex-start;
    align-content: center;
}

.media-library-add-asset-from-url__button {
    margin: 0 0 0 $spacing-small;
}

.media-library-add-asset-from-url__input-wrapper {
    margin: 0 0 $spacing;
}
