
@import "@/../sass/_variables.scss";

$qa-sidebar-width: 300px;

.qa-drawer {
    .timeline-slider {
        align-items: center;
        display: flex;
        flex: 1 0;
        width: 100%;
    }

    .ivu-slider-wrap {
        margin: 9px ($spacing + 9) 9px 9px;
    }

    .tab-carousel-arrow.right {
        right: $spacing-large + $qa-sidebar-width;
    }
}

.qa-drawer__bulk-header {
    width: 100%;
    padding: $spacing-small $spacing-large $spacing-smaller $spacing-large;
    text-align: right;
    font-weight: bold;
    background-color: $white;
}

.qa-drawer__loading-layer {
    position: fixed;
}

.qa-drawer__scrubbar-container {
    flex: 1;
    position: relative;
}

.qa-drawer__sidebar {
    border-left: 1px solid $grey3;
    flex: 0 1;
    min-width: $qa-sidebar-width;
    position: relative;
    width: $qa-sidebar-width;
}
