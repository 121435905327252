
@import "../../../sass/variables";

.create-tag-modal__footer-button {
    &--delete {
        margin-right: auto;
    }

    &--save {
        margin-left: $spacing-semi-small;
    }
}
