
@import "@/../sass/_variables.scss";

.multiselect-view-control {
    display: flex;
    flex-wrap: nowrap;

    .active {
        z-index: 1;
    }

    .ivu-btn:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-right: -1px;
    }

    .ivu-btn:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.master-template-select-view-control {
    margin-right: $spacing;
}
