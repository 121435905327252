
@import "../../../sass/variables";

.advanced-search {
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &--tag-mode {
        ::placeholder {
            font-size: 24px;
            color: #000000;
        }
    }

    &__icon {
        margin-top: -5px;
        color: $ci-blue;
    }

    &__type {
        font-size: 14px;
        padding: 0 $spacing-small;
        flex: 0 0;
        margin-top: 1px;
        white-space: nowrap;
    }

    &__button {
        border: 0 none;
        flex: 0 0;
        margin-top: 1px;
    }

    input {
        font-size: 24px;
        line-height: 22px;
        margin-top: -3px;
        border: 0 none;
        background-color: transparent;
        flex: 1 1;
        color: #666666;

        &:focus-visible {
            outline-color: transparent;
        }
    }
}
