
@import "@/../sass/_variables.scss";

.resize-settings-modal__settings-container {
    border-bottom: 1px solid $grey3;
    margin: 0 0 $spacing;
    padding: 0 0 $spacing-small;
}

.resize-settings-modal__settings-container--no-bottom-border {
    border-bottom: none;
    margin: 0;
    padding: 0;
}

.resize-settings-modal--button-spacing {
    margin: 0 0 0 $spacing-small;
}
