
@import "@/../sass/_variables.scss";

.library-qa-status-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}

.library-qa-status-filter__status-dot-wrapper {
    display: inline-block;
    padding: 0 0 0 $spacing-smaller;
}
