
@import "@/../sass/_variables.scss";

.publish-notification-item__list-item {
    list-style-position: inside;
    margin-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
