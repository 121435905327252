
@import "../../../sass/variables";

.intelligent-delivery {
    &__account {
        &-status {
            display: flex;
            gap: 5px;
            padding: 0 10px;
            font-weight: 400;
            font-size: 16px;
            td {
                padding: 0 5px;
            }
        }
    }
    &__dashboard {
        .publish-status {
            &__info {
                font-weight: 300;
                display: flex;
                margin-left: 10px;
                span {
                    display: block;
                }
                &-name {
                    margin-right: auto;
                    padding-right: 20px;
                }
            }
            &__detailed-button {
                margin-top: 10px;
                height: 40px;
                > span {
                    display: flex;
                    align-items: center;
                }
                .loading-spinner {
                    margin-top: 10px;
                }
            }
        }
    }
}
