
@import "@/../sass/_variables.scss";

.gpt3-preview {
    &__image-prompt {
        background: rgba($color: #000000, $alpha: 0.5);
        color: $white;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
.gpt3cta-preview__cta {
    display: flex;
    align-items: baseline;
    h4 {
        font-weight: 400;
    }
    button {
        margin: 0 0 10px auto;
    }
}
