
@import "../../../sass/variables";

.deliverable-label {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    margin: 2px 4px 2px 0;
    padding: 0 8px;
    border-radius: 2px;
    border: 1px solid $cmp-dark-tag-default-bg;
    background: $cmp-dark-tag-default-bg;
    font-size: 12px;
    vertical-align: middle;
    opacity: 1;
    overflow: hidden;

    &--primary {
        border: 1px solid $cmp-dark-tag-primary-border;
        background: $cmp-dark-tag-primary-bg;
    }
}
