// Loading for loop
.ivu-load-loop{
    animation: ani-load-loop 1s linear infinite;
}

@keyframes ani-load-loop {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
