
@import "@/../sass/_variables.scss";

.dashboard-content-dropdown {
    margin: $spacing $spacing-smaller;

    &__type-value {
        font-size: 20px;
    }
}
