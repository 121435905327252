@backtop-prefix-cls: ~"@{css-prefix}back-top";

.@{backtop-prefix-cls} {
    z-index: @zindex-back-top;
    position: fixed;
    cursor: pointer;
    display: none;

    &.@{backtop-prefix-cls}-show {
        display: block;
    }

    &-inner {
        background-color: rgba(0,0,0,.6);
        border-radius: 2px;
        box-shadow: 0 1px 3px rgba(0,0,0,.2);
        transition: all @transition-time @ease-in-out;

        &:hover {
            background-color: rgba(0,0,0,.7);
        }
    }

    i{
        color: #fff;
        font-size: 24px;
        padding: 8px 12px;
    }
}
