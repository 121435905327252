
@import "../../sass/variables";

.login {
    &-wrapper {
        background: transparent;
        text-align: center;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 360px;
        margin: 0 auto;
        flex-direction: column;
        color: #ffffff;

        .forgot-password {
            float: right;
            color: $white;
        }

        .sign-in-btn {
            height: auto;
            margin-top: 25px;
            padding: 10px 50px;
            width: 100%;

            &-wrapper {
                margin-top: 24px;
            }
        }
    }

    &-form-wrapper {
        width: 100%;
    }

    &-form {
        width: 100%;
    }
}
