@dropdown-prefix-cls: ~"@{css-prefix}dropdown";
@dropdown-item-prefix-cls: ~"@{dropdown-prefix-cls}-item";

.@{dropdown-prefix-cls} {
    display: inline-block;
    //position: relative;

    .@{select-dropdown-prefix-cls} {
        overflow: visible;
        max-height: none;
    }
    .@{dropdown-prefix-cls} {
        width: 100%;
    }

    &-rel{
        //display: inline-block;
        position: relative;
        &-user-select-none{
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
        }
    }

    &-menu{
        min-width: 100px;
    }

    &-transfer{
        width: auto;
    }
    &-item-selected, &-item&-item-selected:hover{
        background:  ~`colorPalette("@{primary-color}", 1)`;
    }
}

.select-item(@dropdown-prefix-cls, @dropdown-item-prefix-cls);