
@import "@/../sass/_variables.scss";

.labelled-value {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 0 0 0 10px;
    width: 100%;

    &__icons-container {
        display: none;
    }

    &__text {
        flex: 1 0;
        display: flex;
        overflow: hidden;
        padding: 0 5px;
        align-items: center;
        cursor: pointer;

        &--value {
            flex: 1 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &-whitespace:before,
            &:empty:before {
                content: "\200b";
            }
        }

        &:hover {
            .labelled-value__icons-container {
                color: $cmp-dark-font-color;
                display: block;
            }
        }
    }
}
