
@import "@/../sass/_variables.scss";

@keyframes loading-spinner__spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    position: relative;
    height: 1em;
    width: 1em;
    line-height: 0;

    div,
    img {
        width: 100%;
        height: 100%;
    }
}

.loading-spinner__icon {
    animation: loading-spinner__spin 1s linear infinite;
    flex: 1;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;

    path {
        fill: currentColor;
    }
}

.loading-spinner__logo {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    path {
        fill: currentColor;
    }
}

.wish-icon {
    background: url("../../assets/wish.gif") no-repeat center;
    background-size: 100%;
}
