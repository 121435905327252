
@import "@/../sass/_variables.scss";

.font-form {
    &__preview {
        &-label {
            display: inline-block;
            margin: 0 0 8px;
        }

        &-text {
            display: block;
            border: 1px solid $cmp-light-border-color;
            font-family: "CustomFontPreview";
            font-size: 32px;
            min-height: 100px;
            padding: $spacing-small;
            position: relative;
            margin: 0 0 $spacing-small;
            text-align: center;
        }
    }

    &__tabbed-content {
        padding: $spacing 0;
    }
}
