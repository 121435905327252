
@import "@/../sass/_variables.scss";

.multiview-select-all {
    display: inline-block;
    white-space: nowrap;
}

.multiview-select-all__selected-count {
    border-left: 1px solid $grey3;
    font-size: $font-size-base;
    padding: 0 0 0 $spacing-small;
}
