
@import "@/../sass/_variables.scss";

$dragged-dropzone-border-width: 1px;

.library-deliverable-table-customise-columns__add-metadata-wrapper {
    border-bottom: 1px solid $grey3;
    padding: $spacing-smaller $spacing-small $spacing-small;

    .ivu-btn {
        width: 100%;
    }
}

.library-deliverable-table-customise-columns__checkbox-container {
    flex: 1 0 auto;
}

.library-deliverable-table-customise-columns__drag-icon {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin: (0 - $dragged-dropzone-border-width);
    padding: 0 $spacing-smaller;
    transition:
        background 0.2s linear,
        color 0.2s linear;

    &:hover {
        background: $primary;
        color: $white;
        cursor: grab;
    }
}

.library-deliverable-table-customise-columns__dragged-label {
    background: $primary;
    border-radius: $btn-border-radius;
    box-shadow: $box-shadow-base;
    color: $white;
    max-width: 300px;
    overflow: hidden;
    padding: $spacing-small $spacing;
    position: fixed;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 99999;
}

.library-deliverable-table-customise-columns__dropdown-container {
    display: flex;
    flex-direction: column;
    max-height: 330px;

    .ivu-checkbox-group-item {
        display: block;
        /*
      To make sure we don't have a 1px gap where the row has the hover state
      but the checkbox is not clickable we apply minus margin to the checkbox
      item.
    */
        margin-bottom: (0 - $dragged-dropzone-border-width);
        margin-top: (0 - $dragged-dropzone-border-width);
        padding: $spacing-smaller;
    }
}

.library-deliverable-table-customise-columns__dropdown-body {
    border-bottom: 1px solid $grey3;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 220px;
}

.library-deliverable-table-customise-columns__dropdown-button {
    margin: 0 0 0 $spacing-small;
}

.library-deliverable-table-customise-columns__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing $spacing-small ($spacing-small - 5px) $spacing-large;
}

.library-deliverable-table-customise-columns__option {
    align-items: center;
    border: $dragged-dropzone-border-width solid transparent;
    display: flex;
    justify-content: space-between;
    transition: background 0.2s linear;

    &:hover {
        background: $grey1;
    }
}

.library-deliverable-table-customise-columns__option--drop-position {
    border: $dragged-dropzone-border-width dashed $primary;

    &:hover {
        background: $white;
    }

    .library-deliverable-table-customise-columns__checkbox-container,
    .library-deliverable-table-customise-columns__drag-icon {
        opacity: 0;
        pointer-events: none;
    }
}

.library-deliverable-table-customise-columns__single-checkbox-wrapper {
    border-bottom: 1px solid $grey3;
    transition: background 0.2s linear;

    &:hover {
        background: $grey1;
    }

    .ivu-checkbox-wrapper {
        display: block;
        margin: 0;
        padding: $spacing-small $spacing-small;
    }
}
