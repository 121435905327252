
@import "@/../sass/_variables.scss";
.chatgpt-preview {
    &__chat-response {
        padding: $spacing;
        width: 100%;
        height: 100%;
        overflow: auto;
        white-space: pre-line;
    }
    .imagine-preview__overlay {
        .imagine-preview__result-element {
            padding: 0;
            text-align: left;
        }
    }
}
