
@import "../sass/variables";

@font-face {
    font-family: "WPP Light";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-light.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-light.otf) format("truetype");
}
@font-face {
    font-family: "WPP Regular";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-regular.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-regular.otf) format("truetype");
}
@font-face {
    font-family: "WPP Medium";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-medium.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-medium.otf) format("truetype");
}
@font-face {
    font-family: "WPP Bold";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-bold.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-bold.otf) format("truetype");
}

html {
    overflow-y: scroll;
}

.ivu-btn-primary {
    color: #fff;
    background-color: $ci-blue;
    border-color: $ci-blue;
}

.theme-creative-insights,
.hox-modal__wrapper--creative-insights {
    font-family: "WPP Regular", sans-serif;
}

.main-content {
    background-color: transparent;
}

.ivu-layout-sider {
    background-color: transparent;
}
