
@import "@/../sass/_variables.scss";

.set-banner-status-dropdown {
    display: inline-block;
}

.set-banner-status-dropdown__select-arrow {
    font-size: $font-size-small;
    margin: 0 0 0 2px;
    transition: transform 0.2s linear;
}

.set-banner-status-dropdown__select-arrow--active {
    transform: rotate(180deg);
}
