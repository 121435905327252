
@import "../../../sass/variables";

.campaign-locked-notice {
    text-align: center;
    align-content: center;
    justify-content: center;
    align-self: center;
    margin: $spacing-small auto;

    &--table-view {
        width: 900px;
    }
}
