
@import "@/../sass/_variables.scss";

.insights-dashboard-tag-component__layout {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: $spacing-small;

        &-name {
            cursor: pointer;
            margin-right: auto;
        }
    }

    &-body {
        width: 100%;
        height: 380px;
    }

    &-footer {
        width: 100%;
        display: flex;
    }

    &-error-message {
        margin-top: $spacing-larger;
    }

    .ivu-card-body {
        width: 100%;
        height: 100%;
    }
}
.insights-dashboard-tag-component_settings {
    .insights-dashboard-tag-component__layout {
        &-body {
            width: 260px;
            align-self: center;
            height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__footer-button {
        &--delete {
            margin-right: auto;
        }

        &--save {
            margin-left: $spacing-semi-small;
        }
    }
}
