
@import "@/../sass/_variables.scss";

.loading-layer {
    line-height: 0;
    position: fixed;

    &--position-absolute {
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: $zindex-loading-layer;
    }

    &__spinner {
        flex: 0;

        &--size-base {
            font-size: 60px;
        }

        &--size-large {
            font-size: 120px;
        }

        &--size-small {
            font-size: 30px;
        }

        &--size-smaller {
            font-size: 16px;
        }
    }

    &--theme-light {
        background: rgba($white, 0.9);
        color: $cmp-light-font-color;

        &.loading-layer--opaque {
            background: $white;
        }
    }

    &--theme-dark {
        background: rgba($cmp-dark-tertiary-bg-color, 0.7);
        color: $grey3;

        &.loading-layer--opaque {
            background: $cmp-dark-tertiary-bg-color;
        }
    }

    &--transparent {
        background: none;
    }

    &__message {
        font-weight: bold;
        font-size: 20px;
        color: black;
        padding-bottom: 30px;
    }
}
.ivu-spin-show-text .ivu-spin-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
