@carousel-prefix-cls: ~"@{css-prefix}carousel";
@carousel-item-prefix-cls: ~"@{css-prefix}carousel-item";

.@{carousel-prefix-cls} {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &-track, &-list {
        transform: translate3d(0, 0, 0);
    }

    &-list {
        position: relative;
        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;
    }

    &-track {
        position: relative;
        top: 100%;
        left: 0;
        display: block;
        overflow: hidden;
        z-index: 1;
        &.higher {
            z-index: 2;
            top: 0;
        }
    }

    &-item {
        float: left;
        height: 100%;
        min-height: 1px;
        display: block;
    }

    &-arrow {

        border: none;
        outline: none;

        padding: 0;
        margin: 0;

        width: 36px;
        height: 36px;
        border-radius: 50%;

        cursor: pointer;

        display: none;

        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);

        transition: @transition-time;
        background-color: rgba(31, 45, 61, .11);
        color: #fff;

        &:hover {
            background-color: rgba(31, 45, 61, 0.5);
        }

        text-align: center;
        font-size: 1em;

        font-family: inherit;
        line-height: inherit;

        & > * {
            vertical-align: baseline;
        }

        &.left {
            left: 16px;
        }

        &.right {
            right: 16px;
        }

        &-always {
            display: inherit;
        }

        &-hover {
            display: inherit;

            opacity: 0;
        }
    }

    &:hover &-arrow-hover {
        opacity: 1;
    }

    &-dots {
        z-index: 10;

        @padding: 7px;

        display: none;

        position: relative;
        &-inside {
            display: block;
            position: absolute;
            bottom: 10px - @padding;
        }

        &-outside {
            display: block;
            margin-top: 10px - @padding;
        }

        list-style: none;

        text-align: center;

        padding: 0;
        width: 100%;
        height: 3px + @padding * 2;

        li {
            position: relative;
            display: inline-block;

            vertical-align: top;
            text-align: center;

            margin: 0 2px;
            padding: @padding 0;

            cursor: pointer;

            button {
                border: 0;
                cursor: pointer;

                background: #8391a5;
                opacity: 0.3;

                display: block;
                width: 16px;
                height: 3px;

                border-radius: 1px;
                outline: none;

                font-size: 0;
                color: transparent;

                transition: all .5s;
                &.radius {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                }
            }

            &:hover > button {
                opacity: 0.7;
            }

            &.@{carousel-prefix-cls}-active > button {
                opacity: 1;
                width: 24px;
                &.radius{
                    width: 6px;
                }
            }
        }
    }
}
