
@import "@/../sass/_variables.scss";

.metadata-field-form__error-message-wrapper--top-spacing {
    padding: $spacing 0 0;
}

.metadata-field-form__form-error-message {
    color: $error-color;
    font-size: $font-size-small;
    margin: $spacing-small 0 $spacing-small;
    line-height: $font-size-large;
}

.metadata-field-form__instructions {
    color: $grey5;
    font-size: $font-size-small;
    margin: $spacing-small 0 0;
    line-height: $font-size-small;
    clear: both;
}
