@card-prefix-cls: ~"@{css-prefix}card";

.@{card-prefix-cls}{
    background: #fff;
    border-radius: @border-radius-small;
    font-size: @font-size-base;
    position: relative;
    //overflow: hidden;
    transition: all @transition-time @ease-in-out;

    &-bordered {
        border: 1px solid @border-color-base;
        border-color: @border-color-split;
    }

    &-shadow{
        box-shadow: @shadow-card;
    }

    &:hover {
        box-shadow: @shadow-base;
        border-color: #eee;
    }
    &&-dis-hover:hover{
        box-shadow: none;
        border-color: transparent;
    }

    &&-dis-hover&-bordered:hover{
        border-color: @border-color-split;
    }

    &&-shadow:hover{
        box-shadow: @shadow-card;
    }

    &-head {
        .content-header;
    }

    &-extra {
        position: absolute;
        right: 16px;
        top: 14px;
    }

    &-body {
        padding: 16px;
    }
}