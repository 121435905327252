
@import "@/../sass/_variables.scss";

.add-account-modal {
    &--extended {
        .hox-modal {
            width: 900px;
        }
        &__form {
            width: auto;
            padding-right: 10px;
        }
    }
    &__form {
        width: 100%;
        padding-right: 0;
        .ivu-form-item {
            margin-bottom: 10px;
            &.ivu-form-item-error {
                margin-bottom: 20px;
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
    }
    &__wrap {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 10px;
    }

    &__info {
        display: flex;
        align-items: center;
    }
    .hox-modal {
        width: 554px;
        &__header {
            margin-bottom: 0;
        }
        &__header-container {
            padding: 30px 30px 0;
        }
        &__body {
            padding: 2px 30px 30px;
        }
        p {
            color: #121619;
        }
    }
    .ivu-alert {
        border: none;
        background-color: #e7eaee;
        padding: 5px 8px 5px 32px;
        line-height: 22px;
        &-icon {
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
