
.table-checkbox-group {
    &--ghost {
        border-color: transparent;

        .ivu-table {
            &::before,
            &::after {
                background-color: inherit;
            }

            th,
            td {
                background-color: inherit;
                border-color: transparent;
                height: 25px;
            }

            table {
                border-color: transparent;
                background-color: inherit;
            }
        }
    }
}
