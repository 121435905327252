
@import "../../../sass/variables";

.report-kpi-selector {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
        margin-right: $spacing-small;
    }
}
