
.creative-insights-data-explorer {
    width: 100%;

    &__card {
        width: 100%;
        min-height: 200px;
    }

    &__chart {
        width: 100%;
    }
}
