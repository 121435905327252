
@import "@/../sass/_variables.scss";

.qa-drawer-select-templates {
    display: inline-block;
    margin-left: $spacing-smaller;

    .ivu-checkbox-wrapper {
        display: block;
        margin: 0;
        padding: $spacing-small $spacing $spacing-small $spacing-smallest;
    }

    .ivu-dropdown-item {
        padding: 0;
    }
}

.qa-drawer-select-templates__container {
    display: flex;
    flex-direction: column;
}

.qa-drawer-select-templates__body {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
}
