
@import "../../sass/_variables.scss";

$logo-height: 200px;

.client-card__logo {
    max-height: 75%;
    max-width: 75%;
    flex: 0 0 auto;
}

.client-card__logo-container {
    align-items: center;
    color: $grey5;
    display: flex;
    height: $logo-height;
    justify-content: center;
}

.client-card__missing-logo {
    align-items: center;
    display: flex;
    height: $logo-height;
    font-size: 80px;
    justify-content: center;
}
