
@import "@/../sass/_variables.scss";

.table-row-column {
    position: relative;
    white-space: nowrap;
    width: auto;

    &--sticky-left {
        left: 0;
        position: sticky;
        z-index: 2;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            border-right: 1px solid $grey3;
        }
    }

    &--sticky-right {
        position: sticky;
        right: 0;
        z-index: 2;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            border-left: 1px solid $grey3;
        }
    }

    &--strong-border-left:after {
        content: "";
        position: absolute;
        left: -1px;
        bottom: 0;
        height: 100%;
        border-left: 3px double $grey3;
    }
}

.table-row-column__content {
    padding: $spacing-small $spacing-semi-small;
    max-width: 100%;

    &--overflow-hidden {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
