
@import "@/../sass/_variables.scss";

.table-row {
    /*
    We give a background colour to the td element instead of tr to account for
    sticky columns.
  */
    td {
        background: $white;
    }

    &:nth-child(2n) {
        td {
            background: $grey0;
        }
    }
}
