
.labelled-select {
    display: flex;
    flex: 1 0;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &__tag {
        flex: 0 0 80px;
    }

    &__text {
        flex: 1 0;
        display: flex;
        overflow: hidden;
        padding: 0 5px;
        align-items: center;
    }
}
