
@import "@/../sass/_variables.scss";

.insights-creative-preview {
    margin: $spacing;
    width: 300px;
    height: 315px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;

    & .dashboard-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .card__footer {
        padding-left: 0;
    }

    &__rank {
        .kpi-performance__results-kpi {
            border: 0;
            //margin-left: -$spacing;
        }
    }

    &__video,
    &__image {
        display: block;
        max-height: 224px;
        max-width: 268px; // 300 -2* 16 padding
        object-fit: contain;
    }

    &__image {
        image-rendering: -webkit-optimize-contrast;
    }

    &__attribute-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 250px;
        padding: $spacing;

        h4 {
            align-self: flex-start;
            text-transform: uppercase;
        }

        h2 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }

    .insights-creative-preview__content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &--no-data {
            width: 250px;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $ci-font-color-secondary;
        }

        &-base {
            position: relative;
            z-index: 1;
        }
        &-overlays {
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            z-index: 2;
        }

        &-overlay {
            position: absolute;
            background: transparent;
            border: 1px #b1aef4 solid;

            &-header {
                background-color: #b1aef4;
                max-width: 90px;
                font-size: 8px;
                color: white;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 12px;
                right: 0;
                position: absolute;
                margin-top: -12px;
            }
        }
    }

    .insights-creative-preview__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ci-font-color-secondary;

        &--no-data {
            height: 22px;
        }

        &-item {
            padding: 0 $spacing-small;

            &-value {
                padding: 0 $spacing-small;
            }
        }
    }

    .insights-creative-preview__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-name {
            max-width: 240px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: $spacing;
        }

        &-quadrant {
            white-space: nowrap;
        }
    }
}
