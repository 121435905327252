
@import "@/../sass/_variables.scss";

.qa-drawer-header__action-items-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 0 $spacing;

    .qa-drawer-header__action-buttons {
        margin-bottom: 0;
        align-self: flex-start;
    }

    &--bulk {
        .qa-drawer-header__action-buttons {
            margin-bottom: $spacing;
        }
    }

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    .ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}

.qa-drawer-header__action-item {
    display: inline-block;
    margin: 12px $spacing-small 0 0;
}

.qa-drawer-header__action-item--extra-spacing {
    display: inline-block;
    margin: 0 $spacing-large 0 0;
}

@media all and (min-width: 1600px) {
    .qa-drawer-header__action-items-container {
        flex-direction: row;

        .qa-drawer-header__action-buttons {
            margin-bottom: 0;
        }

        .qa-drawer-header__bulk-page {
            margin-top: 12px;
            margin-left: auto;
            margin-right: 0;
        }
    }
}
