
.tag-list {
    .ivu-tag {
        font-size: 10px;
        height: auto;
        text-transform: uppercase;

        .ivu-icon-ios-close {
            margin-top: 1px;
        }
    }
}
