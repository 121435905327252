
@import "../../../sass/_variables.scss";

.ae-banner__loading-spinner-wrapper {
    color: $cmp-light-font-color;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
