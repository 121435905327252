
@import "../../../sass/variables";
.read-text {
    position: relative;
    width: 20px;
    height: 20px;
    .loading-spinner__icon {
        display: block;
        height: 20px;
        width: 20px;
    }
    &__ready-icon {
        color: $purple;
    }
}
