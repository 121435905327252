
@import "@/../sass/_variables.scss";

.ci-tag {
    width: 100%;
    padding: 0 $spacing-small 0 $spacing-large;
    line-height: 40px;
    height: 40px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__name {
        flex: 1 1;
        text-overflow: ellipsis;
        overflow: hidden;
        color: currentColor;
        white-space: nowrap;
    }

    &--selected {
        color: $ci-blue;
        background-color: $ci-blue-bg;
        box-shadow: inset -3px 0px 0px #1890ff;
    }
}
