
.meta-is {
    width: 100%;
    background-color: #bdbdbd;
    border-top: 1px solid #ccd0d5;
    border-bottom: 1px solid #ccd0d5;
    margin-bottom: 20px;
    position: relative;

    &__info,
    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
        animation: disappear-reappear-overlay 25s linear infinite;
        opacity: 0;
    }

    &__info {
        z-index: 2;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        font-weight: 600;
        font-size: 18px;

        a {
            display: inline-block;
            color: #fff !important;
            text-decoration: none;
            font-size: 12px;
            margin: 0 0 40px;
        }

        button {
            color: #000;
            background: #fff;
            border-radius: 20px;
            border: 0;
            padding: 10px 15px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    &__header {
        position: relative;
        z-index: 3;
        display: flex;
        align-items: center;
        padding: 20px 10px 10px;

        .meta-is__avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            i {
                width: 100%;
                height: 100%;
                font-size: 42px;
                color: #fff;
            }
        }

        .meta-is__user-info {
            flex-grow: 1;

            .meta-is__user-name {
                font-weight: bold;
                font-size: 14px;
                color: #fff;
                line-height: 15px;
            }

            .meta-is__sponsored {
                font-weight: normal;
                font-size: 12px;
                color: #fff;
            }

            .meta-is__time {
                color: #fff;
                font-size: 12px;
            }
        }

        .meta-is__options {
            font-size: 18px;
            color: #fff;
            cursor: pointer;
            i {
                &:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
    &__progress {
        height: 100%;
        width: 30%;
        background-color: #ffffff;
        animation: progressAnimation 15s linear infinite;

        &-bar {
            height: 2px;
            background-color: rgba(205, 205, 205, 0.5);
            border-radius: 3px;
            position: absolute;
            top: 10px;
            left: 7px;
            width: calc(100% - 14px);
            z-index: 3;
        }
    }

    &__content {
        padding: 16px 0;

        .meta-is__text {
            font-size: 14px;
            padding: 0 16px;
            text-align: left;
            margin: 10px 0 45px;
            color: #333;
            font-weight: 600;
        }

        .meta-is__image {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        padding: 0 16px;
        span {
            display: inline-block;
            line-height: 0px;
            font-size: 40px;
            position: relative;
            margin-bottom: -10px;
        }
        &-btn {
            flex-grow: unset;
            border: 0;
            margin: 10px auto auto;
            font-size: 24px;
            color: #2e4386;
            padding: 0 10px;
            background: #fff;
            border-radius: 6px;
            animation: disappear-reappear 25s linear infinite;
        }
    }
    &__footer {
        padding: 0 16px 12px 16px;
    }
}
@keyframes progressAnimation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@keyframes disappear-reappear {
    0% {
        opacity: 1;
    }
    78% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    98% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes disappear-reappear-overlay {
    0% {
        opacity: 0;
    }
    78% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    98% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
