// sortable
.sortable() {
    display: inline-block;
    width: 14px;
    height: 12px;
    margin-top: -1px;
    vertical-align: middle;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    i {
        display: block;
        height: 6px;
        line-height: 6px;
        overflow: hidden;
        position: absolute;
        color: @btn-disable-color;
        transition: color @transition-time @ease-in-out;
        font-size: @font-size-large;

        &:hover{
            color: inherit;
        }

        &.on{
            color: @primary-color;
        }

        &:first-child{
            top: 0;
        }
        &:last-child{
            bottom: 0;
        }
    }
}