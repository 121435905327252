
@import "@/../sass/_variables.scss";

.new-size-modal {
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__predefined-values {
        width: 100%;
        margin-bottom: $spacing;

        &-right {
            float: right;
            color: #cccccc;
        }
    }

    &__form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-smaller;

        &-item {
            flex: 0 0;
            margin: 0 $spacing-small;
        }

        &-input-wrapper {
            display: block;
            white-space: nowrap;
            margin: 0 $spacing-smaller;
        }

        &-input {
            width: 75px;

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }
        }
    }

    &__form-errors {
        margin-bottom: $spacing;
        color: $red;
    }

    &__banner .campaign-banner-footer__options,
    &__banner .campaign-banner-footer__controls {
        display: none;
    }

    &__footer-button {
        margin: 0 0 0 $spacing-small;
    }
    &__error {
        width: 100%;
        margin-top: $spacing-large;
    }
}
