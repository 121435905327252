
@import "@/../sass/_variables";

.network-error {
    margin: 50px auto;
    text-align: center;

    p {
        margin: 10px 0;
    }
}

pre {
    white-space: normal;
    background: rgba($color: #000000, $alpha: 0.8);
    padding: $spacing-base;
    display: inline-block;
    border-radius: $border-radius-larger;
    box-shadow: $box-shadow-base;

    code {
        display: block;
        text-align: left;
        color: white;
        border-radius: 5px;
        white-space: normal;

        &:before {
            content: "$ ";
        }
    }
}
