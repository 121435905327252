
@import "@/../sass/_variables.scss";
.dropoff-chart {
    height: 215px;
    padding: $spacing-small 0 $spacing-semi-small 0;
    border: 1px solid $grey3;
    width: calc(100% - 10px);

    &--no-metrics {
        height: 45px;

        .dropoff-chart-wrapper {
            height: 45px;
        }
    }

    &-chart {
        width: calc(100% - 10px);
    }

    &-wrapper {
        height: 205px;
    }
}
