
@import "@/../sass/_variables.scss";

.master-templates-table {
    height: calc(100% - 75px);
    flex: 1;
    margin-top: $spacing-large;
    padding: 0 $spacing-semi-large $spacing $spacing-semi-large;

    &-paging {
        flex: 0 0 30px;
        text-align: right;
        padding: 0 $spacing-small $spacing-smallest $spacing-small;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .reporting-deviation-arrow {
        display: inline-block;
    }

    &__empty-string {
        color: $grey5;
    }

    .table-header-column--group-header {
        font-weight: bold;
    }
}
