
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.qa-annotations {
    .ivu-collapse > .ivu-collapse-item {
        border: none;
        border-bottom-color: transparent;

        &.ivu-collapse-item-active {
            border-bottom-color: $grey3;
        }

        > .ivu-collapse-header {
            border-bottom: 1px solid $grey3;
        }
    }

    .collapsible-widget.ivu-collapse .ivu-collapse-content > .ivu-collapse-content-box {
        border-bottom: 1px solid $grey3;
    }
}

.qa-annotations__annotations {
    background: $cmp-light-bg-color;
    padding: $spacing-small $spacing-small 0;

    @include clearfix();
}

.qa-annotations__title {
    font-size: $font-size-base;
}

.qa-annotations__no-annotations-message {
    background: $white;
    border: 1px dashed $grey3;
    color: $grey5;
    margin: 0 0 $spacing-small;
    padding: $spacing $spacing $spacing-small;

    > p {
        font-size: $font-size-small;
        margin-bottom: $spacing-small;
    }
}
