
dt {
    font-weight: bold;
    padding-left: 10px;
}

dd {
    margin-left: 30px;
}

.livebanner-wrapper--resizable {
    width: 100%;
    height: 100%;
}

.banner-preview {
    position: relative;
    justify-content: center;
}
