
@import "@/../sass/_variables.scss";

.filter-tree-select__tree-wrapper {
    min-width: 250px;
    max-width: 400px;
    padding: 0 $spacing-small;

    &-input {
        margin: 0 $spacing-small 5px $spacing-small;
    }
}
.filter-tree-select__dropdown {
    max-height: 80vh;
}

.filter-tree-select__tree {
}
