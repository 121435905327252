
@import "@/../sass/_variables.scss";

.qa-single-annotation-comment {
    border-bottom: 1px solid $grey3;
    margin: 0 (0 - $spacing) 0 0;
    padding: $spacing $spacing $spacing-small;

    &:last-child {
        border-bottom: none;
    }
}

.qa-single-annotation-comment__action-items {
    display: flex;
}

.qa-single-annotation-comment__body {
    font-size: $font-size-small;
    margin: 0 0 $spacing-small;
    white-space: pre-wrap;
}

.qa-single-annotation-comment__date {
    color: $grey5;
    font-size: $font-size-small;
}

.qa-single-annotation-comment__edit-button {
    margin: 0 0 0 $spacing-small;
}

.qa-single-annotation-comment__edit-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding: $spacing-small 0 0 0;
}

.qa-single-annotation-comment__edit-container {
    margin: 0 0 $spacing-small;
}

.qa-single-annotation-comment__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-small;
}

.qa-single-annotation-comment__info {
    overflow: hidden;
    padding: 0 $spacing-smaller 0 0;
}

.qa-single-annotation-comment__user-name {
    font-size: $font-size-small;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
