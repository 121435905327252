
@import "@/../sass/_variables.scss";

.timeline-analysis {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: $spacing-small;
    pointer-events: visible;

    &__body {
        flex: 1 1 auto;
        display: flex;
        height: auto;
        overflow: auto;
        padding: 0 $spacing 0 80px;
    }

    &__preview {
        width: 300px;
        margin-right: $spacing;
        flex: 0 0 300px;
    }

    &-wrapper {
        height: 100%;
        width: 100%;
    }

    &__overlays {
        position: absolute;
        top: 30px;
        left: $spacing;
        z-index: 0;
        width: 100%;
        height: calc(100% - 30px);
        pointer-events: none;

        &-playhead {
            position: absolute;
            height: 100%;
            width: 2px;
            background-color: $primary;
            left: 14px;
        }
    }

    &__scroll-wrapper {
        padding-top: $spacing-large;
        overflow: hidden scroll;
        width: 100%;
        height: 100%;
        flex: 1;
        min-height: 80px;
        position: relative;
        padding-right: $spacing;
        padding-bottom: $spacing;
    }

    &__chart {
        height: calc(100% - 120px);
        //width: 100% !important;
        flex: 1 1;

        &-card {
            position: relative;
            padding-right: $spacing-smaller;
            margin-bottom: $spacing-small;
        }
    }

    &__video {
        max-width: 300px;
        height: 100%;
        object-fit: contain;
        max-height: 90vh;
        min-height: 200px;
        display: block;
        margin: 0 auto;
    }

    &__footer {
        align-items: center;
        border-top: 1px solid $grey3;
        display: flex;
        padding: $spacing-small $spacing-large;
        flex: 0 0 auto;

        .timeline-slider {
            align-items: center;
            display: flex;
            flex: 1 0;
            width: 100%;
        }

        .ivu-slider-wrap {
            margin: 9px ($spacing + 9) 9px 9px;
        }
    }

    &__scrubbar-container {
        flex: 1;
        position: relative;
    }

    &__video-card {
        padding: 0;
        margin: $spacing 0;
    }

    .kpi-performance__results {
        justify-content: space-between;
        &-kpi {
            width: 69px;
            display: flex;
            align-items: center;
            text-align: center;
            flex: 1 1;
        }
    }

    &__filters {
        label {
            width: 100%;
        }
    }
}
