
@import "../../../sass/_variables.scss";

$logo-height: 200px;

.campaign-card__logo {
    align-items: center;
    background: no-repeat center;
    background-size: cover;
    color: $grey5;
    display: flex;
    height: $logo-height;
    justify-content: center;
    width: 100%;
}

.campaign-card__logo--ci-demo {
    background-size: contain;
    max-height: 75%;
    max-width: 75%;
    flex: 0 0 auto;
    margin: 0 auto;
}

.campaign-card__missing-logo {
    align-items: center;
    display: flex;
    height: $logo-height;
    font-size: 80px;
    justify-content: center;
}
