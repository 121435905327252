
@import "../../../sass/variables";

.attached-panel {
    .editable-widgets {
        height: 100vh;
    }
}

.detached-panel {
    .editable-widgets {
        min-height: 80px;
    }

    &.editables-wrapper {
        height: 100%;
        overflow: hidden auto;
    }
}

.editable-widgets {
    height: 100%;
    /*overflow: initial !important; when present allows the content to overflow parent and reveals the native scrollbarq*/

    .expand-editables {
        font-size: 12px;
        position: absolute;
        top: -30px;
        left: 8px;
    }

    &__no-matches {
        margin: $spacing-large;
        color: $grey5;
    }

    .last-interaction.ivu-collapse > .ivu-collapse-item {
        &.ivu-collapse-item-active {
            .ivu-collapse-header {
                background: $cmp-dark-active;
            }
        }
    }

    &--with-scrollbar {
        .editable-widgets__inner-content {
            width: calc(100% - 15px);
            margin-bottom: 75px;
        }
    }

    .editable-widget__body {
        position: relative;

        &--readonly {
            background: $cmp-dark-bg-color;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
        }
    }
}
