
@import "@/../sass/_variables.scss";

.font-upload {
    margin: 0 0 $spacing;

    .ivu-upload-drag {
        color: $link-color;
        transition: background, border-color, color;
        transition-timing-function: $transition-function-base;
        transition-duration: $transition-time-base;

        &:hover {
            color: $link-hover-color;
            border-color: $link-hover-color;
        }
    }

    .ivu-upload-dragOver {
        background: rgba($link-color, 0.15);
        border: 1px dashed $link-hover-color;
        color: $link-hover-color;
    }

    &__error-message {
        color: $error-color;
        font-size: $font-size-small;
        margin: 0 0 $spacing;
    }

    &__font {
        max-width: auto;
        max-height: 150px;
        display: block;
        border: 1px solid $cmp-light-border-color;
        padding: $spacing-smallest;
        margin: 0 0 $spacing-small;
    }

    &__label {
        color: $cmp-light-secondary-font-color;
        display: inline-block;
        font-size: $font-size-base;
        margin: 0 0 $spacing-small;
    }

    &__upload-wrapper {
        position: relative;
        margin: 0 0 $spacing-small;
    }

    &__uploader {
        font-size: $font-size-base;
        width: 100%;
        text-align: center;
        cursor: pointer;
        padding: $spacing-large $spacing;

        .ivu-icon {
            margin: 0 0 $spacing-small;
        }
    }
}
