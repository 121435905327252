
@import "@/../sass/_variables.scss";

.library-deliverable-table-action-items-cell {
    bottom: 0;
    color: $link-color;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition:
        background 0.2s linear,
        color 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $grey3;

    &:hover {
        background: $grey1;
        color: $link-hover-color;
    }
}
