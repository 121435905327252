
.user-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;

    &__wrapper {
        margin: 50px auto;
        text-align: center;
    }

    h1 {
        font-size: 32px;
        margin-bottom: 25px;
    }

    p {
        font-size: 14px;
        margin: 10px 0;
    }
}
