
@import "../../sass/_variables";

.media-library__client-select {
    position: relative;

    &-title {
        margin-bottom: $spacing-base;
    }

    &-grid {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        &-card {
            width: 25%;
            padding: 0 $spacing-base;

            &:nth-child(1n) {
                padding-left: 0;
            }

            &:nth-child(4n) {
                padding-right: 0;
            }
        }
    }
}
