
@import "../../sass/variables";

$silka-family: "Silka", "Open Sans", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "微软雅黑", Arial, sans-serif;

.team-name {
    font-family: $silka-family;
    font-weight: 700;
    font-size: 19px;
    color: $hogarth-yellow;
}
.hogarth-ic-logo {
    margin-bottom: 41px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--large {
        .hogarth-logo {
            width: 100%;
        }
    }

    .hogarth-logo {
        width: 50%;
        height: 50%;
        margin-bottom: $spacing-small;
    }

    .ipe {
        font-size: 12.7px;
        font-family: $silka-family;
        margin-bottom: $spacing-large;
    }
}
