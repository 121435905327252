
@import "../../../sass/_variables.scss";

.creative-insights-nav {
    align-items: center;
    background-color: $creative-insights-nav-bg;
    display: flex;
    flex-direction: column;
    flex: 0 0 $creative-insights-vertical-nav-width;
    height: 100vh;
    justify-content: space-between;
    left: 0;
    padding: $spacing-small 0 0 0;
    position: fixed;
    top: 0;
    width: $creative-insights-vertical-nav-width;
    z-index: $zindex-creative-insights-nav;
}

.creative-insights-nav__home-button-wrapper {
    margin-bottom: 90px;
}
