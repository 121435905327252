
@import "@/../sass/_variables.scss";
.timeline-analysis-chart {
    margin-top: -6px;
    &__wrapper {
        position: relative;
    }
    &__chart {
        position: relative;
        width: 100% !important;

        /* https://github.com/jtblin/angular-chart.js/issues/614 */
    }
}
