
@import "@/../sass/_variables.scss";
.user-feedback-drawer {
    &__iframe-wrapper {
        width: 600px;
        height: 100%;
    }

    iframe {
        height: calc(100% - 17px) !important;
        padding-top: 16px;
    }
    &__body {
        height: 100%;
        flex: 1;
        border: 1px solid #0d3349;
    }

    &__container {
        transform: translateZ(0);
        overflow: hidden;
    }

    &__header {
        padding: $spacing $spacing-large;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-select {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
        }

        &-breakdown {
            margin-right: $spacing;
        }
    }
    .ivu-drawer-right {
        .ivu-drawer-close {
            top: 5px;
            z-index: 2;
            color: #000;
        }
    }
}
