.ivu-article {
    h1{
        font-size: 26px;
        font-weight: normal;
    }
    h2{
        font-size: 20px;
        font-weight: normal;
    }
    h3{
        font-size: 16px;
        font-weight: normal;
    }
    h4{
        font-size: 14px;
        font-weight: normal;
    }
    h5{
        font-size: 12px;
        font-weight: normal;
    }
    h6{
        font-size: 12px;
        font-weight: normal;
    }

    blockquote{
        padding: 5px 5px 3px 10px;
        line-height: 1.5;
        border-left: 4px solid #ddd;
        margin-bottom: 20px;
        color: #666;
        font-size: 14px;
    }

    ul:not([class^="ivu-"]){
        padding-left: 40px;
        list-style-type: disc;
    }
    li:not([class^="ivu-"]){
        margin-bottom: 5px;
        font-size: 14px;
    }
    ul ul:not([class^="ivu-"]), ol ul:not([class^="ivu-"]){
        list-style-type: circle;
    }

    p{
        margin: 5px;
        font-size: 14px;
    }

    a:not([class^="ivu-"])[target="_blank"]:after{
        content: "\F3F2";
        font-family: Ionicons;
        color: #aaa;
        margin-left: 3px;
    }
}