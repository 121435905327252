
@import "@/../sass/_variables.scss";

.editable-group-checkbox-list {
    & > .ivu-collapse > .ivu-collapse-item-active .ivu-collapse-header {
        border-bottom: 1px solid $cmp-light-filters-border-color;
    }

    .collapsible-widget--small {
        &.ivu-collapse > .ivu-collapse-item {
            > .ivu-collapse-header {
                padding: 0 0 0 $spacing-large;
                border: none;

                &:hover {
                    background: $cmp-light-filters-hover-color;
                }
            }
        }
    }
}
