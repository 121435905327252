
@import "@/../sass/_variables.scss";

.qa-status-label {
    display: inline-block;
}

.qa-status-label--display-block {
    display: block;

    .ivu-tag {
        text-align: center;
        width: 100%;
    }
}

.qa-status-label--pointer-events-none {
    pointer-events: none;
}
