
@import "@/../sass/_variables.scss";

.importExportModal {
    &__navigation {
        margin-bottom: $spacing;
    }

    &__howto {
        &-list {
            padding-left: $spacing;
        }
    }

    &__files {
        padding-left: $spacing;
        margin-bottom: $spacing;
    }

    &__upload-result {
        margin-top: $spacing;
    }

    &__upload-results-error-list {
        padding-left: $spacing;
        word-break: break-word;
    }

    &__upload-controls {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .labelled-switch {
            flex: 0;

            &__label {
                white-space: nowrap;
            }
        }
    }
}
