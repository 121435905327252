
@import "@/../sass/_variables.scss";

.reporting-tags-list {
    width: 100%;
    overflow: auto;
    height: 100%;

    section {
        margin-bottom: $spacing;
    }

    h4 {
        padding-left: $spacing;
        text-transform: capitalize;
    }
}
