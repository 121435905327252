
@import "../sass/variables";

.theme-default {
    .ivu-layout-sider {
        left: auto;
    }
}

.hoxton-footer {
    position: sticky;
    left: 0;
    bottom: 0;
    padding-left: $spacing-small;
    width: $campaign-vertical-nav-width;
    z-index: $zindex-footer;
}
