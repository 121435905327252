
@import "../../../../sass/_variables.scss";

.position-analysis {
    max-width: 1100px;

    display: grid;
    gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;

    &__item {
        padding: 10px;
    }
}
