
@import "../../../sass/variables";

.sandbox-no-templates {
    background: $campaign-nav-bg;
    color: $cmp-dark-font-color;
    width: calc(100% - #{$campaign-vertical-nav-width});
    height: calc(100vh - #{$campaign-nav-height} - #{$campaign-main-content-padding-top});
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &__content {
        padding: $spacing-large;

        h2 {
            flex: 0;
            font-weight: normal;
            font-size: 28px;
            text-align: center;
            max-width: 400px;
            margin: 0 auto 50px;
        }

        p {
            margin-bottom: $spacing-base;
        }

        ol {
            padding: $spacing-small $spacing-large;
            margin-bottom: $spacing-base;
        }

        &-instructions {
            padding: $spacing-large;
            background: $cmp-dark-bg-color;
        }
    }
}
