
@import "@/../sass/_variables.scss";

.qa-creative__body {
    background: $cmp-light-bg-color;
    display: flex;
    flex: 1;

    height: auto;
    position: relative;
    overflow: auto;
}

.qa-creative__body-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    overflow: auto;
    padding: $spacing-large $spacing-large $spacing;
    width: 100%;
}

.qa-creative__alert-wrapper {
    width: 100%;
}

.qa-creative__banner-wrapper {
    display: inline-block;
    vertical-align: top;

    &--visible {
        margin: 0 $spacing $spacing 0;

        &.qa-creative__banner-wrapper--single-visible {
            margin: 0 auto $spacing auto;
        }
    }
}

.qa-creative__empty-message {
    flex: 1;
    margin: auto;
}

.qa-creative__has-selection {
    .qa-banner {
        opacity: 0.75;

        &--selected {
            opacity: 1;
        }
    }
}
