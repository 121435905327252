
@import "../../../sass/variables";

.client-select {
    &-inner {
        .ivu-select-selection {
            right: 0;
        }

        &.ivu-select-visible {
            .ivu-select-selection {
                box-shadow: none;
            }
        }

        .ivu-select-selection {
            border: none;
            background-color: inherit;
            color: inherit;

            .ivu-select-placeholder {
                color: inherit;
            }
        }
    }
}
