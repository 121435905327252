
@import "@/../sass/_variables.scss";

.notification-item {
    display: flex;
    padding: $spacing;
    transition: box-shadow $transition-time-base $transition-function-base;
    border-bottom: 1px solid #e7eaee;

    .notification-item__clear-button-container {
        display: none;
        margin: 0 0 0 $spacing-small;
        i {
            font-weight: bold;
            font-size: 22px;
            &:hover {
                color: #5e00b5;
            }
        }
    }

    &--padding-bottom-small {
        padding-bottom: $spacing-small;
    }

    &--show-clear-button {
        cursor: pointer;
        &:hover {
            .notification-item__clear-button-container {
                display: block;
            }
        }
    }
    &--completed {
        background: #e6fae8;
    }
    &--error {
        background: #ffe5ea;
    }
}

.notification-item__action-items {
    align-items: flex-end;
    display: flex;
    justify-self: flex-end;
    .notification-item__button-container {
        margin: 0 0 0 $spacing-small;
        i {
            color: #8b919a;
        }
        a {
            border: 0;
            background: none;
            &:visited {
                background: none;
            }
            &:hover {
                background: none !important;
                i {
                    color: var(--color-primary);
                }
            }
        }
        .ivu-btn {
            border: 0;
            background: none;
        }
    }
}

.notification-item__content {
    > p {
        font-size: $font-size-small;
        line-height: $line-height-base;
        padding: 0 0 $spacing-smaller;
    }

    > ul {
        padding: 0 0 0 $spacing-smaller;

        > li {
            font-size: $font-size-small;
            margin: 0 0 0 $spacing-base;
            padding: 0 0 $spacing-smaller;
        }
    }
}

.notification-item__content-container {
    flex: 1;
    overflow: hidden;
    align-self: center;
}

.notification-item__error-message {
    > p,
    li {
        color: $error-color;
        font-size: $font-size-small;
        line-height: $line-height-base;
        padding: 0 0 $spacing-smaller;
    }
}

.notification-item__expand-icon-container {
    align-items: center;
    color: $cmp-light-font-color;
    cursor: pointer;
    display: flex;
    height: $input-height-small;
    justify-content: center;
    margin: 0 0 0 $spacing-small;
    transition: color, border-color, transform;
    transition-duration: $transition-time-base;
    transition-timing-function: $transition-function-base;
    width: $input-height-small;

    &:hover {
        border-color: $link-hover-color;
        i {
            color: #5e00b5;
        }
    }

    &--active {
        border-color: $link-color;
        color: $link-color;
        transform: rotate(180deg);
    }
}

.notification-item__icon-container {
    margin: 0 $spacing-small 0 0;

    .ivu-icon {
        font-size: 40px;
    }

    &--flipped {
        .ivu-icon {
            transform: rotate(180deg);
        }
    }

    &--color-error {
        color: $error-color;
    }

    &--color-primary {
        color: $primary-color;
    }

    &--color-success {
        color: $success-color;
    }
}

.notification-item__header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-small;

    &--margin-bottom-none {
        margin-bottom: 0;
    }

    &--margin-bottom-smaller {
        margin-bottom: $spacing-smaller;
    }
}

.notification-item__percentage-container {
    color: $cmp-light-font-color;
    font-size: $font-size-small;
    margin: 0 0 0 $spacing-small;
}

.notification-item__subtitle {
    color: $cmp-light-font-color;
    font-size: $font-size-small;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-item__titles-container {
    font-size: $font-size-base;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-item__title {
    font-size: $font-size-base;
    font-weight: 400;
    color: #343a3f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
        color: #000;
        display: block;
    }

    &--color-primary {
        color: $primary-color;
    }

    &--color-error {
        color: $error-color;
    }

    &--color-success {
        color: $success-color;
    }
}
