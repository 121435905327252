
@import "@/../sass/_variables.scss";

.download-job-notification-item__sub-progress {
    margin-top: $spacing-small;
    padding-left: $spacing-base;
    border-left: 1px solid $cmp-light-border-color;

    &-label {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: $spacing-smaller;

        > :first-child {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
