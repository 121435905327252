
@import "@/../sass/_variables.scss";

.qa-drawer-sidebar {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: $spacing 0 0 0;
    height: 100%;
    width: 100%;
}

.qa-drawer-sidebar__no-deliverable {
    .qa-annotations__no-annotations-message {
        margin: 8px;
    }
}

.qa-drawer-sidebar__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 10px;

    .ivu-switch {
        margin-left: 5px;
    }

    .show-resolved-control {
        margin-left: auto;
    }
}

.qa-drawer-sidebar__content {
    flex: 1;
    position: relative;
}

.qa-drawer-sidebar__loading-message {
    background: $white;
    bottom: $spacing-small;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.2);
    font-size: $font-size-small;
    left: $spacing-small;
    padding: $spacing;
    position: absolute;
    right: $spacing-small;

    .ivu-spin {
        display: inline-block;
    }
}

.qa-drawer-sidebar__status-container {
    border-bottom: 1px solid $grey3;
    padding: 0 $spacing $spacing;
}

.qa-drawer-sidebar__title {
    font-size: $font-size-larger;
    margin: 0 $spacing $spacing-smaller;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
