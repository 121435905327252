
@import "@/../sass/_variables.scss";

.empty-message {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0 $spacing;
    width: 100%;
}

.empty-message__container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.empty-message__wrapper {
    max-width: 100%;

    > p {
        font-size: $font-size-large;
        margin: 0 0 $spacing;
    }
}

.empty-message__image-wrapper svg {
    max-width: 300px;
}

.empty-message__title {
    font-size: $font-size-largest;
    font-weight: normal;
    margin: 0 0 $spacing-small;
    text-align: center;
}
