
@import "@/../sass/_variables.scss";

.tab-carousel {
    position: relative;

    &-arrow {
        border: none;
        outline: none;

        padding: 0;
        margin: 0;

        width: 44px;
        height: 44px;
        border-radius: 50%;

        cursor: pointer;

        position: fixed;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $white;
        color: $cmp-light-font-color;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);

        &:focus {
            box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
        }

        &:hover {
            box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
        }

        text-align: center;
        font-size: 1em;

        &.left {
            left: $spacing-large;
        }

        &.right {
            right: $spacing-large;
        }
    }

    &.padding-semi-large {
        .left {
            left: $spacing-semi-large;
        }

        .right {
            right: $spacing-semi-large;
        }
    }

    &.padding-base {
        .left {
            left: $spacing-base;
        }

        .right {
            right: $spacing-base;
        }
    }

    &.padding-semi-small {
        .left {
            left: $spacing-semi-small;
        }

        .right {
            right: $spacing-semi-small;
        }
    }
}
