.size(@width; @height) {
    width: @width;
    height: @height;
}

.square(@size) {
    .size(@size; @size);
}

// fix chrome 12px bug, support ie
.iconfont-size-under-12px(@size, @rotate: 0deg) {
    display: inline-block;
    @font-scale: unit(@size / @font-size-base);
    font-size: @font-size-base;
    font-size: ~"@{size} \9"; // ie8-9
    transform: scale(@font-scale) rotate(@rotate);
    :root & {
        font-size: @font-size-base; // reset ie9 and above
    }
}
