
@import "../../../sass/variables";

.editable-group {
    &:hover .editable-group__feed-upload {
        display: flex;
        color: $white;
    }

    &:hover .editable-group__feed-more,
    &:hover .editable-group__refresh {
        display: flex;
        color: $darktheme100;
    }
    &__action {
        display: flex;
        gap: 5px;
        margin: 5px 0;
        color: #121619;
    }

    &__feed-more,
    &__feed-upload,
    &__refresh {
        display: none;
    }

    &__collapsible-widget .collapsible-widget__panel-controls {
        flex: 0 1;
        display: flex;
        justify-content: center;
        align-content: space-between;
        height: 100%;
    }

    &--editing {
        .ivu-collapse > .ivu-collapse-item {
            &.ivu-collapse-item-active {
                .ivu-collapse-header {
                    background: $cmp-dark-active;
                }
            }
        }
    }

    &__body {
        padding: 0;

        .ivu-radio-wrapper,
        .editable-group__controls {
            cursor: pointer;
            margin: 0;
            line-height: 30px;
            padding: 0 20px;

            &.ivu-radio-wrapper-checked,
            &:hover {
                background: $cmp-dark-tertiary-bg-color;

                .show-form {
                    color: $cmp-dark-active;
                }

                .ivu-radio .ivu-radio-inner {
                    border-color: $cmp-dark-active;
                }
            }
        }
    }

    &__add {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-between;
        background: $cmp-dark-tertiary-bg-color;
        border-left: 2px solid $cmp-dark-active;

        .ivu-input-wrapper {
            margin-right: 10px;

            .ivu-input {
                border: 1px solid $darktheme20;
                height: 20px;
                margin: 5px;
            }
        }

        i {
            margin: 0;
            line-height: 30px;
            cursor: pointer;

            &:last-of-type {
                margin-right: 5px;
            }

            &:hover {
                color: $darktheme20;
            }
        }
    }

    &__values {
        width: 100%;
        padding: 0 !important;
    }

    &__controls {
        &--tip {
            font-size: 14px;
            font-style: italic;
            padding-left: 8px;
            color: $cmp-dark-disabled-color;
        }

        &:hover {
            .editable-group__controls--tip {
                color: $cmp-dark-font-color;
            }
        }
    }

    &__value-wrapper {
        position: relative;

        &:hover {
            .editable-group__value-actions {
                display: block;
            }
        }
    }

    &__value-actions {
        display: none;
        position: absolute;
        top: 3px;
        right: 6px;

        i {
            transition: opacity 0.3s;
            opacity: 0.25;
            cursor: pointer;

            &:hover {
                opacity: 1;
            }
        }
    }

    .ivu-collapse-content-box {
        border-bottom: 1px solid $cmp-dark-border-color;
    }

    .ivu-collapse-header {
        .collapsible-widget__panel-controls {
            margin: 0 15px;

            &:hover {
                color: $cmp-dark-active;
            }
        }
    }

    .show-form {
        border-radius: 50%;
        border: 2px solid;
        font-size: 10px;
        color: $blue;
    }
}
.remove-feed__modal {
    .ivu-nodal-header {
        padding: 0;
    }
    .ivu-modal-body {
        padding-top: 0;
        padding-bottom: 0;
    }
    .ivu-modal-footer {
        .ivu-btn {
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .alert-modal {
        &__icon-wrapper {
            display: none;
        }
        &__content-wrapper {
            padding-left: 0;
        }
        &__title {
            font-size: 18px;
            line-height: 28px;
            color: #4d5358;
        }
        &__content {
            padding-right: 50px;
        }
    }
}
