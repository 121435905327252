
@import "@/../sass/_variables.scss";

.qa-annotation-input-form {
    padding: $spacing 2px 0 2px;

    &--margin-bottom-small {
        margin: 0 0 $spacing-small;
    }

    .atwho-view {
        max-height: 86px;
    }
}

.qa-annotation-input-form__header {
    font-size: 18px;
    line-height: 1em;
    margin: 0 32px 16px 0;
    padding: 16px 0;
}

.qa-annotation-input-form__input {
    min-height: 6em;
    width: 100%;
    border: 1px solid $grey3;
    white-space: pre-wrap;
    margin-bottom: $spacing-small;
    padding: 6px;
    outline-color: white;

    &:focus {
        border: 1px solid $grey5;
    }
}

.qa-annotation-input-form .qa-annotation-input-form__button {
    margin: 0 0 0 $spacing-small;

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    &.ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}

.qa-annotation-input-form__buttons-container {
    display: flex;
    justify-content: flex-end;
    padding: $spacing-small 0 0 0;
}

.mentionable-usage-description__icon {
    color: $grey5;
    margin-bottom: $spacing;
}

.mentionable-tag {
    color: $blue;
}
