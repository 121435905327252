
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.image-cropper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.image-cropper__container {
    @include make-checked-background($grey3, $spacing-small);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: hidden;
    padding: 0 $spacing;
    position: relative;
    width: 100%;
}

.image-cropper__draggable {
    bottom: 0;
    cursor: move;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.image-cropper__enable-zoom-message {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: $white;
    display: flex;
    font-size: $font-size-larger;
    font-weight: bold;
    justify-content: center;
    left: 0;
    padding: 0 $spacing;
    position: absolute;
    right: 0;
    top: 0;
}

.image-cropper__image {
    position: absolute;
}

.image-cropper__slider {
    flex: 1;
    padding: 0 $spacing;
}

.image-cropper__slider-container {
    align-items: center;
    border: 1px solid $grey3;
    border-top: none;
    color: $grey5;
    display: flex;
    padding: 0 $spacing-small;
}

.image-cropper__viewport {
    border: 2px dashed $white;
    box-shadow: 0 0 200vw 200vh rgba(0, 0, 0, 0.65);
    display: block;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
